import React, { ChangeEvent, Component, KeyboardEvent } from "react";
import styled, { css, theme } from "../../theme";
// import AddButtonE from "../../components/UI/buttons/add-button";
import ClearIcon from "../../assets/icons/clear.svg";
import { ValidationPopupInput } from "@ramble/ramble-ui";
import ChevronState from "../../assets/icons/chevron_state.svg"

interface SelectValueProps {
    uiOpenList: boolean;
    uiValue?: string;
    uiSmallPadding?: boolean;
    uiSelectWidth?: number;
    uiNoArrow?: boolean;
    uiChangeInput?: boolean;
    uiBorderColor?: string;
    uiBorderStyle?: string;
    uiNoRightSpace?: boolean;
    uiPaddingRight?: boolean;
    pricingRedesign?: boolean
}

interface SelectContainerProps {
    uiDecorativeBoder?: boolean;
    uiDirection: string;
    uiValue?: string;
    uiInverted?: boolean;
    noBorderBottom?: boolean;
    uiPopup?: boolean;
    uiChangeInput?: boolean;
    uiDropArrow?: boolean;
    cellFormat?: boolean;
}

const Label = styled.label`
    color: ${theme.colors.grayRGBA};
    margin-right: 15px;
    flex-shrink: 0;
`;
// const AddButton = styled(AddButtonE)`
//     width: 100%;
//     font-size: 15px;
// `;

const SelectContainer = styled.div<SelectContainerProps>`
    display: flex;
    align-items: flex-start;
    flex-direction: ${(props) => `${props.uiDirection}`};
    ${(props) =>
        props.cellFormat &&
        css`
            width: 100%;
        `}

    label {
        &::after,
        &::before {
            border-left: ${(props) =>
                props.uiDropArrow ? "2px solid #a3a3a3 !important" : ""};
        }
    }

    .select-wrapper {
        position: relative;
        width: ${(props) => (props.uiDecorativeBoder ? "auto" : "100%")};

        &::before {
            position: ${(props) => !props.uiValue && "absolute"};
            display: ${(props) => (props.uiDecorativeBoder ? "block" : "none")};
            content: "";
            top: 5px;
            top: 8px;
            bottom: 8px;
            right: 2.8em;
            width: 1px;
            background-color: #a3a3a3;
            z-index: 11;
        }
    }

    .select-list {
        padding: 0;
        ${(props) =>
            props.uiChangeInput &&
            css`
                padding-top: 5px;
            `};
        margin: 0;
        list-style-type: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: ${(props) => props.uiInverted && "100%"};
        border-left: 1px solid hsla(0, 0%, 13.9%, 0.15);
        border-right: 1px solid hsla(0, 0%, 13.9%, 0.15);
        border-bottom: ${(props) =>
            props.uiInverted ? "0" : "1px solid hsla(0, 0%, 13.9%, 0.15)"};
        border-top: ${(props) =>
            props.uiInverted ? "1px solid hsla(0, 0%, 13.9%, 0.15)" : "0"};
        border-radius: ${(props) =>
            props.uiInverted ? "0.5em 0.5em 0 0" : "0 0 0.5em 0.5em"};
        margin-top: ${(props) => !props.uiInverted && "-4px"};
        margin-bottom: ${(props) => props.uiInverted && "-4px"};
        max-height: 300px;
        overflow: auto;
        background-color: #fff;
        z-index: 999;
        ${(props) =>
            props.uiPopup &&
            css`
                bottom: 100%;
            `};
            &.noPadding{
                display: none;
            }
    }

    .select-list.pricingRedesign{ 
        box-shadow: 0px 2px 4px 0px #0000004D;
        margin-top: 2px;
    }

    .select-item {
        color: rgba(27, 28, 29, 0.87);
        padding: 0.25em 1em;
        border-bottom: 1px solid ${theme.colors.grayRGBA};
        
        border-bottom: ${(props) => props.noBorderBottom ? "none" : `1px solid ${theme.colors.grayRGBA}`};
        &:hover {
            cursor: pointer;
            background-color: #f8f5f8;
        }

        &:last-child {
            border-radius: 0 0 0.5em 0.5em;
            border-bottom: none;
            padding-bottom: ${(props) => props.uiInverted && "0.5em"};
        }

        &:first-child {
            padding-top: 0.5em;
        }
    }

    .pricingRedesign{
        border: none;
    }

    .select-item.disabled{
        color: #D4D4D4;
    }

    .pricingRedesign .select-item {

        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        min-height: 36px
    }

    &.pricingRedesign label{
        border: 1px solid #AAAAAA ;
        font-size: 16px;
    }

    &.pricingRedesign label:focus-within {
        border-color: #3b97b1;
    }

    &.pricingRedesign label {
        ::after,
        ::before {
            border-left: 0px !important;
        }
    }

    .select-item_not-res {
        color: #ccc;

        &:hover {
            cursor: default;
            background-color: inherit;
        }
    }
`;

const ClearIconBox = styled.span`
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 15px;
    cursor: pointer;
    line-height: 0;
    z-index: 11;

    > svg {
        width: 15px;
        fill: #3b87a0;
    }
`;

const ChevronStateBox = styled.span`
    position: absolute;
    top: 50%;    
    transform: translateY(-50%);
    right: 6px;
    cursor: pointer;
    line-height: 0;
    z-index: 11;
    &.rotate-icon svg{
        transform: rotate(180deg);
    }

    > svg {
        width: 24px;
    }
    
`;

const SelectValue = styled(ValidationPopupInput)<SelectValueProps>`
    position: relative;
    border-radius: 8px;
    padding: 0 !important;
    border: 1px solid hsla(0, 0%, 13.9%, 0.15);
    width: ${(props) =>
        props.uiSelectWidth ? `${props.uiSelectWidth}px` : "100%"};
    z-index: 10;

    &::after,
    &::before {
        content: "";
        position: absolute;
        ${(props) =>
            props.uiNoArrow &&
            css`
                display: none;
            `};
        border-left: ${(props) => (props.uiValue ? "0" : "4px")} solid #a3a3a3;
        top: 50%;
        height: 10px;
        pointer-events: none;
    }

    &::after {
        transform: translateY(-50%)
            ${(props) =>
                props.uiOpenList
                    ? "rotate(-90deg) skew(-32deg)"
                    : "rotate(90deg) skew(32deg)"};
        right: 15px;
    }

    &::before {
        transform: translateY(-50%)
            ${(props) =>
                props.uiOpenList
                    ? "rotate(90deg) skew(32deg)"
                    : "rotate(-90deg) skew(-32deg)"};
        right: 25px;
    }

    > input {
        ${(props) =>
            !props.uiChangeInput &&
            css`
                color: transparent !important;
                text-shadow: 0 0 0 rgba(27, 28, 29, 0.87);
            `};
        border-right: 1px solid #a3a3a3;
        padding: ${(props) =>
            props.uiSmallPadding
                ? props.uiNoRightSpace
                    ? "0.35em 0.35em !important"
                    : "0.35em 2em 0.35em 1em !important"
                : props.uiNoRightSpace
                ? "0.67em 1em !important"
                : props.className === "room-select"
                ? "0.67em 2.8em 0.67em .5em !important"
                : "0.67em 2.8em 0.67em 1em !important"};
        border-radius: 0.5rem;
        font-style: italic;
        text-align: center;
        &::placeholder {
            color: transparent;
            text-shadow: 0 0 0 ${theme.colors.grayRGBA};
        }

        &:hover {
            cursor: pointer;
        }
        ${(props) =>
            props.pricingRedesign &&
            css`
                height: 36px;
                padding: 4px !important;
                padding-left: 12px !important;
                text-align: left;
                font-style: normal;
            `};
    }

    &:hover {
        cursor: pointer;
    }

    &:focus-within {
        border-color: #3b97b1;
        color: rgba(27, 28, 29, 0.8);
    }

    ${(props) =>
        props.uiBorderColor &&
        css`
            border-color: ${props.uiBorderColor};
        `};

    ${(props) =>
        props.uiBorderStyle &&
        css`
            border: ${props.uiBorderStyle};
        `};
        ${(props) =>
            props.uiPaddingRight &&
            css`
                padding-right: 25px !important;
            `};
`;

const FormattedSearchResult = (props: any) => {
    const { str, search } = props;

    if (str) {
        return str;
    }

    const lowStr = str.toLowerCase();
    const lowFind = search.toLowerCase();

    const idx = lowStr.indexOf(lowFind);

    if (idx === -1) {
        return str;
    }
    if (str.length - idx - 1 === search.length - 1) {
        return (
            <span>
                {str.substring(0, idx)}
                <b>{str.substring(idx)}</b>
            </span>
        );
    } else {
        return (
            <span>
                {str.substring(0, idx)}
                <b>{str.substring(idx, idx + search.length)}</b>
                {str.substring(idx + search.length)}
            </span>
        );
    }
};

interface Props {
    className?: string;
    decorativeBorder?: boolean;
    flexDirection: string;
    label?: string;
    selectList: Array<{ key: string | number; label: string } | string>;
    selectWidth?: number;
    smallPadding?: boolean;
    name: string;
    placeholder?: boolean;
    placeholderText?: string;
    valueSelect?: string;
    style?: any;
    uiInverted?: boolean;
    noBorderBottom?: boolean;
    popup?: boolean;
    noArrow?: boolean;
    customValue?: boolean;
    noAddButton?: boolean;
    required?: boolean;
    uiBorderColor?: string;
    uiBorderStyle?: string;
    addButtonText?: string;
    disabled?: boolean;
    cellFormat?: boolean;
    noClearBox?: boolean;
    uiDropArrow?: boolean;
    editable?: boolean;
    type?: string;
    handleClickSelect(value: string | number, name?: string): void;
    handleAddItem?(value: string): void;
    openModalClick?(): void;
    hideAddButton?: boolean;
    panelCustomWidths?: boolean;
    handleChangeCustomState?(value: string, key: string): void;
    paddingRight?: boolean;
    pricingRedesign?: boolean
}
interface State {
    openSelectList: boolean;
    value?: string;
    selectList: Array<{ key: string | number; label: string } | string>;
    sortList: Array<{ key: string | number; label: string } | string>;
    customStateName?: string;
}

export default class SelectList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            openSelectList: false,
            value: props.panelCustomWidths ? props.valueSelect : props.selectList.find((el) => el === props.valueSelect)
                ? props.valueSelect
                : "",
            selectList: props.selectList,
            sortList: props.selectList,
            customStateName: props.valueSelect,
        };
    }
    public componentDidUpdate(prevProps: any, prevState: any): void {
        if (prevState.customStateName !== this.state.customStateName) {
            if (this.props.handleChangeCustomState) {
                this.props.handleChangeCustomState(this.state.customStateName || '', this.props.name)
            }
        }
    }
    public componentWillReceiveProps(nextProps: Props) {
        if (nextProps.selectList !== this.props.selectList) {
            this.setState({
                selectList: nextProps.selectList,
                // sortList: nextProps.selectList,
            });
        }
        if (nextProps.valueSelect !== this.props.valueSelect) {
            if (this.props.editable) {
                this.setState({
                    value: nextProps.valueSelect,
                });
            } else {
                this.setState({
                    value: nextProps.selectList.find((el) =>
                        typeof el === "string"
                            ? el === nextProps.valueSelect
                            : el.label === nextProps.valueSelect
                    )
                        ? nextProps.valueSelect
                        : "",
                    customStateName: nextProps.valueSelect 
                });
            }
        }
        if (!nextProps.valueSelect) {
            this.setState({
                sortList: nextProps.selectList,
            });
        }
    }

    public renderSelectItems = (
        list: Array<{ key: string | number; label: string } | string>
    ) => {
        return list.map((el: any, i: number) => (
            <li
                key={i}
                className={`select-item ${this.props.pricingRedesign ? 'pricingRedesign' : ''}`}
                onMouseDown={() => this.onClickSelectItem(el)}>
                {this.props.customValue ? (
                    <FormattedSearchResult
                        search={this.state.value && this.state.value.trim()}
                        str={typeof el === "string" ? el : el.label}
                    />
                ) : typeof el === "string" ? (
                    el
                ) : (
                    el.label
                )}
            </li>
        ));
    };

    public hideSelectList = () => {
        if (this.state.openSelectList) {
            this.setState({ openSelectList: false });

            const autoSelected = this.state.sortList.filter((item) =>
                typeof item === "string"
                    ? item === this.state.value
                    : item.label === this.state.value
            )[0];
            if (autoSelected) {
                this.props.handleClickSelect(
                    typeof autoSelected === "string"
                        ? autoSelected
                        : autoSelected.key,
                    this.props.name
                );
            }
        }
    };

    public filterSelectList = (
        arr: Array<{ key: string | number; label: string } | string>,
        value: string
    ): Array<{ key: string | number; label: string } | string> => {
        const updateList = arr.filter((el) => {
            if (typeof el === "string") {
                if (
                    el
                        .toLocaleLowerCase()
                        .indexOf(value.toLocaleLowerCase()) !== -1
                ) {
                    return el;
                }
            } else {
                if (
                    el.label
                        .toLocaleLowerCase()
                        .indexOf(value.toLocaleLowerCase()) !== -1
                ) {
                    return el;
                }
            }
        });
        return updateList;
    };

    public onClickSelectItem = (
        value: { key: string | number; label: string } | string
    ) => {
        this.setState(
            {
                openSelectList: false,
                value: typeof value === "string" ? value : value.label,
                sortList: this.props.customValue
                    ? this.filterSelectList(
                          this.state.selectList,
                          typeof value === "string" ? value : value.label
                      )
                    : [...this.state.selectList],
            },
            () =>
                this.props.handleClickSelect(
                    typeof value === "string" ? value : value.key,
                    this.props.name
                )
        );
    };

    public onClickAddNew = () => {
        const { value } = this.state;
        if (value && value.trim()) {
            const selectedItem = this.state.selectList.find((item) =>
                typeof item === "string" ? value === item : value === item.label
            );
            if (selectedItem) {
                this.onClickSelectItem(selectedItem);
            } else {
                if (this.props.handleAddItem) {
                    this.props.handleAddItem(value);
                    this.setState({
                        openSelectList: false,
                    });
                }
                //  else {
                //     const updateList = [...this.state.selectList, value];
                //     this.setState({
                //         openSelectList: false,
                //         selectList: updateList,
                //         sortList: this.filterSelectList(updateList, value),
                //     }, () => this.props.handleClickSelect(value, this.props.name));
                // }
            }
        }
    };

    public handleClearValue = () => {
        this.setState(
            {
                value: "",
                openSelectList: false,
                sortList: [...this.state.selectList],
            },
            () => {
                if (typeof this.state.value !== "undefined") {
                    this.props.handleClickSelect(
                        this.state.value,
                        this.props.name
                    );
                }
            }
        );
    };

    public handleClickOnSelect = () => {
        this.setState({ openSelectList: !this.state.openSelectList });
    };

    public handleChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        if (value && value.length > 20) {
            return
        }
        if (this.props.customValue) {
            const updateList = this.filterSelectList(
                this.state.selectList,
                value
            );

            this.setState(
                {
                    value,
                    sortList: updateList,
                    customStateName: value
                }
            );
            return;
        }
        if (this.props.editable) {
            const updateList = this.filterSelectList(
                this.state.selectList,
                value
            );

            this.setState(
                {
                    value,
                    sortList: updateList,
                },
                () => {
                    this.props.handleClickSelect(value, this.props.name);
                }
            );
            return;
        }
        e.target.value = this.state.value || "";
    };

    public handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            this.onClickAddNew();
        }
    };

    public render(): JSX.Element {
        const { openSelectList } = this.state;
        const {
            className,
            valueSelect,
            name,
            decorativeBorder,
            flexDirection,
            placeholder,
            placeholderText,
            selectWidth,
            smallPadding,
            uiInverted,
            popup,
            customValue,
            noArrow,
            uiBorderColor,
            uiBorderStyle,
            disabled,
            cellFormat,
            noClearBox,
            uiDropArrow,
            editable,
            noBorderBottom,
            style,
            pricingRedesign,
        } = this.props;
        return (
            <SelectContainer
                className={`${className} ${pricingRedesign ? 'pricingRedesign' : ''}`}
                uiValue={valueSelect}
                uiDirection={flexDirection}
                uiDropArrow={uiDropArrow}
                uiDecorativeBoder={decorativeBorder}
                uiInverted={uiInverted}
                noBorderBottom={noBorderBottom}
                uiPopup={popup}
                uiChangeInput={customValue}
                cellFormat={cellFormat}
            >
                {this.props.label && <Label>{this.props.label}</Label>}
                <div className="select-wrapper">
                    <SelectValue
                        style={style}
                        id={`${name}-selectList`}
                        onBlur={this.hideSelectList}
                        onClick={this.handleClickOnSelect}
                        name={name}
                        value={this.state.value || this.state.customStateName || ""}
                        placeholder={
                            placeholder
                                ? placeholderText || "Please select"
                                : ""
                        }
                        uiOpenList={openSelectList}
                        uiValue={valueSelect}
                        uiSelectWidth={selectWidth}
                        uiSmallPadding={smallPadding}
                        uiNoArrow={noArrow}
                        uiChangeInput={customValue || editable}
                        autoComplete="off"
                        onChange={this.handleChangeInput}
                        required={this.props.required}
                        uiReportOnBlur={false}
                        uiBorderColor={uiBorderColor}
                        uiBorderStyle={uiBorderStyle}
                        disabled={disabled}
                        uiDisabled={disabled}
                        uiNoRightSpace={noClearBox}
                        type={this.props.type ? this.props.type : "text"}
                        onKeyPress={this.handleKeyPress}
                        className={className}
                        data-index={1}
                        uiPaddingRight={this.props.paddingRight}
                        pricingRedesign={this.props.pricingRedesign}
                    />
                    {openSelectList && (
                        <ul className={`select-list ${this.state.sortList && this.state.sortList.length < 1 ? 'noPadding' : ''} ${pricingRedesign ? "pricingRedesign" : ''}`}>
                        {/* <AddButton
                                title={this.props.addButtonText || ""}
                                onMouseDown={this.props.openModalClick}
                            /> */}
                                {this.state.sortList.length > 0 && this.renderSelectItems(this.state.sortList)
                                }
                        </ul>
                    )}
                    {valueSelect && !noClearBox && !disabled && (
                        <ClearIconBox onClick={this.handleClearValue}>
                            <ClearIcon />
                        </ClearIconBox>
                    )}
                    {uiDropArrow && pricingRedesign && 
                        <ChevronStateBox className={`${openSelectList ? 'rotate-icon': ''} pointer-events-none`}>
                            <ChevronState />
                        </ChevronStateBox>
                    }
                </div>
            </SelectContainer>
        );
    }
}

import Color from "color";
import React, { forwardRef, InputHTMLAttributes } from "react";
import styled, { css } from "../theme/styled";
import { cleanUiProps } from "../utils/withStyledProps";

export interface InputUIProps {
    /**
     * Disabled input
     */
    uiDisabled?: boolean;
    /**
     * Error input
     */
    uiError?: boolean;

    /**
    Pricing tab redesign QUIPA-1629
     */
    darkBorder?: boolean
}

export type InputProps = InputHTMLAttributes<HTMLInputElement> & InputUIProps;

/**
 * Just generic input
 */
const InputComponent = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { className, uiError, uiDisabled, children, ...other } = props;
    cleanUiProps(other);
    return (
        <label className={className} htmlFor={other.id}>
            <input {...other} title="" autoComplete="off" className="input" role="presentation" ref={ref} />
            {children}
        </label>
    );
});

const withInvalidState = (errorColor: string) => css`
    color: ${Color(errorColor).desaturate(0.2).string()};
    border-color: ${Color(errorColor).lighten(0.5).string()};
    background: ${Color(errorColor).lighten(0.9).string()};

    &:focus-within {
        border-color: ${Color(errorColor).lighten(0.5).string()};
        color: ${Color(errorColor).lighten(0.5).string()};
    }
`;

/**
 * Just generic input
 */
const Input = styled(InputComponent)`
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    border-radius: 0.28rem;
    padding: 0.67em 1em;
    transition: border-color, background-color, color 0.2s ease;
    will-change: border-color, background-color, color;
    outline: none;
    box-sizing: border-box;
    flex-flow: row nowrap;
    color: ${({ theme }) => Color(theme.colors.primaryText).alpha(0.87).string()};
    border: 1px solid 
        ${({ theme, darkBorder }) => 
            darkBorder 
                ? "#AAAAAA" 
                : Color(theme.colors.divider).alpha(0.15).string()};
    background: ${({ theme }) => theme.colors.background};
    cursor: text;

    &:focus-within {
        border-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => Color(theme.colors.primaryText).alpha(0.8).string()};
    }

    > .input {
        border: 0;
        outline: none;
        color: inherit;
        font-size: 1em;
        background-color: inherit;
        flex: 1 1 auto;
        padding: 0;
        margin: 0;
        max-width: 100%;
    }

    ${({ theme, uiError, id, required }) => {
        // if(id === 'cost') console.log(uiError)
        if(id && !["cost", "msrp"].includes(id)) return uiError && withInvalidState(theme.colors.danger);
        return uiError && required && withInvalidState(theme.colors.danger);
    }};
    ${props => props.uiDisabled && css`
        opacity: 0.45;
        pointer-events: none;
    `};
`;

export default Input;

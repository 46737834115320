import { WorkOrder } from "../interfaces/work-order";
import { Api } from "@quipa/api";
import axiosInstance from "./axios-instance";

const getWorkOrderRecommendations = async (payload: WorkOrder) => {
    try {
        let panelJsonObject = {};

        if (payload && payload.newDraperyDesign && payload.newDraperyDesign.panelJson && Array.isArray(payload.newDraperyDesign.panelJson)) {
            payload.newDraperyDesign.panelJson.forEach((width:any, index:any) => {
                panelJsonObject[`pannel${index + 1}Width`] = width;
              });
              payload.newDraperyDesign.panelJson = panelJsonObject;
        }
        

        const response = await axiosInstance.post<WorkOrder>(
            "/workOrder/getWorkOrderRecommendations",
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error get work order recommendations:", error);
        throw error;
    }
};

const getWorkOrderPDFContent = async (draperyOrderId: number) => {
    try {
        const response = await axiosInstance.get<any>(
            `/workOrder/getPDFContent?draperyOrderId=${draperyOrderId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error get work order PDF content:", error);
        throw error;
    }
};
const addWorkOrder = async (payload: any) => {
    try {
        const response = await axiosInstance.post<any>(
            "/workOrder/add",
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error add work order :", error);
        throw error;
    }
};
const editWorkOrder = async (payload: any) => {
    try {
        const response = await axiosInstance.put<any>(
            "/workOrder/edit",
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error edit work order :", error);
        throw error;
    }
};
const getNextNum = async (accountId: number) => {
    try {
        const response = await axiosInstance.get<any>(
            `/workOrder/getNextNum?accountId=${accountId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error get next num for work order :", error);
        throw error;
    }
};

const searchWorkOrders = async (accountId: number) => {
    try {
        const response = await axiosInstance.get<any>(
            `/workOrder/searchWorkOrders?accountId=${accountId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error get next num for work order :", error);
        throw error;
    }
};
const saveWorkOrderOverrides = async (
    draperyOrderId: number,
    accountId: number,
    overrides: Pick<
        Api.SaveWorkOrderOverridesAction["Request"]["payload"],
        "panelJson" | "yardages"
    >,
    draperyOrderPriceId?: number
) => {
    try {
        const response = await axiosInstance.post<any>(
            `/workOrder/saveWorkOrderOverrides`,
            {
                draperyOrderId,
                accountId,
                panelJson: overrides.panelJson,
                yardages: overrides.yardages,
                draperyOrderPriceId,
            }
        );
        return response.data;
    } catch (error) {
        console.error(
            "Error save work order overrides for work order :",
            error
        );
        throw error;
    }
};

const handleDuplicateWorkOrders = async () => {
    try {
        const response = await axiosInstance.post<any>(`/workOrder/handleDuplicateWorkOrders`);
        return response.data;
    } catch (error) {
        console.error(
            "Error updating work order duplicates :",
            error
        );
        throw error;
    }
};

export {
    getWorkOrderRecommendations,
    editWorkOrder,
    addWorkOrder,
    getNextNum,
    getWorkOrderPDFContent,
    saveWorkOrderOverrides,
    searchWorkOrders,
    handleDuplicateWorkOrders
};

import {
  CalculatorCheckbox,
  // CalculatorExtraDiv,
  CalculatorFieldInputRomanShade,
  CalculatorFormField,
  CalculatorFormLabel,
  CalculatorFormQuestionNumber,
  CalculatorImageRadio,
  CalculatorRadioGroup,
  CalculatorRadioGroupWrap,
  CalculatorRadioWrapperWithImg,
  CheckboxInputWrap,
  DraperyHeader,
  DrawingContainer,
  HContainer,
  RadioInput,
  RadioLabelExpanded,
  RomanShadeDesignHeader,
  RomanShadeDesignHeaderSubtitle,
  RomanShadeSection,
  PrependAppendInputGroupRomanShade,
  PrependAppendInput,
  RegularInputGroupLabel,
  TooltipContainer,
  TooltipPopup,
  TooltipText,
  Width170,
  RadioLabel,
  CalculatorRadioWrapperRomanShade,
  CalculatorRadio,
  RomanShadeDesginTextInput,
  NotesContainerRomanShade,
  NoteLettersRomanShade,
} from "./styles";
import { DraperyModule } from "../../redux";
import {
  DraperyOrderDesignEmbellishment,
  DraperyOrderFabric,
  EmbellishmentCheckInfo,
  // highlightStyle,
} from "./calculator";
import React, { ChangeEvent, Component, Dispatch } from "react";
import Drawing from "../UI/drawing/drawing";
import { TextArea } from "@ramble/ramble-ui";
import { ValidationErrorInfo } from "./interfaces-common";
import { duplicateFabric } from "./fabrics-tab";
import styled, { theme } from "../../theme";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";
import { FormFieldsAction } from "../../redux/modules/my-shop/form-fields-reducer";
import SelectList from "../UI/select-list";
// import { CustomizeConstructionDiv } from "./design-tab";
// import { FaAngleDown, FaAngleUp } from "react-icons/fa";
// import DrapperyOrderWorkroomSettings from "./customize-construction";
import AdditionalFormRomanShade from "./additional-form-roman-shade";
import { FlatRomanShade, RelaxedRomanShade } from "../UI/drawing/bk_svgs";
import { RomanShadeJson } from "@quipa/api/build-ts/client";

const FabricDimensionInputGroup = styled(CalculatorFieldInputRomanShade)`
     margin-bottom: .5rem;
`;

const PanelWidthSelectWrap = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-flow: wrap;
    > div:first-child {
        width: 56px;
    }
    > div:last-child {
        align-self: center;
    }
`;

export const viewBox = {
  x: 0,
  y: 20,
  width: 500,
  height: 200,
};

export interface DefaultRipplefoldOptions {
  controlType: string | undefined | null;
  controlLocation: string | undefined | null;
  masterCarrier: string | undefined | null;
  mount: string | undefined | null;
  headerTape: string | undefined | null;
}

export const defaultRipplefoldOptions = (): DefaultRipplefoldOptions => {
  return {
    controlType: null,
    controlLocation: null,
    masterCarrier: null,
    mount: null,
    headerTape: null,
  }
}

export const defaultWidthEachPanelList =
  (): DraperyModule.DraperyOrderDesignPanelJson => ({});

export const draperyLiningsList =
  (): DraperyModule.DraperyOrderDesignLining[] => [
    {
      id: 0,
      name: "Lining",
      active: false,
      description: "",
      draperyOrderDesignId: null,
    },
    {
      id: 0,
      name: "Interlining",
      active: false,
      description: "",
      draperyOrderDesignId: null,
    },
  ];

export const defaultEmbellishmentsList = (): EmbellishmentCheckInfo[] => [
  { checked: false, title: "Banding", stateName: "bandings" },
  { checked: false, title: "Cording", stateName: "cordings" },
  { checked: false, title: "Flange", stateName: "flanges" },
  { checked: false, title: "Trim", stateName: "trims" },
  { checked: false, title: "Other", stateName: "othersEmbellishments" },
];

export const defaultEmbellishment = (
  targetArray: string
): DraperyOrderDesignEmbellishment | undefined => {
  switch (targetArray) {
    case "bandings": {
      return defaultBanding();
    }
    case "cordings": {
      return defaultCording();
    }
    case "flanges": {
      return defaultFlange();
    }
    case "trims": {
      return defaultTrim();
    }
    case "othersEmbellishments": {
      return defaultOtherEmbellishment();
    }
  }
};

export const defaultBanding = (): DraperyOrderDesignEmbellishment => ({
  id: 0,
  name: "",
  type: 1,
  leadReturnEdge: { active: false, insetActive: false, insetSize: "" },
  topEdge: { active: false, insetActive: false, insetSize: "" },
  bottomEdge: { active: false, insetActive: false, insetSize: "" },
  totalStrips: undefined,
  draperyOrderDesignId: null,
  itemId: 0

});

export const defaultCording = (): DraperyOrderDesignEmbellishment => ({
  id: 0,
  name: "",
  type: 2,
  subType: undefined,
  leadReturnEdge: { active: false, insetActive: false, insetSize: "" },
  topEdge: { active: false, insetActive: false, insetSize: "" },
  bottomEdge: { active: false, insetActive: false, insetSize: "" },
  totalStrips: undefined,
  draperyOrderDesignId: null,
  itemId: 0

});

export const defaultFlange = (): DraperyOrderDesignEmbellishment => ({
  id: 0,
  name: "",
  type: 3,
  subType: undefined,
  styleType: "Flat",
  leadReturnEdge: { active: false },
  topEdge: { active: false },
  bottomEdge: { active: false },
  totalStrips: undefined,
  draperyOrderDesignId: null,
  itemId: 0

});

export const defaultTrim = (): DraperyOrderDesignEmbellishment => ({
  id: 0,
  name: "",
  type: 4,
  subType: undefined,
  appliedType: undefined,
  leadReturnEdge: { active: false, insetActive: false, insetSize: "" },
  topEdge: { active: false, insetActive: false, insetSize: "" },
  bottomEdge: { active: false, insetActive: false, insetSize: "" },
  totalStrips: undefined,
  draperyOrderDesignId: null,
  itemId: 0

});

export const defaultOtherEmbellishment =
  (): DraperyOrderDesignEmbellishment => ({
    id: 0,
    name: "",
    type: 5,
    details: "",
    leadReturnEdge: {},
    topEdge: {},
    bottomEdge: {},
    draperyOrderDesignId: null,
    price: "",
    itemId: 0
  });

// duplicate
export const duplicateDraperyOrderOtherItemDesign = (
  draperyOrderDesign: DraperyModule.DraperyOrderDesign
): DraperyModule.DraperyOrderDesign => {
  return {
    ...draperyOrderDesign,
    id: 0,
    drawing: undefined,
  };
};

export const duplicateLining = (lining: any) => {
  return {
    ...lining,
    id: 0,
    draperyOrderDesignId: 0,
    draperyOrderLiningsFabric: {
      ...lining.draperyOrderLiningsFabric,
      id: 0,
    },
  };
};

export const duplicateEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment
) => {
  return {
    ...embellishment,
    id: 0,
    draperyOrderDesignId: 0,
    draperyOrderFabric: embellishment.draperyOrderFabric
      ? duplicateFabric(embellishment.draperyOrderFabric)
      : undefined,
  };
};

// validate
export const validateDraperyOrderDesign = (
  draperyOrderDesign: DraperyModule.DraperyOrderDesign,
  draperyItemDetail: any,
  embellishmentList: EmbellishmentCheckInfo[],
  liningsList: DraperyModule.DraperyOrderDesignLining[],
  mainFabric: DraperyOrderFabric,
  clearHighlightSection?: (name: string) => void,
  romanShadeState?: any,
  quantity?: any
): ValidationErrorInfo[] => {
  const prefixName = "draperyOrderDesign.";
  let tabIndex = 1;
  let errors: ValidationErrorInfo[] = [];
  const romanShadeJson = draperyOrderDesign.romanShadeJson

  // 1. STYLE
    if (romanShadeJson.style && !(romanShadeJson.style.type === 'relaxed' || romanShadeJson.style.type === 'flat'))  {
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.style.type",
        message: "Please select the style.",
      });
    }

    if(romanShadeJson.style && romanShadeJson.style.function < 0) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.style.function",
        message: "Please select the type.",
      });
    }

    // 2. DIMENSIONS
    if(romanShadeJson.dimensions && !(romanShadeJson.dimensions.finishedWidth! > 0)) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.dimensions.finishedWidth",
        message: "Please specify the finished width.",
      });
    }
    if(romanShadeJson.dimensions && !(romanShadeJson.dimensions.finishedLength! > 0)) { 
      const typeLength = romanShadeJson.style.function === 1 ? 'hanging' : 'finished'
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.dimensions.finishedLength",
        message: `Please specify the ${typeLength} length.`,
      });
    }

    if(romanShadeJson.dimensions && !(romanShadeJson.dimensions.foldSize! > 0) && romanShadeJson.dimensions.numOfPermanentFolds! > 0) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.dimensions.foldSize",
        message: "Please specify the size of the folds.",
      });
    }

    if(romanShadeJson.dimensions && !(romanShadeJson.dimensions.bottomSkirtLength! > 0) && romanShadeJson.dimensions.bottomSkirit === 1) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.dimensions.bottomSkirtLength",
        message: "Please specify the bottom skirt length.",
      });
    }

    // 3. VALANCE

    // Front Valance
    if(romanShadeJson.valanceAndReturns && !(romanShadeJson.valanceAndReturns.frontValance >= 0)) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.valanceAndReturns.frontValance",
        message: "Please select a front valance option.",
      });
    }

    if(romanShadeJson.valanceAndReturns && !(romanShadeJson.valanceAndReturns.frontValanceLength! > 0) && romanShadeJson.valanceAndReturns.frontValance === 1) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.valanceAndReturns.frontValanceLength",
        message: "Please specify the front valance length.",
      });
    }

    // Sides Valance
    if(romanShadeJson.valanceAndReturns && !romanShadeJson.valanceAndReturns.sides) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.valanceAndReturns.sides",
        message: "Please select a sides option.",
      });
    }

  if (romanShadeJson.valanceAndReturns && (romanShadeJson.valanceAndReturns.frontValance === 0 || romanShadeJson.valanceAndReturns.frontValance === -1) && !(romanShadeJson.valanceAndReturns.sidesValanceLength! > 0) && romanShadeJson.valanceAndReturns.sides && romanShadeJson.valanceAndReturns.sides === 'side-flaps') { 
    const sideType = romanShadeJson.valanceAndReturns.sides === "side-flaps" ? 'side flaps' : 'returns'
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.valanceAndReturns.sidesValanceLength",
        message: `Please specify the ${sideType} length.`,
      });
    }

    // Back Valance
    if(romanShadeJson.valanceAndReturns && !(romanShadeJson.valanceAndReturns.backValance >=0)) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.valanceAndReturns.backValance",
        message: "Please select a back valance option.",
      });
    }

    if(romanShadeJson.valanceAndReturns && romanShadeJson.valanceAndReturns.backValance === 1 && !(romanShadeJson.valanceAndReturns.backValanceLength! > 0)) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.valanceAndReturns.backValanceLength",
        message: "Please specify the back valance length.",
      });
    }

    // 4. HEDRAIL

    if (romanShadeJson.valanceAndReturns && romanShadeJson.headrail.boardFace !== null && !(romanShadeJson.headrail.boardFace > 0)) {
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.headrail.boardFace",
        message: "Board Face must be greater than 0 if entered.",
      });
    }


    if(romanShadeJson.valanceAndReturns && (romanShadeJson.valanceAndReturns.sides === 'side-flaps' || romanShadeJson.valanceAndReturns.sides === 'returns') && !(romanShadeJson.headrail.boardDepth! > 0)) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.headrail.boardDepth",
        message: "Please specify the board depth.",
      });
    }

    const isControlLeftEntered = romanShadeJson.valanceAndReturns && romanShadeJson.headrail.controlLocationLeft !== null && romanShadeJson.headrail.controlLocationLeft as any !== ''
    const isControlRightEntered = romanShadeJson.valanceAndReturns && romanShadeJson.headrail.controlLocationRight !== null  && romanShadeJson.headrail.controlLocationRight as any !== ''

    if(romanShadeJson.valanceAndReturns && (isControlLeftEntered || isControlRightEntered) && !(+romanShadeJson.headrail.controlLocationRight! + +romanShadeJson.headrail.controlLocationLeft! === +quantity)) { 
      errors.push({
        tabIndex,
        name: prefixName + "romanShadeJson.headrail.controlLocationLeft",
        message: "The total number of left-controlled and right-controlled shades must equal Order Quantity (see Setup tab)."
        });
    } else { 
      clearHighlightSection!(prefixName + "romanShadeJson.headrail.controlLocationLeft")
    }

    if (isControlLeftEntered) {
      if (+romanShadeJson.headrail.controlLocationLeft! < 0 || !Number.isInteger(+romanShadeJson.headrail.controlLocationLeft!)) {
          errors.push({
              tabIndex,
              name: prefixName + "romanShadeJson.headrail.controlLocationLeft",
              message: "Control Location Left must be 0 or greater and must be a whole number if selected.",
          });
      }
  }

    if (isControlRightEntered) {
      if (+romanShadeJson.headrail.controlLocationRight! < 1 || !Number.isInteger(+romanShadeJson.headrail.controlLocationRight!)) {
          errors.push({
              tabIndex,
              name: prefixName + "romanShadeJson.headrail.controlLocationRight",
              message: "Control Location Right must be 1 or greater and must be a whole number if selected.",
          });
      }
  }
  



  // 9. NOTES
  if (draperyOrderDesign.notes && draperyOrderDesign.notes.length > 150) {
    errors.push({
      tabIndex,
      name: prefixName + "notes",
      message: "Notes letters must be less than 150.",
    });
  }
  return errors;
};

export const validateLining = (
  index: number,
  lining: DraperyModule.DraperyOrderDesignLining
): ValidationErrorInfo[] => {
  const errors: ValidationErrorInfo[] = [];
  return errors;
};

const validateBandingLeadReturnEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { totalStrips, leadReturnEdge } = embellishment;
  if (!totalStrips || totalStrips < 1) {
    errors.push({
      tabIndex,
      name: prefixName + "totalStrips",
      message:
        "Total number of vertical strips of banding must be at least 1.",
    });
  }

  if (leadReturnEdge.size === undefined || leadReturnEdge.size === "") {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message:
        "Please specify the banding width for the lead/return placement.",
    });
  } else if (isNaN(+leadReturnEdge.size!) || +leadReturnEdge.size! <= 0) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message: "Banding width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateBandingTopEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { topEdge } = embellishment;
  if (topEdge.size === undefined || topEdge.size === "") {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Please specify the banding width for the top placement.",
    });
  } else if (isNaN(+topEdge.size!) || +topEdge.size! <= 0) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Banding width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateBandingBottomEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { bottomEdge } = embellishment;
  if (bottomEdge.size === undefined || bottomEdge.size === "") {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message:
        "Please specify the banding width for the bottom placement.",
    });
  } else if (isNaN(+bottomEdge.size!) || +bottomEdge.size! <= 0) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message: "Banding width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateBandingEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment,
  tabIndex: number,
  prefixName: string,
  list: DraperyOrderDesignEmbellishment[]
) => {
  let errors: ValidationErrorInfo[] = [];
  const { leadReturnEdge, topEdge, bottomEdge } = embellishment;
  const index = Number(prefixName.split(".")[1]);
  const numEmbellishment = list.filter(
    (embellishment) => embellishment.type === 1
  ).length;
  let embellishmentName = `Banding ${numEmbellishment > 1 ? index + 1 : ""}`;
  // banding validation
  if (!leadReturnEdge.active && !topEdge.active && !bottomEdge.active) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.active",
      message: "Please specify the location for banding placement.",
    });
  }
  if (leadReturnEdge.active) {
    errors = validateBandingLeadReturnEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    leadReturnEdge.insetActive &&
    (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.insetSize",
      message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
    });
  }

  if (topEdge.active) {
    errors = validateBandingTopEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    topEdge.insetActive &&
    (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.insetSize",
      message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
    });
  }

  if (bottomEdge.active) {
    errors = validateBandingBottomEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    bottomEdge.insetActive &&
    (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.insetSize",
      message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
    });
  }

  return errors;
};

const validateCordingEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment,
  tabIndex: number,
  prefixName: string,
  list: DraperyOrderDesignEmbellishment[]
) => {
  const errors: ValidationErrorInfo[] = [];
  const { leadReturnEdge, topEdge, bottomEdge, totalStrips, subType } =
    embellishment;
  const index = Number(prefixName.split(".")[1]);
  const numEmbellishment = list.filter(
    (embellishment) => embellishment.type === 2
  ).length;
  let embellishmentName = `Cording ${numEmbellishment > 1 ? index + 1 : ""}`;
  // cording validation
  if (subType === undefined) {
    errors.push({
      tabIndex,
      name: prefixName + "subType",
      message: "Please select the cording type.",
    });
  }
  if (!leadReturnEdge.active && !topEdge.active && !bottomEdge.active) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.active",
      message: "Please specify the location for cording placement.",
    });
  }
  if (leadReturnEdge.active) {
    if (!totalStrips || totalStrips < 1) {
      errors.push({
        tabIndex,
        name: prefixName + "totalStrips",
        message:
          "Total number of vertical strips of cording must be at least 1.",
      });
    }
  }

  if (
    leadReturnEdge.insetActive &&
    (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.insetSize",
      message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
    });
  }

  if (
    topEdge.insetActive &&
    (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.insetSize",
      message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
    });
  }

  if (
    bottomEdge.insetActive &&
    (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.insetSize",
      message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
    });
  }

  return errors;
};

const validateFlangeLeadReturnEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { totalStrips, leadReturnEdge, subType } = embellishment;
  if (!totalStrips || totalStrips < 1) {
    errors.push({
      tabIndex,
      name: prefixName + "totalStrips",
      message:
        "Total number of vertical strips of flange must be at least 1.",
    });
  }
  if (
    (leadReturnEdge.size === undefined || leadReturnEdge.size === "") &&
    subType === 1
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message:
        "Please specify the flange width for the lead/return placement.",
    });
  } else if (
    subType === 1 &&
    (isNaN(+leadReturnEdge.size!) || +leadReturnEdge.size! <= 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message: "Flange width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateFlangeTopEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { topEdge, subType } = embellishment;
  if ((topEdge.size === undefined || topEdge.size === "") && subType === 1) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Please specify the flange width for the top placement.",
    });
  } else if (subType === 1 && (isNaN(+topEdge.size!) || +topEdge.size! <= 0)) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Flange width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateFlangeBottomEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { bottomEdge, subType } = embellishment;
  if (
    (bottomEdge.size === undefined || bottomEdge.size === "") &&
    subType === 1
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message:
        "Please specify the flange width for the bottom placement.",
    });
  } else if (
    subType === 1 &&
    (isNaN(+bottomEdge.size!) || +bottomEdge.size! <= 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message: "Flange width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateFlangeEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment,
  tabIndex: number,
  prefixName: string,
  list: DraperyOrderDesignEmbellishment[]
) => {
  let errors: ValidationErrorInfo[] = [];
  const index = Number(prefixName.split(".")[1]);
  const { leadReturnEdge, topEdge, bottomEdge, subType } = embellishment;
  const numEmbellishment = list.filter(
    (embellishment) => embellishment.type === 3
  ).length;
  let embellishmentName = `Flange ${numEmbellishment > 1 ? index + 1 : ""}`;
  // flange validation
  errors = checkUndefinedTypes(
    errors,
    tabIndex,
    prefixName,
    "subType",
    "Please select the flange type.",
    subType
  );

  if (!leadReturnEdge.active && !topEdge.active && !bottomEdge.active) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.active",
      message: "Please specify the location for flange placement.",
    });
  }
  if (leadReturnEdge.active) {
    errors = validateFlangeLeadReturnEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (topEdge.active) {
    errors = validateFlangeTopEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (bottomEdge.active) {
    errors = validateFlangeBottomEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    leadReturnEdge.insetActive &&
    (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.insetSize",
      message: `${embellishmentName} inset for Lead/Return edge must be greater than zero if selected.`,
    });
  }

  if (
    topEdge.insetActive &&
    (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.insetSize",
      message: `${embellishmentName} inset for Top edge must be greater than zero if selected.`,
    });
  }

  if (
    bottomEdge.insetActive &&
    (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.insetSize",
      message: `${embellishmentName} inset for Bottom edge must be greater than zero if selected.`,
    });
  }

  return errors;
};

const checkUndefinedTypes = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  prefixName: string,
  suffix: string,
  message: string,
  typeParam?: number
) => {
  if (typeParam === undefined) {
    errors.push({
      tabIndex,
      name: prefixName + suffix,
      message,
    });
  }
  return errors;
};

const validateTrimSidesWidth = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { leadReturnEdge, subType } = embellishment;
  if (
    (leadReturnEdge.size === undefined || leadReturnEdge.size === "") &&
    subType === 1
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message:
        "Please specify the trim width for the sides placement.",
    });
  } else if (
    subType === 1 &&
    (isNaN(+leadReturnEdge.size!) || +leadReturnEdge.size! <= 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message: "Trim width must be a value greater than 0.",
    });
  }
  return errors;
};
const validateTrimLeadReturnEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { totalStrips, leadReturnEdge, subType } = embellishment;
  if (!totalStrips || totalStrips < 1) {
    errors.push({
      tabIndex,
      name: prefixName + "totalStrips",
      message:
        "Total number of vertical strips of trim must be at least 1.",
    });
  }
  if (
    (leadReturnEdge.size === undefined || leadReturnEdge.size === "") &&
    subType === 1
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message:
        "Please specify the trim width for the lead/return placement.",
    });
  } else if (
    subType === 1 &&
    (isNaN(+leadReturnEdge.size!) || +leadReturnEdge.size! <= 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.size",
      message: "Trim width must be a value greater than 0.",
    });
  }
  return errors;
};

const validateTrimTopEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { topEdge, subType } = embellishment;
  if ((topEdge.size === undefined || topEdge.size === "") && subType === 1) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Please specify the trim width for the top placement.",
    });
  } else if (subType === 1 && (isNaN(+topEdge.size!) || +topEdge.size! <= 0)) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.size",
      message: "Trim width must be a value greater than 0.",
    });
  }
  return errors;
};
export const highlightStyle = {
  backgroundColor: theme.colors.errorBackground,
  borderRadius: 6,
  paddingLeft: 8,
};
const validateName = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { name } = embellishment;
  if (!name) {
    errors.push({
      tabIndex,
      name: prefixName + "name",
      message: "Please specify the Other Embellishment name.",
    });
  }
  return errors;
};

const validateUniqueName = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  unique: boolean,
  prefixName: string
) => {
  if (unique) {
    errors.push({
      tabIndex,
      name: prefixName + "name",
      message: "Other Embellishement name selected is already in use, please choose a different name.",
    });
  }
  return errors;
}

const validatePrice = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { price } = embellishment;
  if (!price) {
    errors.push({
      tabIndex,
      name: prefixName + "price",
      message: "Please specify the Other Embellishment price.",
    });
  }
  return errors;
};
const validateOtherEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment,
  tabIndex: number,
  prefixName: string,
  list: DraperyOrderDesignEmbellishment[],
  targetArrayIndex?: number,
  orderType?: string,
) => {
  const foundSameName = list && list.length > 0 ? list.find((i, idx) => {
    if (i.name) {
      return i.name === embellishment.name && idx !== targetArrayIndex
    }
    return false
  }) : false
  let errors: ValidationErrorInfo[] = [];
  const { name, price } = embellishment;
  if (!name) {
    errors = validateName(errors, tabIndex, embellishment, prefixName);
  }
  if (foundSameName) {
    errors = validateUniqueName(errors, tabIndex, foundSameName ? true : false, prefixName)
  }
  if (orderType && orderType !== 'roman-shade-order' && !price) {
    errors = validatePrice(errors, tabIndex, embellishment, prefixName);
  }

  return errors;
};

const validateTrimEmbellishment = (
  embellishment: DraperyOrderDesignEmbellishment,
  tabIndex: number,
  prefixName: string,
  list: DraperyOrderDesignEmbellishment[]
) => {
  let errors: ValidationErrorInfo[] = [];
  const { leadReturnEdge, topEdge, bottomEdge, subType } = embellishment;
  const index = Number(prefixName.split(".")[1]);
  const numEmbellishment = list.filter(
    (embellishment) => embellishment.type === 4
  ).length;
  let embellishmentName = `Trim ${numEmbellishment > 1 ? index + 1 : ""}`;
  // trim validation
  errors = checkUndefinedTypes(
    errors,
    tabIndex,
    prefixName,
    "subType",
    "Please select the trim type.",
    subType
  );

  // if (!leadReturnEdge.active && !topEdge.active && !bottomEdge.active) {
  //   errors.push({
  //     tabIndex,
  //     name: prefixName + "leadReturnEdge.active",
  //     message: "Please specify the location for trim placement.",
  //   });
  // }

  if (
    !topEdge.shade && !bottomEdge.shade && !leadReturnEdge.shade &&
    !topEdge.valance && !bottomEdge.valance && !leadReturnEdge.valance
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.shade",
      message: `Please specify the location for ${embellishmentName} placement.`,
    });
  }

  if (leadReturnEdge.active) {
    errors = validateTrimLeadReturnEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (leadReturnEdge.shade || leadReturnEdge.valance) {
    errors = validateTrimSidesWidth(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    leadReturnEdge.insetActive &&
    (!leadReturnEdge.insetSize || Number(leadReturnEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "leadReturnEdge.insetSize",
      message: `Please specify ${embellishmentName} inset.`,
    });
  }

  if (topEdge.active || topEdge.shade || topEdge.valance) {
    errors = validateTrimTopEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    topEdge.insetActive &&
    (!topEdge.insetSize || Number(topEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "topEdge.insetSize",
      message: `Please specify ${embellishmentName} inset.`,
    });
  }

  if (bottomEdge.active || bottomEdge.shade || bottomEdge.valance) {
    errors = validateTrimBottomEdge(
      errors,
      tabIndex,
      embellishment,
      prefixName
    );
  }

  if (
    bottomEdge.insetActive &&
    (!bottomEdge.insetSize || Number(bottomEdge.insetSize) === 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.insetSize",
      message: `Please specify ${embellishmentName} inset.`,
    });
  }
  return errors;
};

const validateTrimBottomEdge = (
  errors: ValidationErrorInfo[],
  tabIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  prefixName: string
) => {
  const { bottomEdge, subType } = embellishment;
  if (
    (bottomEdge.size === undefined || bottomEdge.size === "") &&
    subType === 1
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message: "Please specify the trim width for the bottom placement.",
    });
  } else if (
    subType === 1 &&
    (isNaN(+bottomEdge.size!) || +bottomEdge.size! <= 0)
  ) {
    errors.push({
      tabIndex,
      name: prefixName + "bottomEdge.size",
      message: "Trim width must be a value greater than 0.",
    });
  }
  return errors;
};

export const validateEmbellishment = (
  targetArray: string,
  targetArrayIndex: number,
  embellishment: DraperyOrderDesignEmbellishment,
  list: DraperyOrderDesignEmbellishment[],
  orderType?: string,
): ValidationErrorInfo[] => {
  const prefixName = `${targetArray}.${targetArrayIndex}.`;
  const tabIndex = 1;
  let errors: ValidationErrorInfo[] = [];
  const embelType = embellishment.type;

  switch (embelType) {
    case 1: {
      errors = validateBandingEmbellishment(
        embellishment,
        tabIndex,
        prefixName,
        list
      );
      break;
    }
    case 2: {
      errors = validateCordingEmbellishment(
        embellishment,
        tabIndex,
        prefixName,
        list
      );
      break;
    }
    case 3: {
      errors = validateFlangeEmbellishment(
        embellishment,
        tabIndex,
        prefixName,
        list
      );
      break;
    }
    case 4: {
      errors = validateTrimEmbellishment(
        embellishment,
        tabIndex,
        prefixName,
        list
      );
      break;
    }
    case 5: {
      errors = validateOtherEmbellishment(
        embellishment,
        tabIndex,
        prefixName,
        list,
        targetArrayIndex,
        orderType
      );
      break;
    }
    default:
      break;
  }

  return errors;
};

export const validateEmbellishmentListRomanShade = (
  embellishmentsInfo: EmbellishmentCheckInfo,
  list: DraperyOrderDesignEmbellishment[],
  targetArray: string,
  orderType?: string,
): ValidationErrorInfo[] => {
  let errors: ValidationErrorInfo[] = [];
  if (embellishmentsInfo.checked) {
    for (const [index, embellishment] of list.entries()) {
      errors = errors.concat(
        validateEmbellishment(targetArray, index, embellishment, list, orderType)
      );
    }
  }
  return errors;
};

export const validateLiningsList = (
  list: DraperyModule.DraperyOrderDesignLining[],
  isNoneLinings?: boolean
): ValidationErrorInfo[] => {
  let errors: ValidationErrorInfo[] = [];
  let isSelected: boolean = false;
  for (const [index, lining] of list.entries()) {
    errors = errors.concat(validateLining(index, lining));
    isSelected = isSelected || lining.active;
  }
  if (!isNoneLinings && !isSelected) {
    errors.push({
      tabIndex: 1,
      name: "isNoneLinings",
      message: "Please select a linings option.",
    });
  }
  if (isNoneLinings && isSelected) {
    errors.push({
      tabIndex: 1,
      name: "isNoneLinings",
      message: "You cannot select a lining and none at the same time.",
    });
  }
  return errors;
};

export const validateEmbellishmentChecked = (
  list: EmbellishmentCheckInfo[],
  isNoneEmbellishment?: boolean
): ValidationErrorInfo[] => {
  const errors: ValidationErrorInfo[] = [];
  let isSelected: boolean = false;
  for (const embellishmentInfo of list) {
    isSelected = isSelected || embellishmentInfo.checked;
  }
  if (!isNoneEmbellishment && !isSelected) {
    errors.push({
      tabIndex: 1,
      name: "isNoneEmbellishment",
      message: "Please select an embellishments option.",
    });
  }
  if (isNoneEmbellishment && isSelected) {
    errors.push({
      tabIndex: 1,
      name: "isNoneEmbellishment",
      message:
        "You cannot select a embellishment and none at the same time.",
    });
  }
  return errors;
};

const LiningsWrap = styled.div`
    display: flex;
    align-items: flex-end;
`;

const AdditionalFormContainer = styled.div`
    border: 1px solid #bebebe;
    background: #fff;
    width: 100%;
    margin: 5px auto 0 auto;
    max-width: 900px;
    min-width: 570px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    > div:first-child {
        margin-top: 0;
    }

    > div {
        margin-top: 20px;
    }
`;

interface PanelConf {
  name: string;
  title: string;
  numberPanels: number;
  size: string;
}

interface PleatType {
  image: string;
  type: string;
  title: string;
  value: any;
  active: boolean;
  isDeleted?: boolean;
}

export const panelsConfiguration: PanelConf[] = [
  { name: "center", title: "SINGLE CENTER", numberPanels: 1, size: "small" },
  { name: "left", title: "LEFT", numberPanels: 1, size: "small" },
  { name: "right", title: "RIGHT", numberPanels: 1, size: "small" },
  { name: "pair", title: "PAIR", numberPanels: 2, size: "small" },
  {
    name: "three-panels",
    title: "3 PANELS",
    numberPanels: 3,
    size: "middle",
  },
  { name: "four-panels", title: "4 PANELS", numberPanels: 4, size: "long" },
  { name: "five-panels", title: "5 PANELS", numberPanels: 5, size: "long" },
];

export const pleatTypeList: PleatType[] = [
  {
    type: "rodandrings",
    title: "NONE (FLAT)",
    value: "NONE (FLAT)",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "EURO (2 FINGER)",
    value: "EURO (2 FINGER)",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "EURO (3 FINGER)",
    value: "EURO (3 FINGER)",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "GOBLET",
    value: "GOBLET",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "INVERTED BOX",
    value: "INVERTED BOX",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "PINCH (2 FINGER)",
    value: "PINCH (2 FINGER)",
    image: "",
    active: true,
  },
  {
    type: "rodandrings",
    title: "PINCH (3 FINGER)",
    value: "PINCH (3 FINGER)",
    image: "",
    active: true,
  },
];

interface Props {
  draperyOrderDesign: DraperyModule.DraperyOrderDesign;
  liningsList: DraperyModule.DraperyOrderDesignLining[];
  isNoneLinings?: boolean;
  embellishmentsList: EmbellishmentCheckInfo[];
  isNoneEmbellishment?: boolean;
  isCustomizeConstructionOpen: boolean;
  disabled: boolean;
  highlightedSection: string | null | undefined;
  data?: any;
  workroomSetting: WorkroomSetting | null;
  formErrorID?: string;

  openImageModal(name: string, option: "add" | "delete"): void;
  resetPanelConf(
    val?: string | number | undefined,
    name?: string | undefined
  ): any;
  resetPleatType(): void;
  addNewLining(): void;
  addEmbellishment(targetArray: string): void;
  handleRemoveRomanShadeCategory(e: any): void;
  deleteEmbellishment(stateName: string, index: number): void;
  toggleCustomizeConstruction(): void;
  handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
  handleChangeRipplefoldOptions(e: ChangeEvent<HTMLInputElement>): void;
  handleRemoveRipplefoldDetail(key: string, value: string | undefined | null): void;
  handleInputChangeForNumberPrice(e: ChangeEvent<HTMLInputElement>): void;
  handleInputChangeForString(e: any): void;
  handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
  handleSelectListChange(value: string | number, name?: string): void;
  updateStateByName(
    name: string,
    value:
      | string
      | number
      | boolean
      | number[]
      | undefined
      | { otherPleatImageId?: string }
  ): void;
  onShapeChanged(shapes: DraperyModule.Shape[]): void;
  getEmbellishmentsByIndex(index: number): {
    name: string;
    list: DraperyOrderDesignEmbellishment[];
  };
  handleBuckramSelect(e: ChangeEvent<HTMLSelectElement>): void;
  handleFullnessSelect(e: ChangeEvent<HTMLSelectElement>): void;
  handleInputBlur?(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void;
  draperyItemDetail?: any;
  dispatch: Dispatch<FormFieldsAction>;
  workOrderEditMode: boolean;
  handleRemoveDraperyDesignCategory(value: number): void;
  openConstructionDetailsEditModal?(): void;
  resetDraperyOrderEmbellishments(): void;
  resetLinings(): void;
  orderType?: string;
  romanShadeJson: RomanShadeJson
  numberingTrims(arg?: string | undefined): void;
}

interface State {
  widthList: string[];
  dynamicPleatTypesAdded: boolean;
  pleatTypeListNew: PleatType[];
  isPleatModalOpen: boolean;
}

class RomanShadeDesignTab extends Component<Props, State> {
  private drawingRef = React.createRef<Drawing>();

  public constructor(props: Props) {
    super(props);

    this.state = {
      widthList: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9"
      ],
      dynamicPleatTypesAdded: false,
      pleatTypeListNew: [],
      isPleatModalOpen: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      data,
    } = this.props;

    const { draperyItemDetail } = this.props;
    let dynamicPleatTypes: PleatType[] = [];

    if (
      draperyItemDetail &&
      draperyItemDetail[0] &&
      draperyItemDetail[0].pleatTypesWithPricing &&
      !this.state.dynamicPleatTypesAdded
    ) {
      draperyItemDetail[0].pleatTypesWithPricing.forEach(
        (pleatType: {
          image: string;
          newPleatId: any;
          name: any;
          isActive: boolean;
          isDeleted: boolean;
        }) => {
          if (pleatType.newPleatId) {
            dynamicPleatTypes.push({
              type: "rodandrings",
              title: pleatType.name,
              value: pleatType.newPleatId,
              image: pleatType.image,
              active: pleatType.isActive,
              isDeleted: pleatType.isDeleted,
            });
          }
        }
      );

      this.setState(
        {
          dynamicPleatTypesAdded: true,
          pleatTypeListNew: [
            ...this.state.pleatTypeListNew,
            ...pleatTypeList,
            ...dynamicPleatTypes,
          ],
        },
        () => { }
      );
    }
    if (data.draperyOrderDesign.headingType === "ripplefold" && data.draperyOrderDesign.pleatType !== "") {
      data.draperyOrderDesign.pleatType = "";
    }
  }

  public drawingBlob = async (): Promise<Blob | null> => {
    if (this.drawingRef.current) {
      return this.drawingRef.current.convertToImage();
    } else {
      return null;
    }
  };

  public render() {
    return (
      <>
        {this.renderFirstQuestion()}
        {this.renderSecondQuestion()}
        {this.renderThirdQuestion()}
        {this.renderFourthQuestion()}
        {this.renderFifthQuestion()}
        {this.renderDrawingSection()}
        {this.renderSeventhQuestion()}
        {/* {this.renderConstructionDiv()} */}
        <DraperyHeader>WORK ORDER NOTES</DraperyHeader>
        {this.renderNotesSection()}
      </>
    );
  }

private renderPanelWidthSelect = (side: string) => {
  const sideName = side.toLocaleLowerCase()
  let hedrail = this.props.draperyOrderDesign.romanShadeJson.headrail
  let width 
  if (sideName === 'left') { 
    width = hedrail.controlLocationLeft
  } else { 
    width = hedrail.controlLocationRight
  }
  const value = width === null ? "" : width.toString();
  return (
      <PanelWidthSelectWrap>
          <SelectList
              label={""}
              selectList={
                sideName === 'right'
                  ? this.state.widthList.filter(value => value !== "0")
                  : this.state.widthList
              }
              handleClickSelect={(value, name) => {
                  this.props.handleSelectListChange(value, name);
              }}
              valueSelect={value}
              flexDirection={"column"}
              name={`draperyOrderDesign.romanShadeJson.headrail.controlLocation${side}`}
              disabled={this.props.workOrderEditMode ? false : this.props.disabled}
              smallPadding
              noClearBox
              uiDropArrow
              noBorderBottom
              editable
              type="number"
              selectWidth={90}
              panelCustomWidths
          />
          <RegularInputGroupLabel style={{marginLeft: '40px'}}>{side}</RegularInputGroupLabel>
      </PanelWidthSelectWrap>
  );
};


  private renderFirstQuestion = (): JSX.Element => {
    const { formErrorID, draperyOrderDesign, highlightedSection } =
        this.props;
        const romanShadeJson = this.props.draperyOrderDesign.romanShadeJson
    return (
        <CalculatorFormField
            id="1"
            uiInline
            uiNoControlWrap
            uiNoMargin
            isError={highlightedSection === "draperyOrderDesign.romanShadeJson.style"}>
              <RomanShadeDesignHeader>
              <CalculatorFormQuestionNumber uiError={formErrorID === "1"}>
                  1
              </CalculatorFormQuestionNumber>
               <DraperyHeader>FUNCTION & STYLE</DraperyHeader>
              </RomanShadeDesignHeader>
            <CalculatorRadioGroupWrap
                style={highlightedSection === 'draperyOrderDesign.romanShadeJson.style.type' ? highlightStyle : {}}>
                <CalculatorRadioWrapperWithImg
                    uiChecked={
                      romanShadeJson && romanShadeJson.style && romanShadeJson.style.type === "flat"
                    }
                    uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                >
                    <CalculatorImageRadio
                        type="radio"
                        id="draperyOrderDesign.romanShadeJson.style.type"
                        name="draperyOrderDesign.romanShadeJson.style.type"
                        value="flat"
                        checked={
                          romanShadeJson && romanShadeJson.style && romanShadeJson.style.type === "flat"
                        }
                        onClick={this.props.handleInputChangeForString}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    <FlatRomanShade />
                    FLAT
                </CalculatorRadioWrapperWithImg>

                <CalculatorRadioWrapperWithImg
                    uiChecked={
                      romanShadeJson && romanShadeJson.style && romanShadeJson.style.type === "relaxed"
                    }
                    uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                >
                    <CalculatorImageRadio
                        type="radio"
                        id="draperyOrderDesign.romanShadeJson.style.type"
                        name="draperyOrderDesign.romanShadeJson.style.type"
                        value="relaxed"
                        checked={
                            draperyOrderDesign.headingType === "relaxed"
                        }
                        onClick={this.props.handleInputChangeForString}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    <RelaxedRomanShade />
                    RELAXED
                </CalculatorRadioWrapperWithImg>
            </CalculatorRadioGroupWrap>
            <RomanShadeSection>
              <CalculatorRadioGroup style={highlightedSection === 'draperyOrderDesign.romanShadeJson.style.function' ? highlightStyle : {}}>
                <RomanShadeDesignHeaderSubtitle>Function</RomanShadeDesignHeaderSubtitle> 
                <RadioLabelExpanded className={`design_category ${romanShadeJson.style.function === 0 ? 'active' : ''}`}>
                    <RadioInput
                        type="radio"
                        id="draperyOrderDesign.romanShadeJson.style.function-Functional"
                        name="draperyOrderDesign.romanShadeJson.style.function"
                        value={0}
                        checked={romanShadeJson.style.function === 0}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForNumber}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        />
                    Functional
                </RadioLabelExpanded>
                <RadioLabelExpanded className={`design_category ${romanShadeJson.style.function === 1 ? 'active' : ''}`}>
                    <RadioInput
                        type="radio"
                        id="draperyOrderDesign.romanShadeJson.style.function-Faux"
                        name="draperyOrderDesign.romanShadeJson.style.function"
                        value={1}
                        checked={romanShadeJson.style.function === 1}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForNumber}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        />
                    Faux
                </RadioLabelExpanded>
              </CalculatorRadioGroup>
              <CalculatorRadioGroup>
              <RomanShadeDesignHeaderSubtitle>Window Mount</RomanShadeDesignHeaderSubtitle> 
                        <RadioLabelExpanded className={`design_category ${romanShadeJson.style.windowMount === 0 ? 'active' : ''}`}>
                            <RadioInput
                                type="radio"
                                id="draperyOrderDesign.romanShadeJson.style.windowMount-inside"
                                name="draperyOrderDesign.romanShadeJson.style.windowMount"
                                value={0}
                                checked={romanShadeJson.style.windowMount === 0}
                                onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                                uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                                />
                            Inside
                        </RadioLabelExpanded>
                        <RadioLabelExpanded className={`design_category ${romanShadeJson.style.windowMount === 1 ? 'active' : ''}`}>
                            <RadioInput
                                type="radio"
                                id="draperyOrderDesign.romanShadeJson.style.windowMount-outside"
                                name="draperyOrderDesign.romanShadeJson.style.windowMount"
                                value={1}
                                checked={romanShadeJson.style.windowMount === 1}
                                onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                                onChange={this.props.handleInputChangeForNumber}
                                disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                                uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                                />
                            Outside
                        </RadioLabelExpanded>
              </CalculatorRadioGroup>
              <CalculatorRadioGroup>
              <RomanShadeDesignHeaderSubtitle>Board Mount</RomanShadeDesignHeaderSubtitle> 
                  <RadioLabelExpanded className={`design_category ${romanShadeJson.style.boardMount === 0 ? 'active' : ''}`}>
                      <RadioInput
                          type="radio"
                          id="draperyOrderDesign.romanShadeJson.style.boardMount-Waterfall"
                          name="draperyOrderDesign.romanShadeJson.style.boardMount"
                          value={0}
                          checked={romanShadeJson.style.boardMount === 0}
                          onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                          onChange={this.props.handleInputChangeForNumber}
                          disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                          uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                          />
                      Waterfall (front)
                  </RadioLabelExpanded>
                  <RadioLabelExpanded className={`design_category ${romanShadeJson.style.boardMount === 1 ? 'active' : ''}`}>
                      <RadioInput
                          type="radio"
                          id="draperyOrderDesign.romanShadeJson.style.boardMount-Reverse"
                          name="draperyOrderDesign.romanShadeJson.style.boardMount"
                          value={1}
                          checked={romanShadeJson.style.boardMount === 1}
                          onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                          onChange={this.props.handleInputChangeForNumber}
                          disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                          uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                          />
                      Reverse (back)
                  </RadioLabelExpanded>
              </CalculatorRadioGroup>
            </RomanShadeSection>
        </CalculatorFormField>
    );
};

 private renderSecondQuestion = (): JSX.Element => {
    const { formErrorID, highlightedSection } = this.props;

    // const isLiningActive = liningsList.some((lining) => lining.active);
    // const isNoneLinings = this.props.isNoneLinings;
    const romanShadeJson = this.props.draperyOrderDesign.romanShadeJson

    return (
      <CalculatorFormField
        id="2"
        uiInline
        uiNoMargin
        isError={highlightedSection === "draperyOrderDesign.romanShadeJson.dimensions"}
      >
        <RomanShadeDesignHeader>
          <CalculatorFormQuestionNumber uiError={formErrorID === "2"}>
            2
          </CalculatorFormQuestionNumber>
          <DraperyHeader>
            DIMENSIONS
          </DraperyHeader>
        </RomanShadeDesignHeader>

        <FabricDimensionInputGroup style={highlightedSection === 'draperyOrderDesign.romanShadeJson.dimensions.finishedWidth' ? highlightStyle : {}}>
          <Width170>
            <RomanShadeDesignHeaderSubtitle>Finished Width</RomanShadeDesignHeaderSubtitle>
          </Width170>
          <PrependAppendInputGroupRomanShade uiSmall>
          <PrependAppendInput
            id="draperyOrderDesign.romanShadeJson.dimensions.finishedWidth"
            name="draperyOrderDesign.romanShadeJson.dimensions.finishedWidth"
            autoComplete="off"
            type="text"
            value={romanShadeJson.dimensions.finishedWidth 
              ? romanShadeJson.dimensions.finishedWidth
              : ""}
            onChange={this.props.handleInputChangeForNumber}
            disabled={this.props.workOrderEditMode ? false : this.props.disabled}
            uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
          />
          <RegularInputGroupLabel>in.</RegularInputGroupLabel>
          </PrependAppendInputGroupRomanShade>
        </FabricDimensionInputGroup>

        <FabricDimensionInputGroup style={highlightedSection === 'draperyOrderDesign.romanShadeJson.dimensions.finishedLength' ? highlightStyle : {}}>
          <Width170>

            <RomanShadeDesignHeaderSubtitle>{romanShadeJson.style.function === 1 ? "Hanging Length" : "Finished Length"}</RomanShadeDesignHeaderSubtitle>
            {/* TODO: tooltip position  */}
            <TooltipPopup
              text={
                <TooltipContainer>
                    {romanShadeJson.style.function === 1 ? 
                    (
                      <TooltipText>
                        <strong>Hanging Length</strong> refers to the length of a stationary shade when it is pulled up into its final position.
                      </TooltipText>
                    ) : 
                    (
                      <TooltipText>
                        <strong>Finished Length</strong> refers to the length of the shade fabric when it is fully extended.
                      </TooltipText>
                    )
                  }
                  </TooltipContainer>
              }
              position="top left"
              className="normal"
              />
            </Width170>
          <PrependAppendInputGroupRomanShade uiSmall>
          <PrependAppendInput
            id="draperyOrderDesign.romanShadeJson.dimensions.finishedLength"
            name="draperyOrderDesign.romanShadeJson.dimensions.finishedLength"
            autoComplete="off"
            type="text"
            value={!!romanShadeJson.dimensions.finishedLength
              ? romanShadeJson.dimensions.finishedLength
              : ""}
            onChange={this.props.handleInputChangeForNumber}
            disabled={this.props.workOrderEditMode ? false : this.props.disabled}
            uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
          />
          <RegularInputGroupLabel>in.</RegularInputGroupLabel>
          </PrependAppendInputGroupRomanShade>
        </FabricDimensionInputGroup>

        <FabricDimensionInputGroup style={highlightedSection === 'draperyOrderDesign.romanShadeJson.dimensions.foldSize' ? highlightStyle : {}}>
          <Width170>

            <RomanShadeDesignHeaderSubtitle>Folds</RomanShadeDesignHeaderSubtitle>
            {/* todo: tooltip position  */}
            <RegularInputGroupLabel style={{marginLeft: 'auto'}}>Size.</RegularInputGroupLabel>
              <TooltipPopup
                text={
                    <TooltipContainer>
                        <TooltipText>
                          <strong>Fold size</strong> refers to half the vertical distance between rings. For example, if using 8” of material folded in half, the fold size is 4”.
                        </TooltipText>
                    </TooltipContainer>
                }
                position="top center"
                className="normal"
              />
          </Width170>
            <PrependAppendInputGroupRomanShade uiSmall centeredFlex>
              <PrependAppendInput
                id="draperyOrderDesign.romanShadeJson.dimensions.foldSize"
                name="draperyOrderDesign.romanShadeJson.dimensions.foldSize"
                autoComplete="off"
                type="text"
                min={0}
                value={romanShadeJson.dimensions.foldSize
                  ? romanShadeJson.dimensions.foldSize
                  : ''}
                onChange={(e)=> this.props.handleInputChangeForNumber(e)}
                disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
              />
              <RegularInputGroupLabel>in.</RegularInputGroupLabel>
            </PrependAppendInputGroupRomanShade>
            <PrependAppendInputGroupRomanShade uiSmall centeredFlex >
            <RegularInputGroupLabel># of Permanent Folds</RegularInputGroupLabel>
              <PrependAppendInput
                id="draperyOrderDesign.romanShadeJson.dimensions.numOfPermanentFolds"
                name="draperyOrderDesign.romanShadeJson.dimensions.numOfPermanentFolds"
                autoComplete="off"
                type="text"
                value={
                  romanShadeJson.dimensions.numOfPermanentFolds != null &&
                  romanShadeJson.dimensions.numOfPermanentFolds >= 0
                    ? romanShadeJson.dimensions.numOfPermanentFolds
                    : ""
                } 
                onChange={(e)=> this.props.handleInputChangeForNumber(e)}
                disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
              />
            </PrependAppendInputGroupRomanShade>
        </FabricDimensionInputGroup>
        <FabricDimensionInputGroup style={highlightedSection === 'draperyOrderDesign.romanShadeJson.dimensions.bottomSkirtLength' ? highlightStyle : {}}>
          <Width170>

            <RomanShadeDesignHeaderSubtitle style={{marginRight: 'auto'}}>Bottom Skirt</RomanShadeDesignHeaderSubtitle>
              <TooltipPopup
                text={
                    <TooltipContainer>
                        <TooltipText>
                          <strong>Bottom skirt</strong> is also commonly referred to as the bottom flap, reveal, or footer. It refers to moving the rings up in position on the back of the shade to allow the bottom of the shade material to be exposed beneath the folds when it is lifted.
                        </TooltipText>
                    </TooltipContainer>
                }
                position="top center"
                className="normal"
              />
          </Width170>
            <CalculatorRadioGroup>
                <RadioLabel className={`design_category ${romanShadeJson.dimensions.bottomSkirit === 0 ? 'active' : ''}`}>
                    <RadioInput
                        type="radio"
                        id="draperyOrderDesign.romanShadeJson.dimensions.bottomSkirit-No"
                        name="draperyOrderDesign.romanShadeJson.dimensions.bottomSkirit"
                        value={0}
                        checked={romanShadeJson.dimensions.bottomSkirit === 0}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForNumber}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        />
                    No
                </RadioLabel >
                <RadioLabel  className={`design_category ${romanShadeJson.dimensions.bottomSkirit === 1 ? 'active' : ''}`}>
                    <RadioInput
                        type="radio"
                        id="draperyOrderDesign.romanShadeJson.dimensions.bottomSkirit-Yes"
                        name="draperyOrderDesign.romanShadeJson.dimensions.bottomSkirit"
                        value={1}
                        checked={romanShadeJson.dimensions.bottomSkirit === 1}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForNumber}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        />
                    Yes
                </RadioLabel >
              </CalculatorRadioGroup>
              {
                romanShadeJson.dimensions.bottomSkirit === 1 && 
                  <PrependAppendInputGroupRomanShade uiSmall centeredFlex >
                  <RegularInputGroupLabel>Length</RegularInputGroupLabel>
                    <PrependAppendInput
                      id="draperyOrderDesign.romanShadeJson.dimensions.bottomSkirtLength"
                      name="draperyOrderDesign.romanShadeJson.dimensions.bottomSkirtLength"
                      autoComplete="off"
                      type="text"
                      value={!!romanShadeJson.dimensions.bottomSkirtLength 
                        ? romanShadeJson.dimensions.bottomSkirtLength
                        : ""}
                      onChange={this.props.handleInputChangeForNumber}
                      disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                      uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    <RegularInputGroupLabel>in.</RegularInputGroupLabel>
                  </PrependAppendInputGroupRomanShade>
              }
        </FabricDimensionInputGroup>
      </CalculatorFormField>
    );
  };

  private renderThirdQuestion = (): JSX.Element => {
    const { formErrorID, /*liningsList,*/ highlightedSection, draperyOrderDesign } = this.props;
 const romanShadeJson = draperyOrderDesign.romanShadeJson 
    return (
      <CalculatorFormField
        id="3"
        uiInline
        uiNoMargin
        isError={highlightedSection === "draperyOrderDesign.romanShadeJson.valanceAndReturns"}
      >
        <RomanShadeDesignHeader>
          <CalculatorFormQuestionNumber uiError={formErrorID === "3"}>
            3
          </CalculatorFormQuestionNumber>
          <DraperyHeader>
            VALANCE & RETURNS
          </DraperyHeader>
        </RomanShadeDesignHeader>
        <FabricDimensionInputGroup style={highlightedSection === 'draperyOrderDesign.romanShadeJson.valanceAndReturns.frontValance' ? highlightStyle : {}}>
          <Width170>
            <RomanShadeDesignHeaderSubtitle style={{marginRight: 'auto'}}>Front Valance</RomanShadeDesignHeaderSubtitle>
          </Width170>
            <CalculatorRadioGroup >
                <RadioLabel className={`design_category ${romanShadeJson.valanceAndReturns.frontValance === 0 ? 'active' : ''}`}>
                    <RadioInput
                        type="radio"
                        id="draperyOrderDesign.romanShadeJson.valanceAndReturns.frontValance-No"
                        name="draperyOrderDesign.romanShadeJson.valanceAndReturns.frontValance"
                        value={0}
                        checked={romanShadeJson.valanceAndReturns.frontValance === 0}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForNumber}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        />
                    No
                </RadioLabel >
                <RadioLabel  className={`design_category ${romanShadeJson.valanceAndReturns.frontValance === 1 ? 'active' : ''}`}>
                    <RadioInput
                        type="radio"
                        id="draperyOrderDesign.romanShadeJson.valanceAndReturns.frontValance-Yes"
                        name="draperyOrderDesign.romanShadeJson.valanceAndReturns.frontValance"
                        value={1}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        checked={romanShadeJson.valanceAndReturns.frontValance === 1}
                        onChange={this.props.handleInputChangeForNumber}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        />
                    Yes
                </RadioLabel >
              </CalculatorRadioGroup>
              {
                romanShadeJson.valanceAndReturns.frontValance === 1 && 
                  <PrependAppendInputGroupRomanShade style={highlightedSection === 'draperyOrderDesign.romanShadeJson.valanceAndReturns.frontValanceLength' ? highlightStyle : {}} uiSmall centeredFlex>
                  <RegularInputGroupLabel>Length</RegularInputGroupLabel>
                    <PrependAppendInput
                      id="draperyOrderDesign.romanShadeJson.valanceAndReturns.frontValanceLength"
                      name="draperyOrderDesign.romanShadeJson.valanceAndReturns.frontValanceLength"
                      autoComplete="off"
                      type="text"
                      value={!!romanShadeJson.valanceAndReturns.frontValanceLength
                        ? romanShadeJson.valanceAndReturns.frontValanceLength
                        : ""}
                      onChange={this.props.handleInputChangeForNumber}
                      disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                      uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    <RegularInputGroupLabel>in.</RegularInputGroupLabel>
                  </PrependAppendInputGroupRomanShade>
              }
        </FabricDimensionInputGroup>
        <FabricDimensionInputGroup style={highlightedSection === 'draperyOrderDesign.romanShadeJson.valanceAndReturns.sides' ? highlightStyle : {}}>
          <Width170>
            <RomanShadeDesignHeaderSubtitle style={{marginRight: 'auto'}}>Sides</RomanShadeDesignHeaderSubtitle>
            <TooltipPopup
              text={
                <TooltipContainer>
                  <TooltipText>
                    <strong>Side Flaps</strong> are separate pieces of fabric that cover the sides of a headrail or board. They often are attached using velcro or staples or can be seamed to the face fabric. Side flaps are typically as wide as the projection; the length varies and should be specified. (Side Flaps are often about 4-6” long, depending on the coverage desired.)
                  </TooltipText>

                  <TooltipText>
                    <strong>Wrapped Returns</strong> are made from one continuous piece of fabric that covers the face and both sides of the headrail/board to cover the projection. Wrapped Returns increase the cut width by 2x the projection and are as long as the finished length of the shade or valance (depending on whether the Wrapped Returns are selected for the shade or valance).
                  </TooltipText>
                </TooltipContainer>
              }
              position="top left"
              className="normal"
              />
          </Width170>
                <CalculatorRadioWrapperRomanShade style={{paddingLeft: '20px'}}>
                  <CalculatorRadio
                        type="radio"
                        name="draperyOrderDesign.romanShadeJson.valanceAndReturns.sides"
                        value={"none"}
                        checked={!!(romanShadeJson.valanceAndReturns.sides && romanShadeJson.valanceAndReturns.sides === "none")}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForString}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                   />
                    None
                </CalculatorRadioWrapperRomanShade>
                <CalculatorRadioWrapperRomanShade>
                    <CalculatorRadio
                        type="radio"
                        name="draperyOrderDesign.romanShadeJson.valanceAndReturns.sides"
                        value={"returns"}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        checked={!!(romanShadeJson.valanceAndReturns.sides && romanShadeJson.valanceAndReturns.sides === "returns")}
                        onChange={this.props.handleInputChangeForString}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    Wrapped Returns
                </CalculatorRadioWrapperRomanShade>
                <CalculatorRadioWrapperRomanShade>
                    <CalculatorRadio
                        type="radio"
                        name="draperyOrderDesign.romanShadeJson.valanceAndReturns.sides"
                        value={"side-flaps"}
                        checked={!!(romanShadeJson.valanceAndReturns.sides && romanShadeJson.valanceAndReturns.sides === "side-flaps")}
                        onChange={this.props.handleInputChangeForString}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    Side Flaps
                </CalculatorRadioWrapperRomanShade>
              {
               (romanShadeJson.valanceAndReturns.sides === 'side-flaps') && romanShadeJson.valanceAndReturns.frontValance !== 1 &&
                  <PrependAppendInputGroupRomanShade uiSmall centeredFlex style={highlightedSection === 'draperyOrderDesign.romanShadeJson.valanceAndReturns.sidesValanceLength' ? highlightStyle : {}}>
                  <RegularInputGroupLabel>Length</RegularInputGroupLabel>
                    <PrependAppendInput
                      id="draperyOrderDesign.romanShadeJson.valanceAndReturns.sidesValanceLength"
                      name="draperyOrderDesign.romanShadeJson.valanceAndReturns.sidesValanceLength"
                      autoComplete="off"
                      type="text"
                      value={!!romanShadeJson.valanceAndReturns.sidesValanceLength
                        ? romanShadeJson.valanceAndReturns.sidesValanceLength
                        : ""}
                      onChange={this.props.handleInputChangeForNumber}
                      disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                      uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    <RegularInputGroupLabel>in.</RegularInputGroupLabel>
                  </PrependAppendInputGroupRomanShade>
              }
        </FabricDimensionInputGroup>
        <FabricDimensionInputGroup style={highlightedSection === 'draperyOrderDesign.romanShadeJson.valanceAndReturns.backValance' ? highlightStyle : {}}>
          <Width170>
            <RomanShadeDesignHeaderSubtitle style={{marginRight: 'auto'}}>Back Valance</RomanShadeDesignHeaderSubtitle>
          </Width170>
            <CalculatorRadioGroup>
                <RadioLabel className={`design_category ${romanShadeJson.valanceAndReturns.backValance === 0 ? 'active' : ''}`}>
                    <RadioInput
                        type="radio"
                        id="draperyOrderDesign.romanShadeJson.valanceAndReturns.backValance-No"
                        name="draperyOrderDesign.romanShadeJson.valanceAndReturns.backValance"
                        value={0}
                        checked={romanShadeJson.valanceAndReturns.backValance === 0}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForNumber}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        />
                    No
                </RadioLabel >
                <RadioLabel  className={`design_category ${romanShadeJson.valanceAndReturns.backValance === 1 ? 'active' : ''}`}>
                    <RadioInput
                        type="radio"
                        id="draperyOrderDesign.romanShadeJson.valanceAndReturns.backValance-Yes"
                        name="draperyOrderDesign.romanShadeJson.valanceAndReturns.backValance"
                        value={1}
                        checked={romanShadeJson.valanceAndReturns.backValance === 1}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForNumber}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        />
                    Yes
                </RadioLabel >
              </CalculatorRadioGroup>
              {
                romanShadeJson.valanceAndReturns.backValance === 1 && 
                  <PrependAppendInputGroupRomanShade uiSmall centeredFlex style={highlightedSection === 'draperyOrderDesign.romanShadeJson.valanceAndReturns.backValanceLength' ? highlightStyle : {}}>
                  <RegularInputGroupLabel>Length</RegularInputGroupLabel>
                    <PrependAppendInput
                      id="draperyOrderDesign.romanShadeJson.valanceAndReturns.backValanceLength"
                      name="draperyOrderDesign.romanShadeJson.valanceAndReturns.backValanceLength"
                      autoComplete="off"
                      type="text"
                      value={!!romanShadeJson.valanceAndReturns.backValanceLength
                        ? romanShadeJson.valanceAndReturns.backValanceLength
                        : ""}
                      onChange={this.props.handleInputChangeForNumber}
                      disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                      uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    <RegularInputGroupLabel>in.</RegularInputGroupLabel>
                  </PrependAppendInputGroupRomanShade>
              }
        </FabricDimensionInputGroup>
      </CalculatorFormField>
    );
  };

  private renderFourthQuestion = (): JSX.Element => {
    const { formErrorID, highlightedSection, draperyOrderDesign} = this.props;
    const romanShadeJson = draperyOrderDesign.romanShadeJson

    return (
      <CalculatorFormField
        id="4"
        uiInline
        uiNoMargin
        isError={highlightedSection === "romanShadeJson.valanceAndReturns"}
      >
        <RomanShadeDesignHeader>
          <CalculatorFormQuestionNumber uiError={formErrorID === "4"}>
            4
          </CalculatorFormQuestionNumber>
          <DraperyHeader>
            HEADRAIL
          </DraperyHeader>
        </RomanShadeDesignHeader>
        <FabricDimensionInputGroup> 
          <Width170>
            <RomanShadeDesignHeaderSubtitle style={{marginRight: 'auto'}}>Lift System</RomanShadeDesignHeaderSubtitle>
          </Width170> 

          <RomanShadeDesginTextInput
              id="draperyOrderDesign.romanShadeJson.headrail.liftSystem"
              name="draperyOrderDesign.romanShadeJson.headrail.liftSystem"
              autoComplete="off"
              type="text"
              maxLength={30}
              value={!!romanShadeJson.headrail.liftSystem
                ? romanShadeJson.headrail.liftSystem
                : ""}
              onChange={this.props.handleInputChangeForString}
              disabled={this.props.workOrderEditMode ? false : this.props.disabled}
              uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
          >

          </RomanShadeDesginTextInput>
        </FabricDimensionInputGroup>
        <FabricDimensionInputGroup style={highlightedSection === 'draperyOrderDesign.romanShadeJson.headrail.boardFace' ? highlightStyle : {}}>
          <Width170> 
            <RomanShadeDesignHeaderSubtitle style={{ marginRight: 'auto' }}>Board Face</RomanShadeDesignHeaderSubtitle>
          </Width170>
          <PrependAppendInputGroupRomanShade uiSmall centeredFlex >
          <PrependAppendInput

              id="draperyOrderDesign.romanShadeJson.headrail.boardFace"
              name="draperyOrderDesign.romanShadeJson.headrail.boardFace"
              autoComplete="off"
              type="text"
              value={!!romanShadeJson.headrail.boardFace
                ? romanShadeJson.headrail.boardFace
                : ""}
              onChange={this.props.handleInputChangeForNumber}
              disabled={this.props.workOrderEditMode ? false : this.props.disabled}
              uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
            />
          <RegularInputGroupLabel>in.</RegularInputGroupLabel>
         <FabricDimensionInputGroup style={highlightedSection === 'draperyOrderDesign.romanShadeJson.headrail.boardDepth' ? highlightStyle : {marginBottom: 0}}>
          <RegularInputGroupLabel>Board Depth</RegularInputGroupLabel>
            <PrependAppendInput
              id="draperyOrderDesign.romanShadeJson.headrail.boardDepth"
              name="draperyOrderDesign.romanShadeJson.headrail.boardDepth"
              autoComplete="off"
              type="text"
              value={!!romanShadeJson.headrail.boardDepth 
                ? romanShadeJson.headrail.boardDepth
                : ""}
                onChange={this.props.handleInputChangeForNumber}
              disabled={this.props.workOrderEditMode ? false : this.props.disabled}
              uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
            />
            <RegularInputGroupLabel>in.</RegularInputGroupLabel>
              </FabricDimensionInputGroup>
          </PrependAppendInputGroupRomanShade>
        </FabricDimensionInputGroup>
        <FabricDimensionInputGroup>
          <Width170>
            <RomanShadeDesignHeaderSubtitle style={{marginRight: 'auto'}}>Control Type</RomanShadeDesignHeaderSubtitle>
          </Width170>
            <CalculatorRadioWrapperRomanShade style={{paddingLeft: '20px'}}>
                  <CalculatorRadio
                        type="radio"
                        name="draperyOrderDesign.romanShadeJson.headrail.controlType"
                        value={"cord"}
                        checked={!!(romanShadeJson.headrail.controlType && romanShadeJson.headrail.controlType === "cord")}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForString}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                   />
                    Cord
                </CalculatorRadioWrapperRomanShade>
                <CalculatorRadioWrapperRomanShade style={{paddingLeft: '20px'}}>
                  <CalculatorRadio
                        type="radio"
                        name="draperyOrderDesign.romanShadeJson.headrail.controlType"
                        value={"cordless"}
                        checked={!!(romanShadeJson.headrail.controlType && romanShadeJson.headrail.controlType === "cordless")}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForString}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                   />
                    Cordless
                </CalculatorRadioWrapperRomanShade>
                <CalculatorRadioWrapperRomanShade style={{paddingLeft: '20px'}}>
                  <CalculatorRadio
                        type="radio"
                        name="draperyOrderDesign.romanShadeJson.headrail.controlType"
                        value={"loop"}
                        checked={!!(romanShadeJson.headrail.controlType && romanShadeJson.headrail.controlType === "loop")}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForString}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                   />
                    Loop
                </CalculatorRadioWrapperRomanShade>
                <CalculatorRadioWrapperRomanShade style={{paddingLeft: '20px'}}>
                  <CalculatorRadio
                        type="radio"
                        name="draperyOrderDesign.romanShadeJson.headrail.controlType"
                        value={"motorized"}
                        checked={!!(romanShadeJson.headrail.controlType && romanShadeJson.headrail.controlType === "motorized")}
                        onClick={(e) => this.props.handleRemoveRomanShadeCategory(e)}
                        onChange={this.props.handleInputChangeForString}
                        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                        uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                   />
                    Motorized
                </CalculatorRadioWrapperRomanShade>
        </FabricDimensionInputGroup>
        <FabricDimensionInputGroup style={(highlightedSection === 'draperyOrderDesign.romanShadeJson.headrail.controlLocationRight' || highlightedSection === 'draperyOrderDesign.romanShadeJson.headrail.controlLocationLeft' ) ? highlightStyle : {}}>
          <Width170>
            <RomanShadeDesignHeaderSubtitle style={{marginRight: 'auto'}}>Control Location</RomanShadeDesignHeaderSubtitle>
          </Width170>

          <Width170>
            {this.renderPanelWidthSelect('Left')}
          </Width170>
          <Width170>
            {this.renderPanelWidthSelect('Right')}
          </Width170>
        </FabricDimensionInputGroup>
      </CalculatorFormField>
    );
  };

  private renderFifthQuestion = (): JSX.Element => {
    const { formErrorID, liningsList, highlightedSection } = this.props;

    const isLiningActive = liningsList.some((lining) => lining.active);
    const isNoneLinings = this.props.isNoneLinings;

    return (
      <CalculatorFormField
        id="5"
        uiInline
        uiNoMargin
        isError={highlightedSection === "isNoneLinings"}
      >
        <RomanShadeDesignHeader>
          <CalculatorFormQuestionNumber uiError={formErrorID === "5"}>
            5
          </CalculatorFormQuestionNumber>
          <DraperyHeader>
            LININGS
          </DraperyHeader>
        </RomanShadeDesignHeader>
        <LiningsWrap style={{ alignItems: 'center' }}>
          <RomanShadeDesignHeaderSubtitle style={{marginTop: '16px'}} >Shade</RomanShadeDesignHeaderSubtitle>

          <CheckboxInputWrap
            className="flex-row"
            style={{ marginTop: '16px' }}
          >
            <CalculatorFieldInputRomanShade>
              <CalculatorFormLabel>
                <CalculatorCheckbox
                  id="isNoneLinings"
                  name="isNoneLinings"
                  checked={isNoneLinings}
                  onChange={(e) => {
                    this.props.handleInputChangeForBoolean(e)
                    this.props.resetLinings()
                  }}
                  disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                  uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                />
                None
              </CalculatorFormLabel>
            </CalculatorFieldInputRomanShade>
            {liningsList.map((el, i) => {
              return (
                <CalculatorFieldInputRomanShade key={el.name}>
                  <CalculatorFormLabel>
                    <CalculatorCheckbox
                      id={"liningsList." + i + ".active"}
                      name={
                        "liningsList." + i + ".active"
                      }
                      checked={
                        el.active && isLiningActive
                      }
                      onChange={
                        this.props
                          .handleInputChangeForBoolean
                      }
                      disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                      uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    {el.name}
                  </CalculatorFormLabel>
                </CalculatorFieldInputRomanShade>
              );
            })}
          </CheckboxInputWrap>
        </LiningsWrap>
      </CalculatorFormField>
    );
  };

  private renderSeventhQuestion = (): JSX.Element => {
    const {
      formErrorID,
      embellishmentsList,
      isNoneEmbellishment,
      highlightedSection,
      // orderType,
  } = this.props;

  const isEmbellishmentActive = embellishmentsList.some(
      (embellishment) => embellishment.checked
  );

  // if roman shades
  const embellishmentsFiltered = embellishmentsList.map((item: any) => 
    ['bandings', 'cordings', 'flanges'].includes(item.stateName) ? undefined : item
  );  

  return (
      <CalculatorFormField
          id="7"
          uiInline
          uiNoMargin
          isError={highlightedSection === "isNoneEmbellishment"}
      >
        <RomanShadeDesignHeader>
          <CalculatorFormQuestionNumber uiError={formErrorID === "7"}>
            7
          </CalculatorFormQuestionNumber>
          <DraperyHeader>
            EMBELLISHMENTS
          </DraperyHeader>
        </RomanShadeDesignHeader>
          <HContainer
              style={{ padding: "30px 0px" }}
              className="flex-col lg:flex-row items-start lg:items-center"
          >
              <CalculatorFormLabel key={-1}>
                  <CalculatorCheckbox
                      id="isNoneEmbellishment"
                      name="isNoneEmbellishment"
                      checked={isNoneEmbellishment}
                      onChange={(e) => {
                          this.props.handleInputChangeForBoolean(e)
                          this.props.resetDraperyOrderEmbellishments()
                      }}
                      disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                      uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                  />
                  None
              </CalculatorFormLabel>
              {embellishmentsFiltered.map((el, i) => {
                if (el === undefined) return null; 
                return (
                  <CalculatorFormLabel key={i}>
                    <CalculatorCheckbox
                      id={`embellishmentsList.${i}.checked`}
                      name={`embellishmentsList.${i}.checked`}
                      checked={el.checked && isEmbellishmentActive}
                      onChange={this.props.handleInputChangeForBoolean}
                      disabled={this.props.workOrderEditMode ? false : this.props.disabled}
                      uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
                    />
                    {el.title}
                  </CalculatorFormLabel>
                );
              })}
          </HContainer>
              {embellishmentsFiltered.some((el) => el && el.checked === true) && (
              <AdditionalFormContainer>
                {embellishmentsFiltered.map((el, i) => {
                  if (el === undefined) return null; 
                  return this.renderEmbellishments(el, i); 
                })}
              </AdditionalFormContainer>
              )}
      </CalculatorFormField>
  );
};

  private renderEmbellishments = (
    embellishmentInfo: EmbellishmentCheckInfo,
    index: number
  ) => {
    const { list, name } = this.props.getEmbellishmentsByIndex(index);
    if (!embellishmentInfo.checked || !list.length) {
      return <></>;
    }
    return list.map((e, i) => this.renderEmbellishment(e, i, name, list));
  };

  private renderEmbellishment = (
    embellishment: DraperyOrderDesignEmbellishment,
    index: number,
    targetArray: string,
    list: DraperyOrderDesignEmbellishment[]
  ) => {
    return (
      <AdditionalFormRomanShade
        key={targetArray + index}
        targetArray={targetArray}
        targetArrayIndex={index}
        embellishment={embellishment}
        addEmbellishment={this.props.addEmbellishment}
        deleteEmbellishment={this.props.deleteEmbellishment}
        differentiate={list.length > 1 && index + 1}
        disabled={this.props.workOrderEditMode ? false : this.props.disabled}
        handleInputChangeForNumber={
          this.props.handleInputChangeForNumber
        }
        handleInputChangeForString={
          this.props.handleInputChangeForString
        }
        handleInputChangeForBoolean={
          this.props.handleInputChangeForBoolean
        }
        handleInputChangeForNumberPrice={
          this.props.handleInputChangeForNumberPrice
        }
        handleInputBlur={this.props.handleInputBlur}
        handleSelectListChange={this.props.handleSelectListChange}
        updateStateByName={this.props.updateStateByName}
        highlightedSection={this.props.highlightedSection}
        orderType={this.props.orderType}
        numberingTrims={this.props.numberingTrims}
        draperyOrderDesign={this.props.draperyOrderDesign}
      />
    );
  };

  private renderDrawingSection = (): JSX.Element => {
    let valanceForDrawing;

    const { frontValance, sides } = this.props.romanShadeJson.valanceAndReturns;
    switch (true) {
      case frontValance === 1 && (sides === 'returns' || sides === 'side-flaps'):
      valanceForDrawing = 'valance-returns-wire';
      break;
      case frontValance === 1 && sides !== 'returns' && sides !== 'side-flaps':
      valanceForDrawing = 'valance-no-returns-wire';
      break;
      case frontValance === 0 && sides === 'side-flaps':
        valanceForDrawing = 'no-valance-returns-wire';
      break;
      case frontValance === 0 && sides === 'returns':
        valanceForDrawing = 'no-valance-wrapped-returns'
      break
      default:
        valanceForDrawing = 'no-valance-no-returns-wire';
      }
            
    return (
      <CalculatorFormField id="drawing-section">
        <RomanShadeDesignHeader>
          <CalculatorFormQuestionNumber>
            6
          </CalculatorFormQuestionNumber>
          <DraperyHeader>
            DRAWING
          </DraperyHeader>
        </RomanShadeDesignHeader>
        <DrawingContainer className={this.props.orderType === 'custom-order' ? 'left' : ''}>
        <Drawing
            shapes={this.props.draperyOrderDesign.drawingShapeJson}
            category={this.props.draperyOrderDesign.category}
            panelType={this.props.draperyOrderDesign.panelType}
            panelJson={this.props.draperyOrderDesign.panelJson}
            rodWidth={this.props.draperyOrderDesign.rodWidth}
            finishedLengthOfPanels={
                this.props.draperyOrderDesign.finishedLengthOfPanels
            }
            disabled={this.props.workOrderEditMode ? false : this.props.disabled}
            onShapeChanged={this.props.onShapeChanged}
            ref={this.drawingRef}
            orderType="roman-shade"
            valanceForDrawing={valanceForDrawing}
            romanShadeJson={this.props.romanShadeJson}
        />
        </DrawingContainer>
      </CalculatorFormField>
    );
  };


// Draft, will be done in the future
//   private renderConstructionDiv = () => {
//     const { highlightedSection } = this.props;
//     return (
//         <div>
//             <CustomizeConstructionDiv
//                 id="customize-construction-div"
//                 // onClick={this.handleCustomizeConstruction}
//             >
//                 {this.props.isCustomizeConstructionOpen && (
//                     <>
//                         <FaAngleUp />
//                         &nbsp; Construction Details
//                     </>
//                 )}
//                 {!this.props.isCustomizeConstructionOpen && (
//                     <>
//                         <FaAngleDown />
//                         &nbsp; Construction Details
//                     </>
//                 )}
//             </CustomizeConstructionDiv>
//             {this.props.isCustomizeConstructionOpen && (
//                 <DrapperyOrderWorkroomSettings
//                     draperyOrderDesign={this.props.draperyOrderDesign}
//                     handleBuckramSelect={this.props.handleBuckramSelect}
//                     handleFullnessSelect={this.props.handleFullnessSelect}
//                     disabled={this.props.workOrderEditMode ? false : this.props.disabled}
//                     highlightedSection={highlightedSection}
//                     handleInputChangeForNumber={
//                         this.props.handleInputChangeForNumber
//                     }
//                     handleInputChangeForString={
//                         this.props.handleInputChangeForString
//                     }
//                     handleInputChangeForBoolean={
//                         this.props.handleInputChangeForBoolean
//                     }
//                     handleSelectListChange={
//                         this.props.handleSelectListChange
//                     }
//                     formErrorID={this.props.formErrorID}
//                     embellishmentsList={this.props.embellishmentsList}
//                     data={this.props.data}
//                     workroomSetting={this.props.workroomSetting}
//                     openConstructionDetailsEditModal={this.props.openConstructionDetailsEditModal}
//                 />
//             )}
//         </div>
//     );
// };

  private renderNotesSection = (): JSX.Element => {
    const { draperyOrderDesign } = this.props;
    return (
      <CalculatorFormField id="notes-section" uiInline uiNoMargin>
        <NotesContainerRomanShade>
          <TextArea
            style={{
              border: "1px solid #B3B3B3",
              width: "76%",
              height: "211px",
            }}
            maxLength={150}
            name="draperyOrderDesign.notes"
            onChange={this.props.handleInputChangeForString}
            value={draperyOrderDesign.notes}
            disabled={this.props.workOrderEditMode ? false : this.props.disabled}
            uiDisabled={this.props.workOrderEditMode ? false : this.props.disabled}
          />
          <NoteLettersRomanShade>{(draperyOrderDesign.notes || "").length}/150</NoteLettersRomanShade>
        </NotesContainerRomanShade>
      </CalculatorFormField>
    );
  };
}

export default RomanShadeDesignTab;
import { Checkbox, Input, InputProps, ValidationForm } from "@ramble/ramble-ui";
import { DispatchProp, connect } from "react-redux";
import React, {
    ChangeEvent,
    FC,
    FormEvent,
    PureComponent,
    ReactNode,
    forwardRef,
} from "react";
import {
    TooltipContainer,
    TooltipHeader,
    TooltipImgContainer,
    TooltipPopup,
    TooltipSvgContainer,
    TooltipText,
    TooltipWidePopup,
} from "../../styles";
import { DraperyFullnessTooltip } from "../../../calculator/styles";
import styled, { css, theme } from "../../../../theme";

import Bind from "lodash-decorators/bind";
import FormulaInput from "./formula-input";
import InchesInput from "./inches-input";
import OtherDetailsRadioGroup from "./other-details-radio-group";
import { PageContent } from "../../../content/page_content";
import RowTitle from "./row-title";
import SelectListE from "../../../UI/select-list";
import SingleInchInput from "./single-inch-input";
import TextCell from "./text-cell";
import TooltipBackOfDrapery from "../../../../assets/images/tooltip/back-of-drapery.png";
import TooltipHemSideView from "../../../../assets/images/tooltip/hem-side-view.png";
import TooltipOverlap from "../../../../assets/icons/tooltip/drapery-overlap.svg";
import TooltipReturnAndLeadEdge from "../../../../assets/icons/tooltip/drapery-return-and-lead-edge.svg";
import { allowOnlyPositiveNum } from "../../helpers";
import {
    getWorkroomSetting,
    searchWorkroomSetting,
    addWorkroomSetting,
    editWorkroomSetting,
} from "../../../../api/workroom-setting";

const unitList = ["select unit", "yard(s)", "%"];
const fullnessList = [
    { key: 0, label: "No Default" },
    { key: 1, label: "1x Fullness" },
    { key: 1.5, label: "1.5x Fullness" },
    { key: 2, label: "2x Fullness" },
    { key: 2.5, label: "2.5x Fullness" },
    { key: 3, label: "3x Fullness" },
];

const fullnessListRipplefoldList = [
    { key: 0, label: "No Default" },
    { key: 0.6, label: "60%" },
    { key: 0.8, label: "80%" },
    { key: 1, label: "100%" },
    { key: 1.2, label: "120%" },
];
const defaultItemList = [
    { type: 1, name: "Drapery | Full Length" },
    { type: 2, name: "Drapery | Cafe Curtain" },
    { type: 3, name: "Drapery | Ripplefold" },
];

const SettingsCheckbox = styled(Checkbox)`
    align-items: start;

    > span.label {
        border: 0 !important;
        line-height: 35px;
        color: ${theme.colors.grayRGBA};
        font-weight: 400;
    }

    > svg {
        margin-top: 7px;
    }
    ${(props) =>
        props.className === "excess-box" &&
        css`
            width: 3%;
            align-items: center;

            > svg {
                margin-top: 0 !important;
            }
        `}
`;

const WorkroomForm = styled(ValidationForm)`
    max-width: 1280px;
    padding-top: 0;
    padding-inline: 0;

`;

const InputLabel = styled.div`
    margin-left: 15px;
    margin-right: 15px;
    display: inline-block;
`;
const LabeledInputE = forwardRef<
    HTMLInputElement,
    InputProps & { label?: string }
>(({ label, ...props }, ref) => (
    <>
        <Input {...props} ref={ref} />
        {label && <InputLabel>{label}</InputLabel>}
    </>
));

const SettingsLabeledInput = styled(LabeledInputE)`
    width: 80px;
    height: 36px;
    border: solid #AAAAAA 1px;
    padding: 10px 8px !important;
    line-height: normal;
    border-radius: 8px;
`;

const Welcome = styled.div`
    max-width: 1280px;
    margin-inline: auto;
    /* padding: 10px 30px 10px 45px; */
`;

const SelectList = styled(SelectListE)`
    display: inline-block;
    padding-top: 0.1em !important;
    padding-bottom: 0.1em !important;

    label {
        line-height: normal;
        
    }
    input {
        height: 36px;
    }
`;

interface FieldProps {
    /**
     * Field label
     */
    label?: string;
    colored?: boolean;
    /**
     * True to render as subfield with margin
     */
    subField?: boolean;
    children: ReactNode;
    className?: string;
}

const FieldE: FC<FieldProps> = (props) => (
    <div className={props.className}>
        {props.label && <div className="field-label">{props.label}</div>}
        {props.children}
    </div>
);

/**
 * Single field in options
 */
const Field = styled(FieldE)`
    display: flex;
    // margin: 0 0 0 20px;
    align-items: baseline;
    color: ${theme.colors.grayRGBA};
    font-weight: 400;

    > .field-label {
        // margin-left: 40px;
        margin-right: 5px;
        display: inline-block;
        min-width: 100px;
        ${(props) =>
            props.colored &&
            css`
                color: #306f82;
            `}
    }

    ${(props) =>
        props.label === "" &&
        css`
            padding-left: 145px;
        `}

    ${(props) =>
        props.subField &&
        css`
            display: inline-block;
            margin: 5px 0 0 150px;
        `};
`;

const DraperyFullnessSelectList = styled(SelectListE)`
    width: 150px;
    line-height: 16px;
    display: inline-block;
`;

const ExcessBox = styled.span`
    line-height: 35px;
    font-size: 16px;
    padding-left: 0.2rem;
`;

interface DefaultSettingType {
    key: number;
    label: string;
}

// tslint:disable-next-line:no-empty-interface
// export interface ConstructionSettingsOwnProps extends RouteComponentProps {}

export interface ConstructionSettingsProps {
    accountId: number;
    setIsChanged?: any;
}

interface State {
    /**
     * does the form validate
     */
    formError: boolean;
    /**
     * What is the form error
     */
    formErrorMessage: string;

    defaultSettingList: DefaultSettingType[];
    currentSettingID: number;
    currentSettingName: string;

    isYardageAddOneExtra: boolean;
    isYardageAddExcess: boolean;
    addExcessValue?: number;
    addExcessUnit?: string;

    /*draperyPinInstaller?: string;*/
    fabricPurchaser?: string;
    embellishmentsPurchaser?: string;
    liningsPurchaser?: string;
    //hardwarePurchaser?: string;

    // Embellishments
    embellishmentCordingVertical?: number;
    embellishmentCordingHorizontal?: number;
    embellishmentFlangeVertical?: number;
    embellishmentFlangeHorizontal?: number;
    embellishmentTrimVertical?: number;
    embellishmentTrimHorizontal?: number;
    embellishmentBandingVertical?: number;
    embellishmentBandingHorizontal?: number;

    embellishmentCordingVerticalCafe?: number;
    embellishmentCordingHorizontalCafe?: number;
    embellishmentFlangeVerticalCafe?: number;
    embellishmentFlangeHorizontalCafe?: number;
    embellishmentTrimVerticalCafe?: number;
    embellishmentTrimHorizontalCafe?: number;
    embellishmentBandingVerticalCafe?: number;
    embellishmentBandingHorizontalCafe?: number;

    embellishmentCordingVerticalRipplefold?: number;
    embellishmentCordingHorizontalRipplefold?: number;
    embellishmentFlangeVerticalRipplefold?: number;
    embellishmentFlangeHorizontalRipplefold?: number;
    embellishmentTrimVerticalRipplefold?: number;
    embellishmentTrimHorizontalRipplefold?: number;
    embellishmentBandingVerticalRipplefold?: number;
    embellishmentBandingHorizontalRipplefold?: number;

    // Hem
    topHemFinishedSize?: number;
    topHemFabricAllowance?: number;
    bottomHemFinishedSize?: number;
    bottomHemFabricAllowance?: number;
    sideHemFinishedSize?: number;
    sideHemFabricAllowance?: number;
    buckram?: string;

    topHemFinishedSizeCafe?: number;
    topHemFabricAllowanceCafe?: number;
    bottomHemFinishedSizeCafe?: number;
    bottomHemFabricAllowanceCafe?: number;
    sideHemFinishedSizeCafe?: number;
    sideHemFabricAllowanceCafe?: number;
    buckramCafe?: string;

    topHemFinishedSizeRipplefold?: number;
    topHemFabricAllowanceRipplefold?: number;
    bottomHemFinishedSizeRipplefold?: number;
    bottomHemFabricAllowanceRipplefold?: number;
    sideHemFinishedSizeRipplefold?: number;
    sideHemFabricAllowanceRipplefold?: number;
    buckramRipplefold?: string;

    // Overlap
    returnOverlapLeadSize?: number;
    returnOverlapReturnSize?: number;
    returnOverlapOverlapSize?: number;
    cutLengthLining?: number;
    cutLengthInterlining?: number;
    draperyFullnessValue?: number;

    mainFabric?: number;
    mainFabricCafe?: number;
    mainFabricRipplefold?: number;

    returnOverlapLeadSizeCafe?: number;
    returnOverlapReturnSizeCafe?: number;
    returnOverlapOverlapSizeCafe?: number;
    cutLengthLiningCafe?: number;
    cutLengthInterliningCafe?: number;
    draperyFullnessValueCafe?: number;

    returnOverlapLeadSizeRipplefold?: number;
    returnOverlapReturnSizeRipplefold?: number;
    returnOverlapOverlapSizeRipplefold?: number;
    cutLengthLiningRipplefold?: number;
    cutLengthInterliningRipplefold?: number;
    draperyFullnessValueRipplefold?: number;

    isChanged: boolean;
    previousKey: number | null;
}

export class ConstructionSettings extends PureComponent<
    ConstructionSettingsProps & DispatchProp,
    State
> {
    public constructor(props: ConstructionSettingsProps & DispatchProp) {
        super(props);

        this.state = {
            defaultSettingList: [],
            formError: false,
            formErrorMessage: "",
            isYardageAddOneExtra: true,
            isYardageAddExcess: false,
            addExcessUnit: "select unit",

            currentSettingID: 0,
            currentSettingName: "Please Select",
            isChanged: false,
            previousKey: null,
        };
        this.getExistingConstructionSettings().catch(() => {});
    }

    public componentDidMount() {
        document.documentElement.scrollIntoView();
    }

    handleInputFocus = () => {
        this.setState({ previousKey: null });
    };

    public render(): JSX.Element {
        const selectedDraperyFullness = fullnessList.find(
            (fullness) => fullness.key === this.state.draperyFullnessValue
        );
        const selectedDraperyFullnessCafe = fullnessList.find(
            (fullness) => fullness.key === this.state.draperyFullnessValueCafe
        );
        const selectedDraperyFullnessRipplefold =
            fullnessListRipplefoldList.find(
                (fullness) =>
                    fullness.key === this.state.draperyFullnessValueRipplefold
            );

        const selectedDraperyFullnessLabel = selectedDraperyFullness
            ? selectedDraperyFullness.label
            : undefined;

        const selectedDraperyFullnessLabelCafe = selectedDraperyFullnessCafe
            ? selectedDraperyFullnessCafe.label
            : undefined;

        const selectedDraperyFullnessLabelRipplefold =
            selectedDraperyFullnessRipplefold
                ? selectedDraperyFullnessRipplefold.label
                : undefined;

            const buckramTypes = [
                { key: 'Ask for each order', label: "Ask for each order", value: "Ask for each order" },
                { key: 'Yes, include in top hem', label: "Yes, include in top hem", value: "Yes, include in top hem" },
                { key: 'No Buckram', label: "No Buckram", value: "No Buckram" },
            ];

        return (
            <PageContent style={{fontSize: '16px', marginTop: '4px', marginInline: 'auto'}}>
                <Welcome className="mb-6">
                    <div className="w-full flex items-center">
                        <span
                            style={{
                                color: "#3B97B1",
                                fontSize: "20px",
                                paddingBottom: "3px",
                                fontWeight: "600",
                                lineHeight: "20px",
                                marginBottom: "12px"
                            }}
                        >
                            OPTIONAL DEFAULTS
                            <span
                                style={{
                                    color: "#B0B0B0",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                }}
                            >
                                {" "}
                                (Recommended)
                            </span>
                        </span>
                    </div>
                    Add any default settings you would like to populate on
                    drapery orders automatically below. You will be asked to
                    complete any settings left blank on every order. These can
                    be edited on each order as needed.
                </Welcome>

                {/* FORM */}
                <WorkroomForm
                    uiOnSubmit={this.submit}
                    uiDimmerBlurring={false}
                    autoComplete="off"
                >
                    {/* HEM SECTION */}
                    <div className="border border-[#E2E2E2]">
                        <div className="border-b border-[#E2E2E2]  bg-[#ecf6f9] text-[#222222] h-[52px] w-full py-[16px] px-[10px] flex items-center font-medium">
                            <p className="m-0">HEMS</p>
                            <TooltipWidePopup
                                text={
                                    <TooltipContainer>
                                        <TooltipHeader>HEM SIZE</TooltipHeader>
                                        <TooltipText>
                                            The finished size of a hem.
                                        </TooltipText>
                                        <TooltipImgContainer>
                                            <img src={TooltipBackOfDrapery} />
                                        </TooltipImgContainer>
                                        <TooltipHeader>
                                            HEM ALLOWANCE
                                        </TooltipHeader>
                                        <TooltipText>
                                            The total amount of fabric needed to
                                            create a hem including the folded
                                            fabric.
                                            <br />
                                            <br />
                                            Hem Allowance = Hem Size + Fold
                                            <br />
                                            <br />
                                            For example, if the top hem size is
                                            4” and I use a double turned hem, I
                                            need an 8” allowance of fabric to
                                            create the folded hem. 4” Hem size +
                                            4” Fold = 8” hem allowance.
                                        </TooltipText>
                                        <TooltipImgContainer>
                                            <img src={TooltipHemSideView} />
                                        </TooltipImgContainer>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal"
                                closeOnClick
                            />
                        </div>
                        <div className="grid grid-cols-4 divide-x divide-[#E2E2E2]">
                            <div className="w-full col-span-1">
                                <div>
                                    <div className="text-center pt-1 text-lg h-14 w-full mb-0"></div>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Top/Header
                                    </p>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Each Side
                                    </p>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Bottom
                                    </p>
                                    <p className=" mb-0 p-2 h-14 flex items-center">
                                        Buckram
                                        <TooltipPopup
                                            text={
                                                <TooltipContainer>
                                                    <TooltipText>
                                                        A stiffened piece of
                                                        open weave fabric that
                                                        comes in varying widths
                                                        that helps provide
                                                        support and structure to
                                                        a fabricated piece, such
                                                        as the top hem of a
                                                        drapery. Buckram is also
                                                        known by other names,
                                                        such as crinoline and
                                                        interface.
                                                    </TooltipText>
                                                </TooltipContainer>
                                            }
                                            position="bottom left"
                                            className="normal"
                                            closeOnClick
                                        />
                                    </p>
                                </div>
                            </div>
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                    <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Ripplefold
                                        </p>
                                        <div className="grid grid-cols-2 h-full">
                                            <p className="text-center text-[14px] leading-5 font-normal mb-0">
                                                Finished Size
                                            </p>
                                            <p className="text-center text-[14px] leading-5 font-normal mb-0 ">
                                                Cut Allowance
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Inches input template */}
                                <InchesInput
                                    finishedCutId="topHemFinishedSizeRipplefold"
                                    CutAllowanceId="topHemFabricAllowanceRipplefold"
                                    finishedCutValue={
                                        this.state
                                            .topHemFinishedSizeRipplefold !==
                                        undefined
                                            ? this.state
                                                  .topHemFinishedSizeRipplefold
                                            : ""
                                    }
                                    cutAllowanceValue={
                                        this.state
                                            .topHemFabricAllowanceRipplefold !==
                                        undefined
                                            ? this.state
                                                  .topHemFabricAllowanceRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    finishedSizePlaceholder="1"
                                    cutAllowancePlaceholder="2"
                                />
                                <InchesInput
                                    finishedCutId="sideHemFinishedSizeRipplefold"
                                    CutAllowanceId="sideHemFabricAllowanceRipplefold"
                                    finishedCutValue={
                                        this.state
                                            .sideHemFinishedSizeRipplefold !==
                                        undefined
                                            ? this.state
                                                  .sideHemFinishedSizeRipplefold
                                            : ""
                                    }
                                    cutAllowanceValue={
                                        this.state
                                            .sideHemFabricAllowanceRipplefold !==
                                        undefined
                                            ? this.state
                                                  .sideHemFabricAllowanceRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    finishedSizePlaceholder="1"
                                    cutAllowancePlaceholder="2"
                                />
                                <InchesInput
                                    finishedCutId="bottomHemFinishedSizeRipplefold"
                                    CutAllowanceId="bottomHemFabricAllowanceRipplefold"
                                    finishedCutValue={
                                        this.state
                                            .bottomHemFinishedSizeRipplefold !==
                                        undefined
                                            ? this.state
                                                  .bottomHemFinishedSizeRipplefold
                                            : ""
                                    }
                                    cutAllowanceValue={
                                        this.state
                                            .bottomHemFabricAllowanceRipplefold !==
                                        undefined
                                            ? this.state
                                                  .bottomHemFabricAllowanceRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    finishedSizePlaceholder="4"
                                    cutAllowancePlaceholder="8"
                                />
                                <div className="h-14 p-2 flex items-center justify-center bg-white relative">
                                    <SelectList
                                        name="buckram"
                                        flexDirection="column"
                                        required={false}
                                        noAddButton
                                        valueSelect={this.state.buckramRipplefold}
                                        handleClickSelect={(value) =>
                                            this.handleBuckramRipplefoldSelect({ target: { value } } as ChangeEvent<HTMLSelectElement>)
                                        }
                                        selectList={buckramTypes}
                                        noClearBox
                                        pricingRedesign
                                        uiDropArrow
                                        className="max-w-[240px]"
                                        style={{height: '31px', width: '100%'}}
                                    />
                                </div>
                            </div>
                            {/* FULL LENGTH HEM */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                    <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Rod & Rings: Full Length
                                        </p>
                                        <div className="grid grid-cols-2 h-full">
                                            <p className="text-center text-[14px] leading-5 font-normal mb-0">
                                                Finished Size
                                            </p>
                                            <p className="text-center text-[14px] leading-5 font-normal mb-0 ">
                                                Cut Allowance
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Inches input template */}
                                <InchesInput
                                    finishedCutId="topHemFinishedSize"
                                    CutAllowanceId="topHemFabricAllowance"
                                    finishedCutValue={
                                        this.state.topHemFinishedSize !==
                                        undefined
                                            ? this.state.topHemFinishedSize
                                            : ""
                                    }
                                    cutAllowanceValue={
                                        this.state.topHemFabricAllowance !==
                                        undefined
                                            ? this.state.topHemFabricAllowance
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    finishedSizePlaceholder="4"
                                    cutAllowancePlaceholder="8"
                                />
                                <InchesInput
                                    finishedCutId="sideHemFinishedSize"
                                    CutAllowanceId="sideHemFabricAllowance"
                                    finishedCutValue={
                                        this.state.sideHemFinishedSize !==
                                        undefined
                                            ? this.state.sideHemFinishedSize
                                            : ""
                                    }
                                    cutAllowanceValue={
                                        this.state.sideHemFabricAllowance !==
                                        undefined
                                            ? this.state.sideHemFabricAllowance
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    finishedSizePlaceholder="1.5"
                                    cutAllowancePlaceholder="3"
                                />
                                <InchesInput
                                    finishedCutId="bottomHemFinishedSize"
                                    CutAllowanceId="bottomHemFabricAllowance"
                                    finishedCutValue={
                                        this.state.bottomHemFinishedSize !==
                                        undefined
                                            ? this.state.bottomHemFinishedSize
                                            : ""
                                    }
                                    cutAllowanceValue={
                                        this.state.bottomHemFabricAllowance !==
                                        undefined
                                            ? this.state
                                                  .bottomHemFabricAllowance
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    finishedSizePlaceholder="4"
                                    cutAllowancePlaceholder="8"
                                />
                                <div className="h-14 p-2 flex items-center justify-center bg-white relative">
                                    <SelectList
                                        name="buckram"
                                        flexDirection="column"
                                        required={false}
                                        noAddButton
                                        valueSelect={this.state.buckram}
                                        handleClickSelect={(value) =>
                                            this.handleBuckramSelect({ target: { value } } as ChangeEvent<HTMLSelectElement>)
                                        }
                                        selectList={buckramTypes}
                                        noClearBox
                                        pricingRedesign
                                        uiDropArrow
                                        className="max-w-[240px]"
                                        style={{height: '31px', width: '100%'}}
                                    />
                                </div>
                            </div>

                            {/* CAFE HEM */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                    <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Rod & Rings: Cafe
                                        </p>
                                        <div className="grid grid-cols-2 h-full">
                                            <p className="text-center text-[14px] leading-5 font-normal mb-0">
                                                Finished Size
                                            </p>
                                            <p className="text-center text-[14px] leading-5 font-normal mb-0 ">
                                                Cut Allowance
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* Inches input template */}
                                <InchesInput
                                    finishedCutId="topHemFinishedSizeCafe"
                                    CutAllowanceId="topHemFabricAllowanceCafe"
                                    finishedCutValue={
                                        this.state.topHemFinishedSizeCafe !==
                                        undefined
                                            ? this.state.topHemFinishedSizeCafe
                                            : ""
                                    }
                                    cutAllowanceValue={
                                        this.state.topHemFabricAllowanceCafe !==
                                        undefined
                                            ? this.state
                                                  .topHemFabricAllowanceCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    finishedSizePlaceholder="3"
                                    cutAllowancePlaceholder="6"
                                />
                                <InchesInput
                                    finishedCutId="sideHemFinishedSizeCafe"
                                    CutAllowanceId="sideHemFabricAllowanceCafe"
                                    finishedCutValue={
                                        this.state.sideHemFinishedSizeCafe !==
                                        undefined
                                            ? this.state.sideHemFinishedSizeCafe
                                            : ""
                                    }
                                    cutAllowanceValue={
                                        this.state
                                            .sideHemFabricAllowanceCafe !==
                                        undefined
                                            ? this.state
                                                  .sideHemFabricAllowanceCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    finishedSizePlaceholder="1.5"
                                    cutAllowancePlaceholder="3"
                                />
                                <InchesInput
                                    finishedCutId="bottomHemFinishedSizeCafe"
                                    CutAllowanceId="bottomHemFabricAllowanceCafe"
                                    finishedCutValue={
                                        this.state.bottomHemFinishedSizeCafe !==
                                        undefined
                                            ? this.state
                                                  .bottomHemFinishedSizeCafe
                                            : ""
                                    }
                                    cutAllowanceValue={
                                        this.state
                                            .bottomHemFabricAllowanceCafe !==
                                        undefined
                                            ? this.state
                                                  .bottomHemFabricAllowanceCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    finishedSizePlaceholder="3"
                                    cutAllowancePlaceholder="6"
                                />
                                <div className="h-14 p-2 flex items-center justify-center bg-white relative">
                                    <SelectList
                                        name="buckramCafe"
                                        flexDirection="column"
                                        required={false}
                                        noAddButton
                                        valueSelect={this.state.buckramCafe}
                                        handleClickSelect={(value) =>
                                            this.handleBuckramCafeSelect({ target: { value } } as ChangeEvent<HTMLSelectElement>)
                                        }
                                        selectList={buckramTypes}
                                        noClearBox
                                        pricingRedesign
                                        uiDropArrow
                                        className="max-w-[240px]"
                                        style={{height: '31px', width: '100%'}}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>                    

                    {/* CUT LENGTHS SECTION */}
                    <div className="mt-6 border border-[#E2E2E2] border-b-0">
                    <div className="bg-[#ecf6f9] border-b border-[#E2E2E2] text-[#222222] h-[52px] w-full py-[16px] px-[10px] flex items-center">
                        <span className="font-medium">CUT LENGTHS</span>
                            <TooltipWidePopup
                                text={
                                    <TooltipContainer>
                                        <TooltipText>
                                            Vertical Cut Length = Finished
                                            Length (FL) + Cut Allowance
                                        </TooltipText>
                                        <TooltipText>
                                            Horizontal Cut Length = Finished
                                            Width (FW) + Cut Allowance
                                        </TooltipText>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal wide"
                                closeOnClick
                            />
                        </div>
                        <div className="grid grid-cols-4 divide-x divide-[#E2E2E2]">
                            <div className="w-full col-span-1">
                                <div>
                                    <div className="text-center pt-1 text-lg h-14 w-full mb-0"></div>
                                    <RowTitle
                                        title={"Main Fabric"}
                                        titleName="Main Fabric"
                                        text="The cut length of the main fabric
                                            for a drapery panel is the finished
                                            length plus the allowances for the
                                            top and bottom hems."
                                    />
                                    <RowTitle title={"Lining"} />
                                    <RowTitle title={"Interlining"} />
                                    <p className="mb-0 p-2 flex items-center text-lg text-twTableHeaderText" style={{height: '52.5px'}}>
                                        <span className="font-medium">Embellishments</span>
                                        <TooltipPopup
                                            text={
                                                <TooltipContainer>
                                                    <TooltipText>
                                                        <b>BANDING:</b>
                                                        &nbsp;Fabric (usually a
                                                        contrast fabric) seamed
                                                        to the main fabric
                                                        around the border or
                                                        inset within the
                                                        drapery.
                                                        <br />
                                                        <br />
                                                        <b>CORDING:</b>
                                                        &nbsp;Rope that is sewn
                                                        into a seam or edge.
                                                        This can be a pre-made
                                                        decorative cording or
                                                        welt cording
                                                        (non-decorative cording
                                                        covered with fabric).
                                                        <br />
                                                        <br />
                                                        <b>FLANGE:</b>&nbsp;A
                                                        strip of fabric that is
                                                        folded in half and sewn
                                                        into the seam or edge of
                                                        the drapery.
                                                        <br />
                                                        <br />
                                                        <b>TRIM:</b>&nbsp;Strips
                                                        of fabric or pre-made
                                                        trim applied on top of
                                                        the main fabric.
                                                        <br />
                                                        <br />
                                                    </TooltipText>
                                                </TooltipContainer>
                                            }
                                            position="bottom left"
                                            className="normal"
                                            closeOnClick
                                        />
                                    </p>
                                    <RowTitle
                                        title={"Banding (Vertical)"}
                                        titleName="Banding (Vertical)"
                                        text="QUIPA matches the main fabric cut length for this calculation."
                                    />
                                    <RowTitle
                                        title={"Banding (Horizontal)"}
                                        titleName="Banding (Horizontal)"
                                        text="QUIPA matches main fabric side hem allowances for this calculation."
                                    />
                                    <RowTitle title={"Cording (Vertical)"} />
                                    <RowTitle
                                        title={"Cording (Horizontal)"}
                                        titleName="Cording (Horizontal)"
                                        text="QUIPA matches main fabric side hem allowances for this calculation."
                                    />
                                    <RowTitle title={"Flange (Vertical)"} />
                                    <RowTitle
                                        title={"Flange (Horizontal)"}
                                        titleName="Flange (Horizontal)"
                                        text="QUIPA matches main fabric side hem allowances for this calculation."
                                    />
                                    <RowTitle title={"Trim (Vertical)"} />
                                    <RowTitle title={"Trim (Horizontal)"} />
                                </div>
                            </div>

                            {/* RIPPLEFOLD */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                    <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Ripplefold
                                        </p>
                                    </div>
                                </div>

                                <div className="relative ">
                                    <TextCell
                                        id="mainFabricRipplefold"
                                        text={`FL + ${
                                            (this.state
                                                .topHemFabricAllowanceRipplefold ||
                                                0) +
                                            (this.state
                                                .bottomHemFabricAllowanceRipplefold ||
                                                0)
                                        } in.`}
                                    />
                                </div>
                                <FormulaInput
                                    id="cutLengthLiningRipplefold"
                                    value={
                                        this.state.cutLengthLiningRipplefold !==
                                        undefined
                                            ? this.state
                                                  .cutLengthLiningRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="9"
                                />
                                <FormulaInput
                                    id="cutLengthInterliningRipplefold"
                                    value={
                                        this.state
                                            .cutLengthInterliningRipplefold !==
                                        undefined
                                            ? this.state
                                                  .cutLengthInterliningRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="-1"
                                />

                                {/* Embelishments */}
                                <div className="h-[52,5px]" style={{ height: '52.5px' }}>
                                    <div></div>
                                </div>
                                <TextCell
                                    id="embellishmentBandingVerticalRipplefold"
                                    text={`FL + ${
                                        (this.state
                                            .topHemFabricAllowanceRipplefold ||
                                            0) +
                                        (this.state
                                            .bottomHemFabricAllowanceRipplefold ||
                                            0)
                                    } in.`}
                                />
                                <TextCell
                                    id="embellishmentBandingVerticalRipplefold"
                                    text={`FW + ${
                                        (this.state
                                            .sideHemFabricAllowanceRipplefold &&
                                            this.state
                                                .sideHemFabricAllowanceRipplefold *
                                                2) ||
                                        ""
                                    } in.`}
                                />
                                <FormulaInput
                                    id="embellishmentCordingVerticalRipplefold"
                                    value={
                                        this.state
                                            .embellishmentCordingVerticalRipplefold !==
                                        undefined
                                            ? this.state
                                                  .embellishmentCordingVerticalRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="2"
                                />
                                <div className="h-14 flex items-center mb-0 border-b border-[#E2E2E2] text-center justify-center">
                                    <p className=" text-[#222222] font-normal leading">
                                        <span className="text-sm">FW +</span>{" "}
                                        {(this.state
                                            .sideHemFabricAllowanceRipplefold &&
                                            this.state
                                                .sideHemFabricAllowanceRipplefold *
                                                2) ||
                                            ""}{" "}
                                        in.
                                    </p>
                                </div>
                                <FormulaInput
                                    id="embellishmentFlangeVerticalRipplefold"
                                    value={
                                        this.state
                                            .embellishmentFlangeVerticalRipplefold !==
                                        undefined
                                            ? this.state
                                                  .embellishmentFlangeVerticalRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="2"
                                />
                                <div className="h-14 flex items-center mb-0 border-b border-[#E2E2E2] text-center justify-center">
                                    <p className="mb-0 p-2">
                                        FW +{" "}
                                        {(this.state
                                            .sideHemFabricAllowanceRipplefold &&
                                            this.state
                                                .sideHemFabricAllowanceRipplefold *
                                                2) ||
                                            ""}{" "}
                                        in.
                                    </p>
                                </div>

                                <FormulaInput
                                    id="embellishmentTrimVerticalRipplefold"
                                    value={
                                        this.state
                                            .embellishmentTrimVerticalRipplefold !==
                                        undefined
                                            ? this.state
                                                  .embellishmentTrimVerticalRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="6"
                                />
                                <FormulaInput
                                    id="embellishmentTrimHorizontalRipplefold"
                                    value={
                                        this.state
                                            .embellishmentTrimHorizontalRipplefold !==
                                        undefined
                                            ? this.state
                                                  .embellishmentTrimHorizontalRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="3"
                                />
                            </div>
                            {/* FULL LENGTH */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                    <p className="text-center pt-1 mb-0 text-[#3B97B1] font-medium leading-5">
                                            Rod & Rings: Full Length
                                        </p>
                                    </div>
                                </div>

                                <div className="relative ">
                                    <TextCell
                                        id="mainFabric"
                                        text={`FL + ${
                                            (this.state.topHemFabricAllowance ||
                                                0) +
                                            (this.state
                                                .bottomHemFabricAllowance || 0)
                                        } in.`}
                                    />
                                </div>
                                <FormulaInput
                                    id="cutLengthLining"
                                    value={
                                        this.state.cutLengthLining !== undefined
                                            ? this.state.cutLengthLining
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="15"
                                />
                                <FormulaInput
                                    id="cutLengthInterlining"
                                    value={
                                        this.state.cutLengthInterlining !==
                                        undefined
                                            ? this.state.cutLengthInterlining
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="-1"
                                />

                                {/* Embelishments */}
                                <div style={{height: '52.5px'}}>
                                    <div></div>
                                </div>
                                <TextCell
                                    id="embellishmentBandingVertical"
                                    text={`FL + ${
                                        (this.state.topHemFabricAllowance ||
                                            0) +
                                        (this.state.bottomHemFabricAllowance ||
                                            0)
                                    } in.`}
                                />
                                <TextCell
                                    id="embellishmentBandingVertical"
                                    text={`FW + ${
                                        (this.state.sideHemFabricAllowance &&
                                            this.state.sideHemFabricAllowance *
                                                2) ||
                                        ""
                                    } in.`}
                                />
                                <FormulaInput
                                    id="embellishmentCordingVertical"
                                    value={
                                        this.state
                                            .embellishmentCordingVertical !==
                                        undefined
                                            ? this.state
                                                  .embellishmentCordingVertical
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="2"
                                />
                                <div className="h-14 flex items-center mb-0 border-b border-[#E2E2E2] text-center justify-center">
                                    <p className=" text-[#222222] font-normal leading">
                                        <span className="text-sm">FW +</span>{" "}
                                        {(this.state.sideHemFabricAllowance &&
                                            this.state.sideHemFabricAllowance *
                                                2) ||
                                            ""}{" "}
                                        in.
                                    </p>
                                </div>
                                <FormulaInput
                                    id="embellishmentFlangeVertical"
                                    value={
                                        this.state
                                            .embellishmentFlangeVertical !==
                                        undefined
                                            ? this.state
                                                  .embellishmentFlangeVertical
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="2"
                                />
                                <div className="h-14 flex items-center mb-0 border-b border-[#E2E2E2] text-center justify-center">
                                    <p className="mb-0 p-2">
                                        FW +{" "}
                                        {(this.state.sideHemFabricAllowance &&
                                            this.state.sideHemFabricAllowance *
                                                2) ||
                                            ""}{" "}
                                        in.
                                    </p>
                                </div>

                                <FormulaInput
                                    id="embellishmentTrimVertical"
                                    value={
                                        this.state.embellishmentTrimVertical !==
                                        undefined
                                            ? this.state
                                                  .embellishmentTrimVertical
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="10"
                                />
                                <FormulaInput
                                    id="embellishmentTrimHorizontal"
                                    value={
                                        this.state
                                            .embellishmentTrimHorizontal !==
                                        undefined
                                            ? this.state
                                                  .embellishmentTrimHorizontal
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="4"
                                />
                            </div>

                            {/* CAFE */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                    <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Rod & Rings: Cafe
                                        </p>
                                    </div>
                                </div>
                                <div className="relative">
                                    <TextCell
                                        id="mainFabricCafe"
                                        text={`FL + ${
                                            (this.state
                                                .topHemFabricAllowanceCafe ||
                                                0) +
                                            (this.state
                                                .bottomHemFabricAllowanceCafe ||
                                                0)
                                        } in.`}
                                    />
                                    {/* <div className="absolute left-0 top-0 mt-4">
                                        <TooltipPopup
                                            text={
                                                <>
                                                    <TooltipContainer>
                                                        <TooltipText>
                                                            The cut length of
                                                            the main fabric for
                                                            a drapery panel is
                                                            the finished length
                                                            plus the allowances
                                                            for the top and
                                                            bottom hems.”
                                                        </TooltipText>
                                                    </TooltipContainer>
                                                </>
                                            }
                                            position="top right"
                                            className="floatRight"
                                            size="25px"
                                        />
                                    </div> */}
                                </div>
                                <FormulaInput
                                    id="cutLengthLiningCafe"
                                    value={
                                        this.state.cutLengthLiningCafe !==
                                        undefined
                                            ? this.state.cutLengthLiningCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="7"
                                />
                                <FormulaInput
                                    id="cutLengthInterliningCafe"
                                    value={
                                        this.state.cutLengthInterliningCafe !==
                                        undefined
                                            ? this.state
                                                  .cutLengthInterliningCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="0"
                                />

                                {/* Embelishments */}
                                <div style={{height: '52.5px'}}>
                                    <div></div>
                                </div>
                                <TextCell
                                    id="embellishmentBandingVertical"
                                    text={`FL + ${
                                        (this.state.topHemFabricAllowanceCafe ||
                                            0) +
                                        (this.state
                                            .bottomHemFabricAllowanceCafe || 0)
                                    } in.`}
                                />
                                <TextCell
                                    id="embellishmentBandingVertical"
                                    text={`FW + ${
                                        (this.state
                                            .sideHemFabricAllowanceCafe &&
                                            this.state
                                                .sideHemFabricAllowanceCafe *
                                                2) ||
                                        ""
                                    } in.`}
                                />
                                <FormulaInput
                                    id="embellishmentCordingVerticalCafe"
                                    value={
                                        this.state
                                            .embellishmentCordingVerticalCafe !==
                                        undefined
                                            ? this.state
                                                  .embellishmentCordingVerticalCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="2"
                                />
                                <div className="h-14 flex items-center mb-0 border-b border-[#E2E2E2] text-center justify-center">
                                    <p className="mb-0 p-2">
                                        FW +{" "}
                                        {(this.state
                                            .sideHemFabricAllowanceCafe &&
                                            this.state
                                                .sideHemFabricAllowanceCafe *
                                                2) ||
                                            ""}{" "}
                                        in.
                                    </p>
                                </div>
                                <FormulaInput
                                    id="embellishmentFlangeVerticalCafe"
                                    value={
                                        this.state
                                            .embellishmentFlangeVerticalCafe !==
                                        undefined
                                            ? this.state
                                                  .embellishmentFlangeVerticalCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="2"
                                />
                                <div className="h-14 flex items-center mb-0 border-b border-[#E2E2E2] text-center justify-center">
                                    <p className="mb-0 p-2">
                                        FW +{" "}
                                        {(this.state
                                            .sideHemFabricAllowanceCafe &&
                                            this.state
                                                .sideHemFabricAllowanceCafe *
                                                2) ||
                                            ""}{" "}
                                        in.
                                    </p>
                                </div>

                                <FormulaInput
                                    id="embellishmentTrimVerticalCafe"
                                    value={
                                        this.state
                                            .embellishmentTrimVerticalCafe !==
                                        undefined
                                            ? this.state
                                                  .embellishmentTrimVerticalCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="10"
                                />
                                <FormulaInput
                                    id="embellishmentTrimHorizontalCafe"
                                    value={
                                        this.state
                                            .embellishmentTrimHorizontalCafe !==
                                        undefined
                                            ? this.state
                                                  .embellishmentTrimHorizontalCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="4"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="text-sm text-twTableHeaderText mt-2">
                            Note: 1/2{'"'} seam allowances are included when
                            applicable
                        </p>
                    </div>

                    {/* MAIN FABRIC ADDITIONAL ALLOWANCE SECTION */}
                    <div className="mt-6">
                        <div className="border border-[#E2E2E2]  bg-[#ecf6f9] text-[#222222] h-[52px] w-full py-[16px] px-[10px] flex items-center font-medium">
                        <p className="uppercase">Main Fabric Additional Allowance</p>
                        </div>
                        <div className="m-4">
                            <Field className="mb-2">
                                <SettingsCheckbox
                                    id="isYardageAddOneExtra"
                                    name="isYardageAddOneExtra"
                                    checked={this.state.isYardageAddOneExtra}
                                    onChange={this.changeCheckbox}
                                    pricingRedesign
                                >
                                    <span className="text-[16px] leading-6">
                                        Add one extra vertical repeat to the MAIN
                                        FABRIC total yardage (per work order) for
                                        placement adjustment.
                                    </span>
                                </SettingsCheckbox>
                            </Field>
                            <Field>
                                <SettingsCheckbox
                                    id="isYardageAddExcess"
                                    name="isYardageAddExcess"
                                    checked={this.state.isYardageAddExcess}
                                    onChange={this.changeCheckbox}
                                    className="excess-box"
                                    pricingRedesign     
                                >
                                    {" "}
                                </SettingsCheckbox>
                                <ExcessBox style={{marginLeft: '-13px'}}>
                                    Add{" "}
                                    <SettingsLabeledInput
                                        id="addExcessValue"
                                        name="addExcessValue"
                                        value={
                                            this.state.addExcessValue !==
                                            undefined
                                                ? this.state.addExcessValue
                                                : ""
                                        }
                                        onKeyPress={this.handleKeyPress}
                                        onChange={
                                            this.handleInputChangeForNumber
                                        }
                                        onKeyDown={this.handleKeyDown}
                                        disabled={
                                            !this.state.isYardageAddExcess
                                        }
                                        uiDisabled={
                                            !this.state.isYardageAddExcess
                                        }
                                        type="number"
                                        min="0"
                                    />
                                    &nbsp;
                                    <SelectList
                                        selectList={unitList}
                                        handleClickSelect={
                                            this.handleSelectAddExcessUnit
                                        }
                                        valueSelect={this.state.addExcessUnit}
                                        flexDirection="row"
                                        name="addExcessUnit"
                                        smallPadding
                                        uiBorderColor="#3B97B1"
                                        noClearBox
                                        uiDropArrow
                                        disabled={
                                            !this.state.isYardageAddExcess
                                        }
                                        pricingRedesign
                                        style={{ width: '120px', height: '31px' }}
                                    />{" "}
                                    excess to the MAIN FABRIC total yardage (per
                                    work order).
                                </ExcessBox>
                            </Field>
                        </div>
                    </div>

                    {/* EDGES SIZES AND FULLNESS SECTION */}
                    <div className="mt-6 border border-[#E2E2E2]">
                        <div className="border-b border-[#E2E2E2]  bg-[#ecf6f9] text-[#222222] h-[52px] w-full py-[16px] px-[10px] flex items-center font-medium">
                            <p className="m-0">EDGE SIZES AND FULLNESS</p>
                            <TooltipWidePopup
                                text={
                                    <TooltipContainer>
                                        <TooltipSvgContainer>
                                            <TooltipReturnAndLeadEdge />
                                        </TooltipSvgContainer>
                                        <TooltipHeader>
                                            RETURN EDGE
                                        </TooltipHeader>
                                        <TooltipText>
                                            The horizontal space from the edge
                                            of the drapery that will return to
                                            the wall and the first pleat. The
                                            return edge size usually needs to
                                            match or be larger than the
                                            projection of the hardware bracket.
                                        </TooltipText>
                                        <TooltipHeader>LEAD EDGE</TooltipHeader>
                                        <TooltipText>
                                            The horizontal space between last
                                            pleat and the edge of the drapery
                                            that will be pulled to operate the
                                            drapery.
                                        </TooltipText>
                                        <TooltipSvgContainer>
                                            <TooltipOverlap />
                                        </TooltipSvgContainer>
                                        <TooltipHeader>OVERLAP</TooltipHeader>
                                        <TooltipText>
                                            The amount of horizontal space that
                                            a seamstress plans for two drapery
                                            panels to meet and cover the same
                                            area of rod in order to minimize
                                            light gap between them when hanging.
                                        </TooltipText>
                                        <DraperyFullnessTooltip>
                                            <TooltipHeader>
                                                DRAPERY FULLNESS
                                            </TooltipHeader>
                                            <TooltipText>
                                                The amount of extra fabric to be
                                                used in addition to finished
                                                width to create the desired body
                                                or “full” effect.&nbsp; The
                                                additional fabric is
                                                folded/gathered on the face of
                                                the panel to create a fuller
                                                look. For example 2x fullness
                                                indicates that the fabric pieces
                                                are twice as wide as the
                                                finished (pleated) width.
                                            </TooltipText>
                                            <div className="industry-standards">
                                                INDUSTRY STANDARDS
                                                <div className="industry-standards-table">
                                                    <div className="industry-standards-column">
                                                        <div>PLEATED</div>
                                                        <div>FLAT</div>
                                                        <div>SHEERS</div>
                                                    </div>
                                                    <div className="industry-standards-column border">
                                                        <div>
                                                            1.2-3x fullness
                                                        </div>
                                                        <div>1-2x fullness</div>
                                                        <div>3x fullness</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </DraperyFullnessTooltip>
                                    </TooltipContainer>
                                }
                                position="bottom left"
                                className="normal"
                                closeOnClick
                            />
                        </div>
                        <div className="grid grid-cols-4 divide-x divide-[#E2E2E2]">
                            <div className="w-full col-span-1">
                                <div>
                                    <div className="text-center pt-1 text-lg h-14 w-full mb-0"></div>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Lead / Butt
                                    </p>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Return
                                    </p>
                                    <p className="border-b border-[#E2E2E2] mb-0 p-2 h-14 flex items-center">
                                        Overlap
                                    </p>
                                    <p className=" mb-0 p-2 h-14 flex items-center">
                                        Fullness
                                        <TooltipPopup
                                            text={
                                                <TooltipContainer>
                                                    <DraperyFullnessTooltip>
                                                        <TooltipHeader>
                                                            DRAPERY FULLNESS
                                                        </TooltipHeader>
                                                        <TooltipText>
                                                            The amount of extra
                                                            fabric to be used in
                                                            addition to finished
                                                            width to create the
                                                            desired body or
                                                            “full” effect.&nbsp;
                                                            The additional
                                                            fabric is
                                                            folded/gathered on
                                                            the face of the
                                                            panel to create a
                                                            fuller look. For
                                                            example 2x fullness
                                                            indicates that the
                                                            fabric pieces are
                                                            twice as wide as the
                                                            finished (pleated)
                                                            width.
                                                        </TooltipText>
                                                        <div className="industry-standards">
                                                            INDUSTRY STANDARDS
                                                            <div className="industry-standards-table">
                                                                <div className="industry-standards-column">
                                                                    <div>
                                                                        PLEATED
                                                                    </div>
                                                                    <div>
                                                                        FLAT
                                                                    </div>
                                                                    <div>
                                                                        SHEERS
                                                                    </div>
                                                                </div>
                                                                <div className="industry-standards-column border">
                                                                    <div>
                                                                        1.5-3x
                                                                        fullness
                                                                    </div>
                                                                    <div>
                                                                        1-2x
                                                                        fullness
                                                                    </div>
                                                                    <div>
                                                                        3x
                                                                        fullness
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </DraperyFullnessTooltip>
                                                </TooltipContainer>
                                            }
                                            position="bottom left"
                                            className="normal"
                                            closeOnClick
                                        />
                                    </p>
                                </div>
                            </div>
                            {/* RIPPLEFOLD */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                    <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Ripplefold
                                        </p>
                                        <div className="h-full">
                                            <p className="text-center text-[14px] leading-5 font-normal mb-0">
                                                Size
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Inches input template */}
                                <SingleInchInput
                                    id="returnOverlapLeadSizeRipplefold"
                                    value={
                                        this.state
                                            .returnOverlapLeadSizeRipplefold !==
                                        undefined
                                            ? this.state
                                                  .returnOverlapLeadSizeRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="4"
                                />
                                <SingleInchInput
                                    id="returnOverlapReturnSizeRipplefold"
                                    value={
                                        this.state
                                            .returnOverlapReturnSizeRipplefold !==
                                        undefined
                                            ? this.state
                                                  .returnOverlapReturnSizeRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="4.25"
                                />
                                <SingleInchInput
                                    id="returnOverlapOverlapSizeRipplefold"
                                    value={
                                        this.state
                                            .returnOverlapOverlapSizeRipplefold !==
                                        undefined
                                            ? this.state
                                                  .returnOverlapOverlapSizeRipplefold
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="2"
                                />

                                <div className="h-14">
                                    <div className="h-full flex items-center justify-center py-2 px-6">
                                        {/* <select className="w-full border rounded">
                                                {
                                                    fullnessList.map((item) => {
                                                        return (
                                                            <option key={item.key}>{item.label}</option>
                                                        )
                                                    })
                                                }
                                            </select> */}
                                        <DraperyFullnessSelectList
                                            selectList={
                                                fullnessListRipplefoldList
                                            }
                                            handleClickSelect={
                                                this
                                                    .handleSelectRodRingCafeRipplefold
                                            }
                                            valueSelect={
                                                selectedDraperyFullnessLabelRipplefold
                                            }
                                            flexDirection="row"
                                            name="rodRing"
                                            smallPadding
                                            uiBorderColor="#3B97B1"
                                            noClearBox
                                            uiDropArrow
                                            pricingRedesign
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* FULL LENGTH */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                    <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Rod & Rings: Full Length
                                        </p>
                                        <div className="h-full">
                                            <p className="text-center text-[14px] leading-5 font-normal mb-0">
                                                Size
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Inches input template */}
                                <SingleInchInput
                                    id="returnOverlapLeadSize"
                                    value={
                                        this.state.returnOverlapLeadSize !==
                                        undefined
                                            ? this.state.returnOverlapLeadSize
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="3.5"
                                />
                                <SingleInchInput
                                    id="returnOverlapReturnSize"
                                    value={
                                        this.state.returnOverlapReturnSize !==
                                        undefined
                                            ? this.state.returnOverlapReturnSize
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="3.5"
                                />
                                <SingleInchInput
                                    id="returnOverlapOverlapSize"
                                    value={
                                        this.state.returnOverlapOverlapSize !==
                                        undefined
                                            ? this.state
                                                  .returnOverlapOverlapSize
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="3.5"
                                />

                                <div className="h-14">
                                    <div className="h-full flex items-center justify-center py-2 px-6">
                                        {/* <select className="w-full border rounded">
                                                {
                                                    fullnessList.map((item) => {
                                                        return (
                                                            <option key={item.key}>{item.label}</option>
                                                        )
                                                    })
                                                }
                                            </select> */}
                                        <DraperyFullnessSelectList
                                            selectList={fullnessList}
                                            handleClickSelect={
                                                this.handleSelectRodRing
                                            }
                                            valueSelect={
                                                selectedDraperyFullnessLabel
                                            }
                                            flexDirection="row"
                                            name="rodRing"
                                            smallPadding
                                            uiBorderColor="#3B97B1"
                                            noClearBox
                                            uiDropArrow
                                            pricingRedesign
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* CAFE  */}
                            <div className={`w-full col-span-1`}>
                                {/* Header */}
                                <div>
                                    <div className="h-14 overflow-hidden border-[#E2E2E2]">
                                    <p className="text-center pt-1  mb-0 text-[#3B97B1] font-medium leading-5">
                                            Rod & Rings: Cafe
                                        </p>
                                        <div className="h-full">
                                            <p className="text-center text-[14px] leading-5 font-normal mb-0">
                                                Size
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Inches input template */}
                                <SingleInchInput
                                    id="returnOverlapLeadSizeCafe"
                                    value={
                                        this.state.returnOverlapLeadSizeCafe !==
                                        undefined
                                            ? this.state
                                                  .returnOverlapLeadSizeCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="3.5"
                                />
                                <SingleInchInput
                                    id="returnOverlapReturnSizeCafe"
                                    value={
                                        this.state
                                            .returnOverlapReturnSizeCafe !==
                                        undefined
                                            ? this.state
                                                  .returnOverlapReturnSizeCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="3.5"
                                />
                                <SingleInchInput
                                    id="returnOverlapOverlapSizeCafe"
                                    value={
                                        this.state
                                            .returnOverlapOverlapSizeCafe !==
                                        undefined
                                            ? this.state
                                                  .returnOverlapOverlapSizeCafe
                                            : ""
                                    }
                                    onChange={this.handleInputChangeForNumber}
                                    handleKeyDown={this.handleKeyDown}
                                    onFocus={this.handleInputFocus}
                                    placeholder="3.5"
                                />

                                <div className="h-14">
                                    <div className="h-full flex items-center justify-center py-2 px-6">
                                        <DraperyFullnessSelectList
                                            selectList={fullnessList}
                                            handleClickSelect={
                                                this.handleSelectRodRingCafe
                                            }
                                            valueSelect={
                                                selectedDraperyFullnessLabelCafe
                                            }
                                            flexDirection="row"
                                            name="rodRing"
                                            smallPadding
                                            uiBorderColor="#3B97B1"
                                            noClearBox
                                            uiDropArrow
                                            pricingRedesign
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 ">
                        <div className="border border-[#E2E2E2]  bg-[#ecf6f9] text-[#222222] h-[52px] w-full py-[16px] px-[10px] flex items-center font-medium">
                            <p>OTHER DETAILS</p>
                        </div>
                        <div>
                            {/*
                            <OtherDetailsRadioGroup
                                title="Drapery pins installed by"
                                id="draperyPinInstaller"
                                isInstaller={true}
                                value={this.state.draperyPinInstaller}
                                onChange={this.handleOtherOptionsSelect}
                            /> */}
                            <OtherDetailsRadioGroup
                                title="Fabric purchaser"
                                id="fabricPurchaser"
                                value={this.state.fabricPurchaser}
                                onChange={this.handleOtherOptionsSelect}
                            />
                            <OtherDetailsRadioGroup
                                title="Embellishments purchaser"
                                id="embellishmentsPurchaser"
                                value={this.state.embellishmentsPurchaser}
                                onChange={this.handleOtherOptionsSelect}
                            />
                            <OtherDetailsRadioGroup
                                title="Linings purchaser"
                                id="liningsPurchaser"
                                value={this.state.liningsPurchaser}
                                onChange={this.handleOtherOptionsSelect}
                            />
                            {/* <OtherDetailsRadioGroup
                                title="Hardware purchaser"
                                id="hardwarePurchaser"
                                value={this.state.hardwarePurchaser}
                                onChange={this.handleOtherOptionsSelect}
                            /> */}
                        </div>
                    </div>

                    {this.state.formError && (
                        <div
                            className="border bg-red-200 border-[#E2E2E2] mt-4 p-2"
                            style={{ backgroundColor: "rgb(254 202 202)" }}
                            id="errorMessage"
                        >
                            {this.state.formErrorMessage}
                        </div>
                    )}
                </WorkroomForm>
            </PageContent>
        );
    }

    private async getExistingConstructionSettings(): Promise<void> {
        const { accountId } = this.props;
        // check whether default workroom settings is already exist
        // if not create new one
        const res = await searchWorkroomSetting(accountId);
        if (res.error) {
            throw new Error(res.message);
        }

        const accountDefaultSettings = res;
        const defaultSettingList = accountDefaultSettings
            .filter(
                (accountDefaultSetting: any) =>
                    defaultItemList.filter(
                        (defaultItem) =>
                            defaultItem.type === accountDefaultSetting.type &&
                            defaultItem.name === accountDefaultSetting.name
                    ).length > 0
            )
            .map((accountDefaultSetting: any) => ({
                key: accountDefaultSetting.id,
                label: accountDefaultSetting.name,
            }));
        for (const defaultItem of defaultItemList) {
            const foundIndex = accountDefaultSettings.findIndex(
                (accountDefaultSetting: any) => {
                    return (
                        accountDefaultSetting.type === defaultItem.type &&
                        accountDefaultSetting.name.localeCompare(
                            defaultItem.name
                        ) === 0
                    );
                }
            );
            // here type = 1 means drapery order default setting

            // foundIndex = -1 means there is no default setting record in db, so we have to create new one
            if (foundIndex === -1) {
                const res1 = await addWorkroomSetting({
                    accountId,
                    type: defaultItem.type,
                    name: defaultItem.name,
                    presetIsYardageAddOneExtra: true,
                    presetIsYardageAddExcess: false,
                });
                if (res1.error) {
                    throw new Error(res1.message);
                }
                const newDefaultSetting = res1;
                defaultSettingList.push({
                    key: newDefaultSetting.id,
                    label: newDefaultSetting.name,
                });
            }
        }
        this.setState({
            defaultSettingList,
        });
        await this.onDefaultNameChanged(
            defaultSettingList[0].key,
            defaultSettingList[0].label
        );
    }

    @Bind()
    handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (
            e.keyCode === 69 ||
            e.key === "e" ||
            e.keyCode === 188 ||
            e.keyCode === 187 ||
            e.keyCode === 61 ||
            e.keyCode === 107
        ) {
            e.preventDefault();
        }
        if (
            (e.keyCode === 189 ||
                e.keyCode === 173 ||
                e.keyCode === 109 ||
                e.keyCode === 48 ||
                e.keyCode === 96) &&
            this.state.previousKey === e.keyCode
        ) {
            e.preventDefault();
        }

        this.setState({ previousKey: e.keyCode });
        if (
            e.currentTarget.valueAsNumber &&
            (e.keyCode === 189 || e.keyCode === 173 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            e.currentTarget.valueAsNumber.toString().includes("0") &&
            (e.keyCode === 48 || e.keyCode === 96)
        ) {
            e.preventDefault();
        }
        if (
            e.currentTarget.validity.badInput &&
            (e.keyCode === 189 || e.keyCode === 173 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            this.state.previousKey !== null &&
            this.state.previousKey !== 189 &&
            e.currentTarget.value &&
            (e.keyCode === 173 || e.keyCode === 189 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            this.state.previousKey !== null &&
            this.state.previousKey !== 109 &&
            e.currentTarget.value &&
            (e.keyCode === 173 || e.keyCode === 189 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            e.currentTarget.value.includes("-") &&
            (e.keyCode === 173 || e.keyCode === 189 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
        if (
            this.state.previousKey !== null &&
            this.state.previousKey !== 173 &&
            e.currentTarget.value &&
            (e.keyCode === 173 || e.keyCode === 189 || e.keyCode === 109)
        ) {
            e.preventDefault();
        }
    }

    @Bind()
    private handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void {
        const targetId = e.currentTarget.id;
        const targetValue = e.currentTarget.value;
        const regex = /[a-zA-Z]/;

        // if (targetValue === "--") return;
        let nValue = targetValue ? +targetValue : undefined;
        if (targetValue.match(regex)) return;
        if (
            targetValue[0] === "0" &&
            !targetValue.includes(".") &&
            targetValue.length > 1
        ) {
            return;
        }
        if (
            (targetValue &&
                targetValue.length > 2 &&
                !targetValue.includes(".") &&
                !targetValue.includes("-")) ||
            (!targetValue.includes(".") &&
                targetValue.includes("-") &&
                targetValue.length > 3)
        )
            return;
        if (
            targetValue &&
            targetValue.includes(".") &&
            targetValue.split(".")[1].length > 2
        )
            return;
        if (
            targetValue[0] === "0" &&
            !targetValue.includes(".") &&
            targetValue.length >= 2
        ) {
            return;
        }
        if (
            targetValue.length === 3 &&
            targetValue.includes("-0") &&
            !targetValue.includes(".")
        ) {
            return;
        }

        // If changing allowance, change other values that rely on it as well
        if (targetId === "sideHemFabricAllowance") {
            this.setState({
                ...this.state,
                [e.currentTarget.id]: nValue,
                embellishmentCordingHorizontal: nValue,
                embellishmentFlangeHorizontal: nValue,
                embellishmentBandingHorizontal: nValue,
            });
        } else if (targetId === "mainFabric") {
            this.setState({
                ...this.state,
                [e.currentTarget.id]: nValue,
                embellishmentBandingVertical: nValue,
            });
        } else if (targetId === "sideHemFabricAllowanceCafe") {
            this.setState({
                ...this.state,
                [e.currentTarget.id]: nValue,
                embellishmentCordingHorizontalCafe: nValue,
                embellishmentFlangeHorizontalCafe: nValue,
                embellishmentBandingHorizontalCafe: nValue,
            });
        } else if (targetId === "mainFabricCafe") {
            this.setState({
                ...this.state,
                [e.currentTarget.id]: nValue,
                embellishmentBandingVerticalCafe: nValue,
            });
        } else if (targetId === "addExcessValue") {
            const numVal = allowOnlyPositiveNum(targetValue);

            this.setState({
                ...this.state,
                [e.currentTarget.id]: numVal,
            });
        } else {
            this.setState({ ...this.state, [e.currentTarget.id]: nValue });
        }

        this.setState({ isChanged: true });
        if (this.props.setIsChanged) {
            this.props.setIsChanged(true);
        }
        this.hideErrorMessage();
    }
    @Bind()
    private handleBuckramSelect(e: ChangeEvent<HTMLSelectElement>): void {
        this.setState({ ...this.state, buckram: e.target.value });
    }
    @Bind()
    private handleBuckramCafeSelect(e: ChangeEvent<HTMLSelectElement>): void {
        this.setState({ ...this.state, buckramCafe: e.target.value });
    }

    @Bind()
    private handleBuckramRipplefoldSelect(
        e: ChangeEvent<HTMLSelectElement>
    ): void {
        this.setState({ ...this.state, buckramRipplefold: e.target.value });
    }

    @Bind()
    private handleOtherOptionsSelect(
        section: string,
        e: ChangeEvent<HTMLInputElement>
    ): void {
        const nValue = e.currentTarget.value;
        this.setState({ ...this.state, [section]: nValue });
    }

    @Bind()
    private changeCheckbox(e: ChangeEvent<HTMLInputElement>): void {
        switch (e.currentTarget.id) {
            case "isYardageAddOneExtra": {
                this.setState({
                    isYardageAddOneExtra: !this.state.isYardageAddOneExtra,
                });
                break;
            }
            case "isYardageAddExcess": {
                this.setState({
                    isYardageAddExcess: !this.state.isYardageAddExcess,
                });
                break;
            }
        }
        this.setState({ isChanged: true });
        if (this.props.setIsChanged) {
            this.props.setIsChanged(true);
        }
        this.hideErrorMessage();
    }

    private validateWorkroomSettings() {
        if (
            this.state.topHemFinishedSize !== undefined &&
            this.state.topHemFinishedSize <= 0
        ) {
            return "Top hem size must be a value greater than 0.";
        }

        if (
            this.state.bottomHemFinishedSize !== undefined &&
            this.state.bottomHemFinishedSize <= 0
        ) {
            return "Bottom hem size must be a value greater than 0.";
        }

        if (
            this.state.sideHemFinishedSize !== undefined &&
            this.state.sideHemFinishedSize <= 0
        ) {
            return "Side hem size must be a value greater than 0.";
        }

        if (
            this.state.embellishmentCordingVertical !== undefined &&
            this.state.embellishmentCordingVertical < 0
        ) {
            return "Vertical Cording allowance must be a value of 0 or more.";
        }

        if (
            this.state.embellishmentTrimVertical !== undefined &&
            this.state.embellishmentTrimVertical < 0
        ) {
            return "Vertical Trim allowance must be a value of 0 or more.";
        }
        if (
            this.state.embellishmentTrimHorizontal !== undefined &&
            this.state.embellishmentTrimHorizontal < 0
        ) {
            return "Horizontal Trim allowance must be a value of 0 or more.";
        }

        if (
            this.state.topHemFabricAllowance !== undefined &&
            typeof this.state.topHemFabricAllowance !== "number"
        ) {
            return "The top hem allowance must be a number.";
        } else if (
            this.state.topHemFinishedSize !== undefined &&
            this.state.topHemFabricAllowance !== undefined &&
            this.state.topHemFabricAllowance < this.state.topHemFinishedSize
        ) {
            return "Top hem size cannot exceed the top hem allowance.";
        }

        if (
            this.state.bottomHemFabricAllowance !== undefined &&
            typeof this.state.bottomHemFabricAllowance !== "number"
        ) {
            return "The bottom hem allowance must be a number.";
        } else if (
            this.state.bottomHemFinishedSize !== undefined &&
            this.state.bottomHemFabricAllowance !== undefined &&
            this.state.bottomHemFabricAllowance <
                this.state.bottomHemFinishedSize
        ) {
            return "Bottom hem size cannot exceed the bottom hem allowance.";
        }

        if (
            this.state.sideHemFabricAllowance !== undefined &&
            typeof this.state.sideHemFabricAllowance !== "number"
        ) {
            return "The side ham allowance must be a number";
        } else if (
            this.state.sideHemFinishedSize !== undefined &&
            this.state.sideHemFabricAllowance !== undefined &&
            this.state.sideHemFabricAllowance < this.state.sideHemFinishedSize
        ) {
            return "Side hem size cannot exceed the side hem allowance.";
        }

        if (
            this.state.returnOverlapLeadSize !== undefined &&
            typeof this.state.returnOverlapLeadSize !== "number"
        ) {
            return "The leading edge size must be a number";
        } else if (
            this.state.sideHemFinishedSize !== undefined &&
            this.state.returnOverlapLeadSize !== undefined &&
            this.state.returnOverlapLeadSize < this.state.sideHemFinishedSize
        ) {
            return "Leading edge size cannot be less than the side hem size.";
        }

        if (
            this.state.returnOverlapReturnSize !== undefined &&
            typeof this.state.returnOverlapReturnSize !== "number"
        ) {
            return "The return edge size must be a number";
        } else if (
            this.state.sideHemFinishedSize !== undefined &&
            this.state.returnOverlapReturnSize !== undefined &&
            this.state.returnOverlapReturnSize < this.state.sideHemFinishedSize
        ) {
            return "Return edge size cannot be less than the side hem size.";
        }

        if (
            this.state.returnOverlapOverlapSize &&
            this.state.returnOverlapOverlapSize < 0
        ) {
            return "Overlap size must be a value 0 or greater.";
        }

        if (this.state.isYardageAddExcess) {
            if (this.state.addExcessValue === undefined) {
                return "Please specify the amount of excess yardage for main fabric.";
            } else if (this.state.addExcessValue < 0) {
                return "Amount of excess main fabric must be 0 or greater.";
            }

            if (
                this.state.addExcessUnit === undefined ||
                this.state.addExcessUnit === "" ||
                this.state.addExcessUnit === "select unit"
            ) {
                return "Please specify the unit of excess yardage for main fabric.";
            }
        }

        if (
            this.state.embellishmentFlangeVertical !== undefined &&
            this.state.embellishmentFlangeVertical < 0
        ) {
            return "Vertical Flange allowance must be a value of 0 or more.";
        }

        // Cafe validation
        if (
            this.state.topHemFinishedSizeCafe !== undefined &&
            this.state.topHemFinishedSizeCafe <= 0
        ) {
            return "Cafe top hem size must be a value greater than 0.";
        }

        if (
            this.state.bottomHemFinishedSizeCafe !== undefined &&
            this.state.bottomHemFinishedSizeCafe <= 0
        ) {
            return "Cafe bottom hem size must be a value greater than 0.";
        }

        if (
            this.state.sideHemFinishedSizeCafe !== undefined &&
            this.state.sideHemFinishedSizeCafe <= 0
        ) {
            return "Cafe side hem size must be a value greater than 0.";
        }

        if (
            this.state.embellishmentCordingVerticalCafe !== undefined &&
            this.state.embellishmentCordingVerticalCafe < 0
        ) {
            return "Cafe vertical cording allowance must be a value of 0 or more.";
        }

        if (
            this.state.embellishmentTrimVerticalCafe !== undefined &&
            this.state.embellishmentTrimVerticalCafe < 0
        ) {
            return "Cafe vertical trim allowance must be a value of 0 or more.";
        }
        if (
            this.state.embellishmentTrimHorizontalCafe !== undefined &&
            this.state.embellishmentTrimHorizontalCafe < 0
        ) {
            return "Cafe horizontal trim allowance must be a value of 0 or more.";
        }

        if (
            this.state.topHemFabricAllowanceCafe !== undefined &&
            typeof this.state.topHemFabricAllowanceCafe !== "number"
        ) {
            return "Cafe top hem allowance must be a number.";
        } else if (
            this.state.topHemFinishedSizeCafe !== undefined &&
            this.state.topHemFabricAllowanceCafe !== undefined &&
            this.state.topHemFabricAllowanceCafe <
                this.state.topHemFinishedSizeCafe
        ) {
            return "Cafe top hem size cannot exceed the top hem allowance.";
        }

        if (
            this.state.bottomHemFabricAllowanceCafe !== undefined &&
            typeof this.state.bottomHemFabricAllowanceCafe !== "number"
        ) {
            return "Cafe bottom hem allowance must be a number.";
        } else if (
            this.state.bottomHemFinishedSizeCafe !== undefined &&
            this.state.bottomHemFabricAllowanceCafe !== undefined &&
            this.state.bottomHemFabricAllowanceCafe <
                this.state.bottomHemFinishedSizeCafe
        ) {
            return "Cafe bottom hem size cannot exceed the bottom hem allowance.";
        }

        if (
            this.state.sideHemFabricAllowanceCafe !== undefined &&
            typeof this.state.sideHemFabricAllowanceCafe !== "number"
        ) {
            return "Cafe side ham allowance must be a number";
        } else if (
            this.state.sideHemFinishedSizeCafe !== undefined &&
            this.state.sideHemFabricAllowanceCafe !== undefined &&
            this.state.sideHemFabricAllowanceCafe <
                this.state.sideHemFinishedSizeCafe
        ) {
            return "Cafe side hem size cannot exceed the side hem allowance.";
        }

        if (
            this.state.returnOverlapLeadSizeCafe !== undefined &&
            typeof this.state.returnOverlapLeadSizeCafe !== "number"
        ) {
            return "Cafe leading edge size must be a number";
        } else if (
            this.state.sideHemFinishedSizeCafe !== undefined &&
            this.state.returnOverlapLeadSizeCafe !== undefined &&
            this.state.returnOverlapLeadSizeCafe <
                this.state.sideHemFinishedSizeCafe
        ) {
            return "Cafe leading edge size cannot be less than the side hem size.";
        }

        if (
            this.state.returnOverlapReturnSizeCafe !== undefined &&
            typeof this.state.returnOverlapReturnSizeCafe !== "number"
        ) {
            return "Cafe return edge size must be a number";
        } else if (
            this.state.sideHemFinishedSizeCafe !== undefined &&
            this.state.returnOverlapReturnSizeCafe !== undefined &&
            this.state.returnOverlapReturnSizeCafe <
                this.state.sideHemFinishedSizeCafe
        ) {
            return "Cafe return edge size cannot be less than the side hem size.";
        }

        if (
            this.state.returnOverlapOverlapSizeCafe &&
            this.state.returnOverlapOverlapSizeCafe < 0
        ) {
            return "Cafe Overlap size must be a value 0 or greater.";
        }

        if (
            this.state.embellishmentFlangeVerticalCafe !== undefined &&
            this.state.embellishmentFlangeVerticalCafe < 0
        ) {
            return "Cafe Vertical Flange allowance must be a value of 0 or more.";
        }

        // Ripplefold validation
        if (
            this.state.topHemFinishedSizeRipplefold !== undefined &&
            this.state.topHemFinishedSizeRipplefold <= 0
        ) {
            return "Ripplefold top hem size must be a value greater than 0.";
        }

        if (
            this.state.bottomHemFinishedSizeRipplefold !== undefined &&
            this.state.bottomHemFinishedSizeRipplefold <= 0
        ) {
            return "Ripplefold bottom hem size must be a value greater than 0.";
        }

        if (
            this.state.sideHemFinishedSizeRipplefold !== undefined &&
            this.state.sideHemFinishedSizeRipplefold <= 0
        ) {
            return "Ripplefold side hem size must be a value greater than 0.";
        }

        if (
            this.state.embellishmentCordingVerticalRipplefold !== undefined &&
            this.state.embellishmentCordingVerticalRipplefold < 0
        ) {
            return "Ripplefold vertical cording allowance must be a value of 0 or more.";
        }

        if (
            this.state.embellishmentTrimVerticalRipplefold !== undefined &&
            this.state.embellishmentTrimVerticalRipplefold < 0
        ) {
            return "Ripplefold vertical trim allowance must be a value of 0 or more.";
        }
        if (
            this.state.embellishmentTrimHorizontalRipplefold !== undefined &&
            this.state.embellishmentTrimHorizontalRipplefold < 0
        ) {
            return "Ripplefold horizontal trim allowance must be a value of 0 or more.";
        }

        if (
            this.state.topHemFabricAllowanceRipplefold !== undefined &&
            typeof this.state.topHemFabricAllowanceRipplefold !== "number"
        ) {
            return "Ripplefold top hem allowance must be a number.";
        } else if (
            this.state.topHemFinishedSizeRipplefold !== undefined &&
            this.state.topHemFabricAllowanceRipplefold !== undefined &&
            this.state.topHemFabricAllowanceRipplefold <
                this.state.topHemFinishedSizeRipplefold
        ) {
            return "Ripplefold top hem size cannot exceed the top hem allowance.";
        }

        if (
            this.state.bottomHemFabricAllowanceRipplefold !== undefined &&
            typeof this.state.bottomHemFabricAllowanceRipplefold !== "number"
        ) {
            return "Ripplefold bottom hem allowance must be a number.";
        } else if (
            this.state.bottomHemFinishedSizeRipplefold !== undefined &&
            this.state.bottomHemFabricAllowanceRipplefold !== undefined &&
            this.state.bottomHemFabricAllowanceRipplefold <
                this.state.bottomHemFinishedSizeRipplefold
        ) {
            return "Ripplefold bottom hem size cannot exceed the bottom hem allowance.";
        }

        if (
            this.state.sideHemFabricAllowanceRipplefold !== undefined &&
            typeof this.state.sideHemFabricAllowanceRipplefold !== "number"
        ) {
            return "Ripplefold side ham allowance must be a number";
        } else if (
            this.state.sideHemFinishedSizeRipplefold !== undefined &&
            this.state.sideHemFabricAllowanceRipplefold !== undefined &&
            this.state.sideHemFabricAllowanceRipplefold <
                this.state.sideHemFinishedSizeRipplefold
        ) {
            return "Ripplefold side hem size cannot exceed the side hem allowance.";
        }

        if (
            this.state.returnOverlapLeadSizeRipplefold !== undefined &&
            typeof this.state.returnOverlapLeadSizeRipplefold !== "number"
        ) {
            return "Ripplefold leading edge size must be a number";
        } else if (
            this.state.sideHemFinishedSizeRipplefold !== undefined &&
            this.state.returnOverlapLeadSizeRipplefold !== undefined &&
            this.state.returnOverlapLeadSizeRipplefold <
                this.state.sideHemFinishedSizeRipplefold
        ) {
            return "Ripplefold leading edge size cannot be less than the side hem size.";
        }

        if (
            this.state.returnOverlapReturnSizeRipplefold !== undefined &&
            typeof this.state.returnOverlapReturnSizeRipplefold !== "number"
        ) {
            return "Ripplefold return edge size must be a number";
        } else if (
            this.state.sideHemFinishedSizeRipplefold !== undefined &&
            this.state.returnOverlapReturnSizeRipplefold !== undefined &&
            this.state.returnOverlapReturnSizeRipplefold <
                this.state.sideHemFinishedSizeRipplefold
        ) {
            return "Ripplefold return edge size cannot be less than the side hem size.";
        }

        if (
            this.state.returnOverlapOverlapSizeRipplefold &&
            this.state.returnOverlapOverlapSizeRipplefold < 0
        ) {
            return "Ripplefold Overlap size must be a value 0 or greater.";
        }

        if (
            this.state.embellishmentFlangeVerticalRipplefold !== undefined &&
            this.state.embellishmentFlangeVerticalRipplefold < 0
        ) {
            return "Ripplefold Vertical Flange allowance must be a value of 0 or more.";
        }

        return "";
    }

    @Bind()
    //ts-ignore since this is used in another file
    //@ts-ignore
    private async saveCurrentSetting(): Promise<boolean> {
        if (this.state.currentSettingID === 0) {
            this.throwCalcError("Please select a default.");
            return false;
        }

        const validationResult = this.validateWorkroomSettings();
        if (validationResult !== "") {
            this.throwCalcError(validationResult);
            return false;
        }

        const buckramToSend =
            this.state.buckram === null
                ? "Ask for each order"
                : this.state.buckram;

        const res = await editWorkroomSetting({
            id: this.state.defaultSettingList[0].key, // Full Length
            presetIsYardageAddOneExtra: this.state.isYardageAddOneExtra,
            presetIsYardageAddExcess: this.state.isYardageAddExcess,
            presetAddExcessValue: this.state.addExcessValue,
            presetAddExcessUnit: this.state.addExcessUnit,
            buckram: buckramToSend,

            //draperyPinInstaller: this.state.draperyPinInstaller,
            fabricPurchaser: this.state.fabricPurchaser,
            embellishmentsPurchaser: this.state.embellishmentsPurchaser,
            liningsPurchaser: this.state.liningsPurchaser,
            //hardwarePurchaser: this.state.hardwarePurchaser,

            presetEmbellishmentJson: {
                cordingVertical: this.state.embellishmentCordingVertical,
                cordingHorizontal: (this.state.sideHemFabricAllowance || 0) * 2,
                bandingVertical: this.state.embellishmentBandingVertical,
                bandingHorizontal: (this.state.sideHemFabricAllowance || 0) * 2,
                flangeVertical: this.state.embellishmentFlangeVertical,
                flangeHorizontal: this.state.embellishmentFlangeHorizontal,
                trimVertical: this.state.embellishmentTrimVertical,
                trimHorizontal: this.state.embellishmentTrimHorizontal,
            },
            presetHEMSJson: {
                topHemFinishedSize: this.state.topHemFinishedSize,
                topHemFabricAllowance: this.state.topHemFabricAllowance,
                bottomHemFinishedSize: this.state.bottomHemFinishedSize,
                bottomHemFabricAllowance: this.state.bottomHemFabricAllowance,
                sideHemFinishedSize: this.state.sideHemFinishedSize,
                sideHemFabricAllowance: this.state.sideHemFabricAllowance,
            },
            presetReturnOverlapJson: {
                leadSize: this.state.returnOverlapLeadSize,
                returnSize: this.state.returnOverlapReturnSize,
                overlapSize: this.state.returnOverlapOverlapSize,
            },
            presetCutLengthJson: {
                mainFabric: this.state.mainFabric,
                lining: this.state.cutLengthLining,
                interlining: this.state.cutLengthInterlining,
            },
            presetDraperyFullnessJson: {
                value: this.state.draperyFullnessValue,
            },
        });
        const buckramCafeToSend =
            this.state.buckramCafe === null
                ? "Ask for each order"
                : this.state.buckramCafe;

        // for Cafe
        const cafeRes = await editWorkroomSetting({
            id: this.state.defaultSettingList[1].key, // Cafe
            presetIsYardageAddOneExtra: this.state.isYardageAddOneExtra,
            presetIsYardageAddExcess: this.state.isYardageAddExcess,
            presetAddExcessValue: this.state.addExcessValue,
            presetAddExcessUnit: this.state.addExcessUnit,
            buckram: buckramCafeToSend,

            //draperyPinInstaller: this.state.draperyPinInstaller,
            fabricPurchaser: this.state.fabricPurchaser,
            embellishmentsPurchaser: this.state.embellishmentsPurchaser,
            liningsPurchaser: this.state.liningsPurchaser,
            //hardwarePurchaser: this.state.hardwarePurchaser,

            presetEmbellishmentJson: {
                cordingVertical: this.state.embellishmentCordingVerticalCafe,
                cordingHorizontal:
                    (this.state.sideHemFabricAllowanceCafe || 0) * 2,
                bandingVertical: this.state.embellishmentBandingVerticalCafe,
                bandingHorizontal:
                    (this.state.sideHemFabricAllowanceCafe || 0) * 2,
                flangeVertical: this.state.embellishmentFlangeVerticalCafe,
                flangeHorizontal: this.state.embellishmentFlangeHorizontalCafe,
                trimVertical: this.state.embellishmentTrimVerticalCafe,
                trimHorizontal: this.state.embellishmentTrimHorizontalCafe,
            },
            presetHEMSJson: {
                topHemFinishedSize: this.state.topHemFinishedSizeCafe,
                topHemFabricAllowance: this.state.topHemFabricAllowanceCafe,
                bottomHemFinishedSize: this.state.bottomHemFinishedSizeCafe,
                bottomHemFabricAllowance:
                    this.state.bottomHemFabricAllowanceCafe,
                sideHemFinishedSize: this.state.sideHemFinishedSizeCafe,
                sideHemFabricAllowance: this.state.sideHemFabricAllowanceCafe,
            },
            presetReturnOverlapJson: {
                leadSize: this.state.returnOverlapLeadSizeCafe,
                returnSize: this.state.returnOverlapReturnSizeCafe,
                overlapSize: this.state.returnOverlapOverlapSizeCafe,
            },
            presetCutLengthJson: {
                mainFabric: this.state.mainFabricCafe,
                lining: this.state.cutLengthLiningCafe,
                interlining: this.state.cutLengthInterliningCafe,
            },
            presetDraperyFullnessJson: {
                value: this.state.draperyFullnessValueCafe,
            },
        });

        const buckramRipplefoldToSend =
            this.state.buckramRipplefold === null
                ? "Ask for each order"
                : this.state.buckramRipplefold;

        const ripplefoldRes = await editWorkroomSetting({
            id: this.state.defaultSettingList[2].key, // Full Length
            presetIsYardageAddOneExtra: this.state.isYardageAddOneExtra,
            presetIsYardageAddExcess: this.state.isYardageAddExcess,
            presetAddExcessValue: this.state.addExcessValue,
            presetAddExcessUnit: this.state.addExcessUnit,
            buckram: buckramRipplefoldToSend,

            //draperyPinInstaller: this.state.draperyPinInstaller,
            fabricPurchaser: this.state.fabricPurchaser,
            embellishmentsPurchaser: this.state.embellishmentsPurchaser,
            liningsPurchaser: this.state.liningsPurchaser,
            //hardwarePurchaser: this.state.hardwarePurchaser,

            presetEmbellishmentJson: {
                cordingVertical:
                    this.state.embellishmentCordingVerticalRipplefold,
                cordingHorizontal:
                    (this.state.sideHemFabricAllowanceRipplefold || 0) * 2,
                bandingVertical:
                    this.state.embellishmentBandingVerticalRipplefold,
                bandingHorizontal:
                    (this.state.sideHemFabricAllowanceRipplefold || 0) * 2,
                flangeVertical:
                    this.state.embellishmentFlangeVerticalRipplefold,
                flangeHorizontal:
                    (this.state.sideHemFabricAllowanceRipplefold || 0) * 2,
                trimVertical: this.state.embellishmentTrimVerticalRipplefold,
                trimHorizontal:
                    this.state.embellishmentTrimHorizontalRipplefold,
            },
            presetHEMSJson: {
                topHemFinishedSize: this.state.topHemFinishedSizeRipplefold,
                topHemFabricAllowance:
                    this.state.topHemFabricAllowanceRipplefold,
                bottomHemFinishedSize:
                    this.state.bottomHemFinishedSizeRipplefold,
                bottomHemFabricAllowance:
                    this.state.bottomHemFabricAllowanceRipplefold,
                sideHemFinishedSize: this.state.sideHemFinishedSizeRipplefold,
                sideHemFabricAllowance:
                    this.state.sideHemFabricAllowanceRipplefold,
            },
            presetReturnOverlapJson: {
                leadSize: this.state.returnOverlapLeadSizeRipplefold,
                returnSize: this.state.returnOverlapReturnSizeRipplefold,
                overlapSize: this.state.returnOverlapOverlapSizeRipplefold,
            },
            presetCutLengthJson: {
                mainFabric: this.state.mainFabricRipplefold,
                lining: this.state.cutLengthLiningRipplefold,
                interlining: this.state.cutLengthInterliningRipplefold,
            },
            presetDraperyFullnessJson: {
                value: this.state.draperyFullnessValueRipplefold,
            },
        });

        if (res.error) {
            this.throwCalcError(res.message);
            return false;
        }
        if (cafeRes.error) {
            this.throwCalcError(cafeRes.message);
            return false;
        }

        if (ripplefoldRes.error) {
            this.throwCalcError(ripplefoldRes.message);
            return false;
        }
        return true;
    }
    @Bind()
    private handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
        const { key } = event;
        // Check if the pressed key is "-" or any other unwanted characters
        if (key === "-" || key === "+") {
            event.preventDefault();
        }
    }

    @Bind()
    private throwCalcError(errorMsg: string) {
        this.setState({ formError: true, formErrorMessage: errorMsg });
    }

    @Bind()
    private hideErrorMessage(): void {
        this.setState({ formError: false, formErrorMessage: "" });
    }

    @Bind()
    private async submit(e: FormEvent<HTMLFormElement>): Promise<void> {
        //do nothing
    }

    private handleSelectRodRing = (value: number, name?: string): void => {
        this.setState({ draperyFullnessValue: value, isChanged: true });
        if (this.props.setIsChanged) {
            this.props.setIsChanged(true);
        }
    };
    private handleSelectRodRingCafe = (value: number, name?: string): void => {
        this.setState({ draperyFullnessValueCafe: value, isChanged: true });
        if (this.props.setIsChanged) {
            this.props.setIsChanged(true);
        }
    };

    private handleSelectRodRingCafeRipplefold = (
        value: number,
        name?: string
    ): void => {
        this.setState({
            draperyFullnessValueRipplefold: value,
            isChanged: true,
        });
        if (this.props.setIsChanged) {
            this.props.setIsChanged(true);
        }
    };

    private handleSelectAddExcessUnit = (
        value: string,
        name?: string
    ): void => {
        this.setState({ addExcessUnit: value, isChanged: true });
        if (this.props.setIsChanged) {
            this.props.setIsChanged(true);
        }
    };

    private async onDefaultNameChanged(
        value: number,
        label: string
    ): Promise<void> {
        const res = await getWorkroomSetting({ id: value });
        const cafeRes = await getWorkroomSetting({
            id: this.state.defaultSettingList[1].key,
        });

        const ripplefoldRes = await getWorkroomSetting({
            id: this.state.defaultSettingList[2].key,
        });
        if (res.error) {
            throw new Error(res.message);
        }
        if (cafeRes.error) {
            throw new Error(cafeRes.message);
        }
        if (ripplefoldRes.error) {
            throw new Error(ripplefoldRes.message);
        }
        const defaultSettingDB = res;
        const defaultSettingDBCafe = cafeRes;
        const defaultSettingDBRipplefold = ripplefoldRes;

        this.setState({
            currentSettingID: value,
            currentSettingName: label,

            isYardageAddOneExtra:
                defaultSettingDB.presetIsYardageAddOneExtra !== undefined
                    ? defaultSettingDB.presetIsYardageAddOneExtra
                    : true,
            isYardageAddExcess:
                defaultSettingDB.presetIsYardageAddExcess !== undefined
                    ? defaultSettingDB.presetIsYardageAddExcess
                    : false,

            addExcessValue: defaultSettingDB.presetAddExcessValue,
            addExcessUnit:
                defaultSettingDB.presetAddExcessUnit || "select unit",

            //draperyPinInstaller: defaultSettingDB.draperyPinInstaller,
            fabricPurchaser: defaultSettingDB.fabricPurchaser,
            embellishmentsPurchaser: defaultSettingDB.embellishmentsPurchaser,
            liningsPurchaser: defaultSettingDB.liningsPurchaser,
            //hardwarePurchaser: defaultSettingDB.hardwarePurchaser,
            buckram: defaultSettingDB.buckram,
            embellishmentFlangeVertical:
                defaultSettingDB.presetEmbellishmentJson
                    ? defaultSettingDB.presetEmbellishmentJson.flangeVertical
                    : undefined,
            embellishmentFlangeHorizontal:
                defaultSettingDB.presetEmbellishmentJson
                    ? defaultSettingDB.presetEmbellishmentJson.flangeHorizontal
                    : undefined,
            embellishmentTrimVertical: defaultSettingDB.presetEmbellishmentJson
                ? defaultSettingDB.presetEmbellishmentJson.trimVertical
                : undefined,
            embellishmentTrimHorizontal:
                defaultSettingDB.presetEmbellishmentJson
                    ? defaultSettingDB.presetEmbellishmentJson.trimHorizontal
                    : undefined,
            embellishmentCordingVertical:
                defaultSettingDB.presetEmbellishmentJson
                    ? defaultSettingDB.presetEmbellishmentJson.cordingVertical
                    : undefined,
            embellishmentCordingHorizontal:
                defaultSettingDB.presetEmbellishmentJson
                    ? defaultSettingDB.presetEmbellishmentJson.trimHorizontal
                    : undefined,

            topHemFinishedSize: defaultSettingDB.presetHEMSJson
                ? defaultSettingDB.presetHEMSJson.topHemFinishedSize
                : undefined,
            topHemFabricAllowance: defaultSettingDB.presetHEMSJson
                ? defaultSettingDB.presetHEMSJson.topHemFabricAllowance
                : undefined,
            bottomHemFinishedSize: defaultSettingDB.presetHEMSJson
                ? defaultSettingDB.presetHEMSJson.bottomHemFinishedSize
                : undefined,
            bottomHemFabricAllowance: defaultSettingDB.presetHEMSJson
                ? defaultSettingDB.presetHEMSJson.bottomHemFabricAllowance
                : undefined,
            sideHemFinishedSize: defaultSettingDB.presetHEMSJson
                ? defaultSettingDB.presetHEMSJson.sideHemFinishedSize
                : undefined,
            sideHemFabricAllowance: defaultSettingDB.presetHEMSJson
                ? defaultSettingDB.presetHEMSJson.sideHemFabricAllowance
                : undefined,

            returnOverlapLeadSize: defaultSettingDB.presetReturnOverlapJson
                ? defaultSettingDB.presetReturnOverlapJson.leadSize
                : undefined,
            returnOverlapReturnSize: defaultSettingDB.presetReturnOverlapJson
                ? defaultSettingDB.presetReturnOverlapJson.returnSize
                : undefined,
            returnOverlapOverlapSize: defaultSettingDB.presetReturnOverlapJson
                ? defaultSettingDB.presetReturnOverlapJson.overlapSize
                : undefined,

            mainFabric: defaultSettingDB.presetCutLengthJson
                ? defaultSettingDB.presetCutLengthJson.mainFabric
                : undefined,

            cutLengthLining: defaultSettingDB.presetCutLengthJson
                ? defaultSettingDB.presetCutLengthJson.lining
                : undefined,
            cutLengthInterlining: defaultSettingDB.presetCutLengthJson
                ? defaultSettingDB.presetCutLengthJson.interlining
                : undefined,

            draperyFullnessValue: defaultSettingDB.presetDraperyFullnessJson
                ? defaultSettingDB.presetDraperyFullnessJson.value
                : undefined,
            draperyFullnessValueCafe:
                defaultSettingDBCafe.presetDraperyFullnessJson
                    ? defaultSettingDBCafe.presetDraperyFullnessJson.value
                    : undefined,
            draperyFullnessValueRipplefold:
                defaultSettingDBRipplefold.presetDraperyFullnessJson
                    ? defaultSettingDBRipplefold.presetDraperyFullnessJson.value
                    : undefined,

            // Cafe
            buckramCafe: defaultSettingDBCafe.buckram,
            embellishmentFlangeVerticalCafe:
                defaultSettingDBCafe.presetEmbellishmentJson
                    ? defaultSettingDBCafe.presetEmbellishmentJson
                          .flangeVertical
                    : undefined,
            embellishmentFlangeHorizontalCafe:
                defaultSettingDBCafe.presetEmbellishmentJson
                    ? defaultSettingDBCafe.presetEmbellishmentJson
                          .flangeHorizontal
                    : undefined,
            embellishmentTrimVerticalCafe:
                defaultSettingDBCafe.presetEmbellishmentJson
                    ? defaultSettingDBCafe.presetEmbellishmentJson.trimVertical
                    : undefined,
            embellishmentTrimHorizontalCafe:
                defaultSettingDBCafe.presetEmbellishmentJson
                    ? defaultSettingDBCafe.presetEmbellishmentJson
                          .trimHorizontal
                    : undefined,
            embellishmentCordingVerticalCafe:
                defaultSettingDBCafe.presetEmbellishmentJson
                    ? defaultSettingDBCafe.presetEmbellishmentJson
                          .cordingVertical
                    : undefined,
            embellishmentCordingHorizontalCafe:
                defaultSettingDBCafe.presetEmbellishmentJson
                    ? defaultSettingDBCafe.presetEmbellishmentJson
                          .trimHorizontal
                    : undefined,

            topHemFinishedSizeCafe: defaultSettingDBCafe.presetHEMSJson
                ? defaultSettingDBCafe.presetHEMSJson.topHemFinishedSize
                : undefined,
            topHemFabricAllowanceCafe: defaultSettingDBCafe.presetHEMSJson
                ? defaultSettingDBCafe.presetHEMSJson.topHemFabricAllowance
                : undefined,
            bottomHemFinishedSizeCafe: defaultSettingDBCafe.presetHEMSJson
                ? defaultSettingDBCafe.presetHEMSJson.bottomHemFinishedSize
                : undefined,
            bottomHemFabricAllowanceCafe: defaultSettingDBCafe.presetHEMSJson
                ? defaultSettingDBCafe.presetHEMSJson.bottomHemFabricAllowance
                : undefined,
            sideHemFinishedSizeCafe: defaultSettingDBCafe.presetHEMSJson
                ? defaultSettingDBCafe.presetHEMSJson.sideHemFinishedSize
                : undefined,
            sideHemFabricAllowanceCafe: defaultSettingDBCafe.presetHEMSJson
                ? defaultSettingDBCafe.presetHEMSJson.sideHemFabricAllowance
                : undefined,

            returnOverlapLeadSizeCafe:
                defaultSettingDBCafe.presetReturnOverlapJson
                    ? defaultSettingDBCafe.presetReturnOverlapJson.leadSize
                    : undefined,
            returnOverlapReturnSizeCafe:
                defaultSettingDBCafe.presetReturnOverlapJson
                    ? defaultSettingDBCafe.presetReturnOverlapJson.returnSize
                    : undefined,
            returnOverlapOverlapSizeCafe:
                defaultSettingDBCafe.presetReturnOverlapJson
                    ? defaultSettingDBCafe.presetReturnOverlapJson.overlapSize
                    : undefined,

            mainFabricCafe: defaultSettingDBCafe.presetCutLengthJson
                ? defaultSettingDBCafe.presetCutLengthJson.mainFabric
                : undefined,
            cutLengthLiningCafe: defaultSettingDBCafe.presetCutLengthJson
                ? defaultSettingDBCafe.presetCutLengthJson.lining
                : undefined,
            cutLengthInterliningCafe: defaultSettingDBCafe.presetCutLengthJson
                ? defaultSettingDBCafe.presetCutLengthJson.interlining
                : undefined,

            buckramRipplefold: defaultSettingDBRipplefold.buckram,
            embellishmentFlangeVerticalRipplefold:
                defaultSettingDBRipplefold.presetEmbellishmentJson
                    ? defaultSettingDBRipplefold.presetEmbellishmentJson
                          .flangeVertical
                    : undefined,
            embellishmentFlangeHorizontalRipplefold:
                defaultSettingDBRipplefold.presetEmbellishmentJson
                    ? defaultSettingDBRipplefold.presetEmbellishmentJson
                          .flangeHorizontal
                    : undefined,
            embellishmentTrimVerticalRipplefold:
                defaultSettingDBRipplefold.presetEmbellishmentJson
                    ? defaultSettingDBRipplefold.presetEmbellishmentJson
                          .trimVertical
                    : undefined,
            embellishmentTrimHorizontalRipplefold:
                defaultSettingDBRipplefold.presetEmbellishmentJson
                    ? defaultSettingDBRipplefold.presetEmbellishmentJson
                          .trimHorizontal
                    : undefined,
            embellishmentCordingVerticalRipplefold:
                defaultSettingDBRipplefold.presetEmbellishmentJson
                    ? defaultSettingDBRipplefold.presetEmbellishmentJson
                          .cordingVertical
                    : undefined,
            embellishmentCordingHorizontalRipplefold:
                defaultSettingDBRipplefold.presetEmbellishmentJson
                    ? defaultSettingDBRipplefold.presetEmbellishmentJson
                          .trimHorizontal
                    : undefined,

            topHemFinishedSizeRipplefold:
                defaultSettingDBRipplefold.presetHEMSJson
                    ? defaultSettingDBRipplefold.presetHEMSJson
                          .topHemFinishedSize
                    : undefined,
            topHemFabricAllowanceRipplefold:
                defaultSettingDBRipplefold.presetHEMSJson
                    ? defaultSettingDBRipplefold.presetHEMSJson
                          .topHemFabricAllowance
                    : undefined,
            bottomHemFinishedSizeRipplefold:
                defaultSettingDBRipplefold.presetHEMSJson
                    ? defaultSettingDBRipplefold.presetHEMSJson
                          .bottomHemFinishedSize
                    : undefined,
            bottomHemFabricAllowanceRipplefold:
                defaultSettingDBRipplefold.presetHEMSJson
                    ? defaultSettingDBRipplefold.presetHEMSJson
                          .bottomHemFabricAllowance
                    : undefined,
            sideHemFinishedSizeRipplefold:
                defaultSettingDBRipplefold.presetHEMSJson
                    ? defaultSettingDBRipplefold.presetHEMSJson
                          .sideHemFinishedSize
                    : undefined,
            sideHemFabricAllowanceRipplefold:
                defaultSettingDBRipplefold.presetHEMSJson
                    ? defaultSettingDBRipplefold.presetHEMSJson
                          .sideHemFabricAllowance
                    : undefined,

            returnOverlapLeadSizeRipplefold:
                defaultSettingDBRipplefold.presetReturnOverlapJson
                    ? defaultSettingDBRipplefold.presetReturnOverlapJson
                          .leadSize
                    : undefined,
            returnOverlapReturnSizeRipplefold:
                defaultSettingDBRipplefold.presetReturnOverlapJson
                    ? defaultSettingDBRipplefold.presetReturnOverlapJson
                          .returnSize
                    : undefined,
            returnOverlapOverlapSizeRipplefold:
                defaultSettingDBRipplefold.presetReturnOverlapJson
                    ? defaultSettingDBRipplefold.presetReturnOverlapJson
                          .overlapSize
                    : undefined,

            mainFabricRipplefold: defaultSettingDBRipplefold.presetCutLengthJson
                ? defaultSettingDBRipplefold.presetCutLengthJson.mainFabric
                : undefined,
            cutLengthLiningRipplefold:
                defaultSettingDBRipplefold.presetCutLengthJson
                    ? defaultSettingDBRipplefold.presetCutLengthJson.lining
                    : undefined,
            cutLengthInterliningRipplefold:
                defaultSettingDBRipplefold.presetCutLengthJson
                    ? defaultSettingDBRipplefold.presetCutLengthJson.interlining
                    : undefined,
        });
    }
}

export default connect(null)(ConstructionSettings);

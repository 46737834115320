import {
    DraperyOrderDesignEmbellishment,
    DraperyOrderFabric,
    DraperyOrderLiningsFabric,
    EmbellishmentCheckInfo,
    ValidationConfirmationInfo,
} from "./calculator";
import React, { ChangeEvent, PureComponent } from "react";

import { DraperyModule } from "../../redux";
import FabricsComponent from "./fabric-tab-component";
import FabricsLiningsSection from "./fabrics-tab-linings-section";
import FabricsMainComponent from "./fabric-tab-main-component";
import { SearchSelectProps } from "../../components/UI/search_select/search_select";
import { ValidationErrorInfo } from "./interfaces-common";
import { defaultDraperyOrderSummaryComponent } from "./summary-tab";
import { FormFieldsPartial } from "../../redux/modules/my-shop/form-fields-reducer";
import { FabricsOrLiningsItemDetailsFieldsPartial } from "../../redux/modules/my-shop/fabrics-or-linings-item-details-fields-reducer";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";

export interface FabricPatternItem
    extends FormFieldsPartial,
        FabricsOrLiningsItemDetailsFieldsPartial {}

export const defaultMainFabric = (): DraperyOrderFabric => ({
    id: 0,
    draperyOrderId: 0,
    name: "MAIN FABRIC",
    type: 1,
    supplierId: 0,
    pattern: "",
    patternId: 0,
    imageId: null,
    orderOrigin: -1,
    applicationMethod: undefined,
    isSpecialtyFabricHandling: false,
    isDropRepeat: false,
    directionOfUse: 1,
    isMatchRepeatAtSeams: true,
    isPleatToPattern: false,
    values: {},
    supplierName: "",
    customSupplier: "",
    isActive: undefined,
    draperyOrderSummaryComponent:
        defaultDraperyOrderSummaryComponent("MAIN FABRIC"),
});

export const filterMainFabric = (
    mainFabric: DraperyModule.DraperyOrderFabric & { supplierName?: string } & {
        customSupplier?: string;
    },
    component: DraperyModule.DraperyOrderSummaryComponent
): DraperyOrderFabric => ({
    id: mainFabric.id,
    draperyOrderId: mainFabric.draperyOrderId,
    name: "MAIN FABRIC",
    type: 1,
    supplierId: mainFabric.supplierId,
    pattern: mainFabric.pattern,
    imageId: mainFabric.imageId,
    orderOrigin: mainFabric.orderOrigin,
    applicationMethod: undefined,
    isSpecialtyFabricHandling: mainFabric.isSpecialtyFabricHandling,
    isDropRepeat: mainFabric.isDropRepeat,
    directionOfUse: mainFabric.directionOfUse,
    isMatchRepeatAtSeams: mainFabric.isMatchRepeatAtSeams,
    isPleatToPattern: mainFabric.isPleatToPattern || false,
    values: {
        fabricWidth: mainFabric.values.fabricWidth,
        verticalRepeat: mainFabric.values.verticalRepeat,
        horizontalRepeat: mainFabric.values.horizontalRepeat,
    },
    supplierName: mainFabric.supplierName,
    customSupplier: mainFabric.customSupplier,
    draperyOrderSummaryComponent: component,
    itemId: mainFabric.itemId,
    yardsNeeded: mainFabric.yardsNeeded
});

const validateMainFabricDirectionOfUse = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    mainFabric: DraperyModule.DraperyOrderFabric & { supplierName?: string },
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
) => {
    const { directionOfUse, values } = mainFabric;

    if (directionOfUse !== 2) {
        return errors;
    }

    const topHemFabricAllowance = draperyOrderDesign.customHEMSJson
        .topHemFabricAllowance
        ? draperyOrderDesign.customHEMSJson.topHemFabricAllowance
        : 0;
    const bottomHemFabricAllowance = draperyOrderDesign.customHEMSJson
        .bottomHemFabricAllowance
        ? draperyOrderDesign.customHEMSJson.bottomHemFabricAllowance
        : 0;
    if (
        draperyOrderDesign.finishedLengthOfPanels +
            topHemFabricAllowance +
            bottomHemFabricAllowance >
        values.fabricWidth!
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "values.fabricWidth",
            message:
                "Main fabric cannot be railroaded without a horizontal seam across the drapery due to the finished length of the treatment. Please adjust your finished length, hem allowance, or change the direction of use for this fabric.",
        });
    }
    return errors;
};

// validate
export const validateDraperyOrderMainFabric = (
    mainFabric: DraperyModule.DraperyOrderFabric & { supplierName?: string },
    draperyOrderDesign: DraperyModule.DraperyOrderDesign,
    fabricsPatternItems?: FabricPatternItem[] | undefined,
    workroomSetting?: WorkroomSetting | null,
    orderType?: string,
): ValidationErrorInfo[] => {
    if (!mainFabric) {
        return [];
    }
    const isValidMainFabric = fabricsPatternItems!.find(
        (fab) => fab.name === mainFabric.pattern
    );
    const prefixName = "mainFabric.";
    const tabIndex = 2;
    let errors: ValidationErrorInfo[] = [];
    if (!mainFabric.pattern && mainFabric.orderOrigin === 1) {
        errors.push({
            tabIndex,
            name: prefixName + "pattern",
            message: "Please specify the Main Fabric Pattern.",
        });
    }

    // if (!mainFabric.supplierId && mainFabric.orderOrigin === 1) {
    //     errors.push({
    //         tabIndex,
    //         name: prefixName + "supplierId",
    //         message: "Please specify the Main Fabric Supplier.",
    //     });
    // }

    if (
        (!isValidMainFabric && mainFabric && mainFabric.orderOrigin === 1) ||
        (mainFabric.orderOrigin === 1 &&
            isValidMainFabric &&
            !isValidMainFabric.name)
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "pattern",
            message:
                "Main Fabric and its pricing must be added to My Shop if workroom is ordering.",
        });
    }
    if ((isValidMainFabric && !isValidMainFabric.isActive)) {
        errors.push({
            tabIndex,
            name: prefixName + "pattern" + ".openModalForPattern",
            message:
            `${isValidMainFabric.name} is marked as inactive in My Shop and must be made active to use on orders.`
        });
    }

    if (
        mainFabric.orderOrigin === -1 &&
        workroomSetting &&
        workroomSetting.fabricPurchaser !== "Workroom" &&
        workroomSetting &&
        workroomSetting.fabricPurchaser !== "Installer"
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "orderOrigin",
            message: "Please specify who will order the Main Fabric.",
        });
    }

    if (mainFabric.orderOrigin === -1 && orderType === 'roman-shade-order') {
        errors.push({
            tabIndex,
            name: prefixName + "orderOrigin",
            message: "Please specify who will order the Main Fabric.",
        });
    }

    // if (
    //     (mainFabric.orderOrigin === 1 ||
    //         (workroomSetting &&
    //             workroomSetting.fabricPurchaser === "Workroom" &&
    //             mainFabric.orderOrigin === 1)) &&
    //     isValidMainFabric &&
    //     isValidMainFabric.cost === null &&
    //     isValidMainFabric.retailPricingValue === null &&
    //     isValidMainFabric.tradePricingValue === null
    // ) {
    //     errors.push({
    //         tabIndex,
    //         name: prefixName + "pattern",
    //         message:
    //             "Main Fabric and its pricing must be added to My Shop if workroom is ordering.",
    //     });
    // }

    if (mainFabric.directionOfUse === 0) {
        errors.push({
            tabIndex,
            name: prefixName + "directionOfUse",
            message: "Please select direction of use",
        });
    }

    const values = mainFabric.values;
    if (orderType && orderType !== 'custom-order' && (values.fabricWidth === undefined || values.fabricWidth === null || values.fabricWidth as any === '' || values.fabricWidth !== undefined && values.fabricWidth !== null && parseFloat(values.fabricWidth.toString()) < 1)) {
        const end_of_message = orderType === 'roman-shade-order' ? '1 or more' : 'at least 1"'
        errors.push({
            tabIndex,
            name: prefixName + "values.fabricWidth",
            message: `Main Fabric width must be a value of ${end_of_message}.`,
        });
    }
    if (orderType && (orderType === 'drapery-order' || orderType === 'roman-shade-order') && (values.verticalRepeat == null || values.verticalRepeat === undefined || values.verticalRepeat !== undefined && values.verticalRepeat !== null && isNaN(parseFloat(values.verticalRepeat.toString())) || values.verticalRepeat !== undefined && values.verticalRepeat !== null && parseFloat(values.verticalRepeat.toString()) < 0)) {
        errors.push({
            tabIndex,
            name: prefixName + "values.verticalRepeat",
            message:
                "Main Fabric vertical repeat must be a value of 0 or more.",
        });
    }
    if (orderType && (orderType === 'drapery-order' || orderType === 'roman-shade-order') && (values.horizontalRepeat == null || values.horizontalRepeat === undefined || values.horizontalRepeat !== undefined && values.horizontalRepeat !== null && isNaN(parseFloat(values.horizontalRepeat.toString())) || values.horizontalRepeat !== undefined && values.horizontalRepeat !== null && parseFloat(values.horizontalRepeat.toString()) < 0)) {
        errors.push({
            tabIndex,
            name: prefixName + "values.horizontalRepeat",
            message:
                "Main fabric horizontal repeat must be a value of 0 or more.",
        });
    }
    // this will be a warning message
    // if (draperyOrderDesign.category === 2 && values.fabricWidth! > 60) { errors.push("Please confirm the desired Widths per Panel for this extra wide fabric."); }

    errors = validateMainFabricDirectionOfUse(
        tabIndex,
        errors,
        prefixName,
        mainFabric,
        draperyOrderDesign
    );

    return errors;
};

export const validateDraperyOrderLiningsFabric = (
    name: string,
    targetArrayIndex: number,
    draperyOrderLiningsFabric: DraperyOrderLiningsFabric | undefined,
    liningsPatternItems?: FabricPatternItem[],
    workroomSetting?: WorkroomSetting | null,
    orderType?: string,
): ValidationErrorInfo[] => {
    let errors: ValidationErrorInfo[] = [];
    const isValidLiningFabric = liningsPatternItems!.find(
        (fab) => fab.name === draperyOrderLiningsFabric!.pattern
    );

    if (
        draperyOrderLiningsFabric &&
        !draperyOrderLiningsFabric.pattern &&
        draperyOrderLiningsFabric.orderOrigin === 1
    ) {
        errors.push({
            tabIndex: 2,
            name: `liningsList.${targetArrayIndex}.draperyOrderLiningsFabric.pattern`,
            message: `Please specify the ${
                name.charAt(0).toUpperCase() + name.slice(1)
                } ${orderType === 'roman-shade-order' ? 'name' : 'Fabric Pattern'}.`,
        });
    }

    // if (
    //     draperyOrderLiningsFabric &&
    //     !draperyOrderLiningsFabric.supplierId &&
    //     !draperyOrderLiningsFabric.supplierName &&
    //     draperyOrderLiningsFabric.orderOrigin === 1
    // ) {
    //     errors.push({
    //         tabIndex: 2,
    //         name: `liningsList.${targetArrayIndex}.draperyOrderLiningsFabric.supplierId`,
    //         message: `Please specify the ${
    //             name.charAt(0).toUpperCase() + name.slice(1)
    //         } Fabric Supplier.`,
    //     });
    // }

    if (
        ((draperyOrderLiningsFabric &&
            draperyOrderLiningsFabric.orderOrigin === 1) ||
            (workroomSetting &&
                workroomSetting.fabricPurchaser === "Workroom" &&
                draperyOrderLiningsFabric &&
                draperyOrderLiningsFabric.orderOrigin === -1)) &&
        !isValidLiningFabric &&
        draperyOrderLiningsFabric.pattern
    ) {
        errors.push({
            tabIndex: 2,
            name: `liningsList.${targetArrayIndex}.draperyOrderLiningsFabric.pattern`,
            message:
                targetArrayIndex === 0
                    ? "Lining Fabric and its pricing must be added to My Shop if workroom is ordering."
                    : "Interlining Fabric and its pricing must be added to My Shop if workroom is ordering.",
        });
    }

    if (isValidLiningFabric && !isValidLiningFabric.isActive) {
        errors.push({
            tabIndex: 2,
            name: `liningsList.${targetArrayIndex}.draperyOrderLiningsFabric.pattern` + '.openModalForPattern',
            message: `${isValidLiningFabric.name} is marked as inactive in My Shop and must be made active to use on orders.`
        });
    }

    if (
        draperyOrderLiningsFabric &&
        draperyOrderLiningsFabric.orderOrigin < 0 &&
        workroomSetting &&
        workroomSetting.liningsPurchaser !== "Workroom" &&
        workroomSetting.liningsPurchaser !== "Installer"
    ) {
        errors.push({
            tabIndex: 2,
            name: `liningsList.${targetArrayIndex}.draperyOrderLiningsFabric.orderOrigin`,
            message: `Please specify who will order the ${
                name.charAt(0).toUpperCase() + name.slice(1)
            } Fabric.`,
        });
    }

    if (
        draperyOrderLiningsFabric &&
        draperyOrderLiningsFabric.orderOrigin < 0 &&
        orderType === 'roman-shade-order'
    ) {
        errors.push({
            tabIndex: 2,
            name: `liningsList.${targetArrayIndex}.draperyOrderLiningsFabric.orderOrigin`,
            message: `Please specify who will order the ${
                name.charAt(0).toUpperCase() + name.slice(1)
            } Fabric.`,
        });
    }

    if (orderType !== 'roman-shade-order') {
        if (
            ((draperyOrderLiningsFabric &&
                draperyOrderLiningsFabric.orderOrigin === 1) ||
                (workroomSetting &&
                    workroomSetting.fabricPurchaser === "Workroom" &&
                    draperyOrderLiningsFabric &&
                    draperyOrderLiningsFabric.orderOrigin === -1)) &&
            isValidLiningFabric &&
            isValidLiningFabric.cost === null &&
            isValidLiningFabric.retailPricingValue === null &&
            isValidLiningFabric.tradePricingValue === null
        ) {
            errors.push({
                tabIndex: 2,
                name: `liningsList.${targetArrayIndex}.draperyOrderLiningsFabric.pattern`,
                message:
                    targetArrayIndex === 0
                        ? "Lining Fabric pricing is required if workroom is ordering."
                        : "Interlining Fabric pricing is required if workroom is ordering.",
            });
        }
    }

    if (
        !draperyOrderLiningsFabric ||
        orderType && (orderType === 'drapery-order' || orderType === 'roman-shade-order') && (!draperyOrderLiningsFabric ||
        !draperyOrderLiningsFabric.values ||
        Object.keys(draperyOrderLiningsFabric.values).length === 0 ||
        draperyOrderLiningsFabric.values.fabricWidth == null ||
        draperyOrderLiningsFabric.values.fabricWidth < 1)
    ) {
        errors.push({
            tabIndex: 2,
            name: `liningsList.${targetArrayIndex}.draperyOrderLiningsFabric.values.fabricWidth`,
            message: `${name} Fabric width must be a value of ${orderType === 'roman-shade-order' ? '1 or more' : 'at least 1'}.`,
        });
    } else if (
        orderType && (orderType === 'drapery-order' || orderType === 'roman-shade-order') && (draperyOrderLiningsFabric && draperyOrderLiningsFabric.values && Object.keys(draperyOrderLiningsFabric.values).length < 1 || draperyOrderLiningsFabric && draperyOrderLiningsFabric.values && draperyOrderLiningsFabric.values.fabricWidth &&
        (typeof parseFloat(draperyOrderLiningsFabric.values.fabricWidth.toString()) !== "number" || draperyOrderLiningsFabric.values.fabricWidth === undefined || draperyOrderLiningsFabric.values.fabricWidth !== undefined && draperyOrderLiningsFabric.values.fabricWidth !== null &&
            parseFloat(draperyOrderLiningsFabric.values.fabricWidth.toString()) < 1 ||
            !draperyOrderLiningsFabric.values.fabricWidth))
    ) {
        errors.push({
            tabIndex: 2,
            name: `liningsList.${targetArrayIndex}.draperyOrderLiningsFabric.values.fabricWidth`,
            message: `${name} Fabric width must be a value of at least 1.`,
        });
    }
    return errors;
};

const validateMultipleFabricTypes = (
    tabIndex: number,
    errors: ValidationErrorInfo[],
    prefixName: string,
    fabricNames: string[],
    fabric: DraperyModule.DraperyOrderFabric & { supplierName?: string },
    orderType?: string,
) => {
    const { type: fabricType, values, applicationMethod } = fabric;
    const valFabricWidth = values.fabricWidth;

    if (
        orderType && (orderType === 'drapery-order' || orderType === 'roman-shade-order') && ([2, 3, 5, 7].includes(fabricType) &&
        (valFabricWidth === undefined || valFabricWidth === null || valFabricWidth !== undefined && valFabricWidth !== null && parseFloat(valFabricWidth.toString()) < 1))
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "values.fabricWidth",
            message: `${fabricNames[fabricType]} fabric width must be a value of at least 1".`,
        });
    }
    if (
        fabricType === 8 &&
        values.trimWidth !== undefined &&
        values.trimWidth !== null &&
        parseFloat(values.trimWidth.toString()) < 1
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "values.trimWidth",
            message: `${fabricNames[fabricType]} trim width must be a value of at least 1".`,
        });
    }
    if (fabricType === 2 || fabricType === 7) {
        if (orderType && (orderType === 'drapery-order' || orderType === 'roman-shade-order') && (values.verticalRepeat === undefined || values.verticalRepeat === null || values.verticalRepeat !== undefined && values.verticalRepeat !== null && isNaN(parseFloat(values.verticalRepeat.toString())) || values.verticalRepeat !== undefined && values.verticalRepeat !== null && parseFloat(values.verticalRepeat.toString()) < 0)) {
            errors.push({
                tabIndex,
                name: prefixName + "values.verticalRepeat",
                message: `${fabricNames[fabricType]} fabric vertical repeat must be a value of 0 or more.`,
            });
        }
        if (
            orderType && (orderType === 'drapery-order' || orderType === 'roman-shade-order') && (values.horizontalRepeat === undefined || values.horizontalRepeat === null || values.horizontalRepeat !== undefined && values.horizontalRepeat !== null && isNaN(parseFloat(values.horizontalRepeat.toString())) || values.horizontalRepeat !== undefined && values.horizontalRepeat !== null &&
            parseFloat(values.horizontalRepeat.toString()) < 0)
        ) {
            errors.push({
                tabIndex,
                name: prefixName + "values.horizontalRepeat",
                message: `${fabricNames[fabricType]} fabric horizontal repeat must be a value of 0 or more.`,
            });
        }
    }
    if (fabricType === 7 || fabricType === 8) {
        if (applicationMethod === undefined) {
            errors.push({
                tabIndex,
                name: prefixName + "applicationMethod",
                message: `${fabricNames[fabricType]} fabric application method must be specified.`,
            });
        }
    }

    return errors;
};

export const validateDraperyOrderFabric = (
    targetArray: string,
    targetArrayIndex: number,
    embellishment: DraperyOrderDesignEmbellishment,
    fabric?: DraperyModule.DraperyOrderFabric & { supplierName?: string },
    fabricsPatternItems?: FabricPatternItem[] | undefined,
    workroomSetting?: WorkroomSetting | null,
    list?: DraperyOrderDesignEmbellishment[],
    orderType?: string,
): ValidationErrorInfo[] => {
    if (!fabric) {
        return [];
    }
    const isValidFabric = fabricsPatternItems!.find(
        (fab) => fab.name === fabric.pattern
    );
    const prefixName = `${targetArray}.${targetArrayIndex}.draperyOrderFabric.`;
    const tabIndex = 2;
    let errors: ValidationErrorInfo[] = [];
    const values = fabric.values;
    const fabricNames: string[] = [
        "",
        "Main",
        "Banding Fabric",
        "Welt Cording",
        "Cording (Pre-Made)",
        "Flange Fabric",
        "Flange (Pre-Made)",
        "Trim Fabric",
        "Trim (Pre-Made)",
        "Other",
    ];
    const fabricType = fabric.type;

    if (fabricType < 2) {
        return errors;
    }

    if (fabric.pattern === "" && fabric.orderOrigin === 1) {
        errors.push({
            tabIndex,
            name: prefixName + "pattern",
            message: `Please specify the ${fabricType === 9 ? `${embellishment.name} Fabric` : fabricNames[fabricType]} pattern.`,
        });
    }

    if ((isValidFabric && !isValidFabric.isActive)) {
        errors.push({
            tabIndex,
            name: prefixName + "pattern" + '.openModalForPattern',
            message:
                `${isValidFabric.name} is marked as inactive in My Shop and must be made active to use on orders.`
        });
    }
    
    // if (
    //     !fabric.supplierId &&
    //     !fabric.supplierName &&
    //     fabric.orderOrigin === 1
    // ) {
    //     errors.push({
    //         tabIndex,
    //         name: prefixName + "supplierId",
    //         message: `Please specify the ${fabricNames[fabricType]} Supplier.`,
    //     });
    // }

    if (
        fabric.orderOrigin === -1 &&
        workroomSetting &&
        workroomSetting.embellishmentsPurchaser !== "Workroom" &&
        workroomSetting.embellishmentsPurchaser !== "Installer"
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "orderOrigin",
            message: `Please specify who will order the ${fabricType === 9 ? embellishment.name : fabricNames[fabricType]} Fabric.`,
        });
    }

    if (fabric.orderOrigin === -1 && orderType === 'roman-shade-order') {
        errors.push({
            tabIndex,
            name: prefixName + "orderOrigin",
            message: `Please specify who will order the ${fabricType === 9 ? embellishment.name : fabricNames[fabricType]} Fabric.`,
        });
    }
    if (
        (fabric.orderOrigin === 1 ||
            (workroomSetting &&
                workroomSetting.embellishmentsPurchaser === "Workroom" &&
                fabric.orderOrigin === -1)) &&
        !isValidFabric &&
        fabric.pattern
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "pattern",
            message: `${fabricNames[fabricType]} ${
                list && list.length > 1 ? targetArrayIndex + 1 : ""
            } and its pricing must be added to My Shop if workroom is ordering.`,
        });
    }

    if (orderType !== 'roman-shade-order') {
        if (
            (fabric.orderOrigin === 1 ||
                (workroomSetting &&
                    workroomSetting.fabricPurchaser === "Workroom" &&
                    fabric.orderOrigin === 1)) &&
            isValidFabric &&
            isValidFabric.cost === null &&
            isValidFabric.retailPricingValue === null &&
            isValidFabric.tradePricingValue === null
        ) {
            errors.push({
                tabIndex,
                name: prefixName + "pattern",
                message: `${fabricNames[fabricType]} pricing is required if workroom is ordering.`,
            });
        }
    }

    errors = validateMultipleFabricTypes(
        tabIndex,
        errors,
        prefixName,
        fabricNames,
        fabric,
        orderType
    );

    if (fabricType === 3) {
        if (orderType && orderType === 'drapery-order' && !values.fabricWidth) {
            errors.push({
                tabIndex,
                name: prefixName + "values.fabricWidth",
                message: 'Welt fabric must be a value of at least 1"',
            });
        }
        // else if (values.fabricWidth < 54) {
        //     errors.push({tabIndex, name: prefixName + "values.fabricWidth", message: "QUIPA calculations account for welt cut on the bias from a 54\" wide fabric or larger. Please verify the fabric yardage required for welt fabrics less than 54\" wide."});
        // }
    }

    if (
        orderType && orderType === 'drapery-order' && (fabricType === 9 &&
            (fabric.yardsRecommended === undefined || fabric.yardsRecommended === null || (fabric.yardsRecommended !== null && fabric.yardsRecommended < 0)))
    ) {
        errors.push({
            tabIndex,
            name: prefixName + "yardsRecommended",
            message: `Yardage needed for ${embellishment.name} Fabric must be 0 or greater.`,
        });
    }
    return errors;
};

export const validateDraperyOrderMainFabricConfirmations = (
    draperyOrder: DraperyModule.DraperyOrder,
    mainFabric: DraperyModule.DraperyOrderFabric & { supplierName?: string },
    draperyOrderDesign: DraperyModule.DraperyOrderDesign
): ValidationConfirmationInfo[] => {
    if (!mainFabric) {
        return [];
    }

    const prefixName = "mainFabric.";
    const tabIndex = 2;
    const confirmations: ValidationConfirmationInfo[] = [];

    const { values, directionOfUse } = mainFabric;

    if (
        draperyOrderDesign.category === 1 &&
        values.fabricWidth &&
        parseFloat(values.fabricWidth!.toString()) > 54
    ) {
        const message =
            directionOfUse === 1
                ? "The Main Fabric is extra wide. Fabric yardage will be calculated using the fabric’s ACTUAL width (instead of a standard 54” width). On the Design tab, confirm the input for panel widths is based on the fabric’s ACTUAL width and check the box to proceed."
                : "When railroading fabric with stationary panels, fabric yardage will be calculated based on a standard 54” width. Please confirm the inputs for panel widths on the Design tab is based on 54” widths, then check the box to proceed.";
        // tslint:disable-next-line:max-line-length
        confirmations.push({
            tabIndex,
            name: prefixName + "values.fabricWidth",
            value: "draperyOrder.userConfirmation.allowExtraWideMainFabric",
            message,
            checked:
                draperyOrder.userConfirmation.allowExtraWideMainFabric || false,
        });
    }
    return confirmations;
};

const defaultBandingFabric = (
    item: string,
    orderOrigin: number
): DraperyOrderFabric => ({
    id: 0,
    draperyOrderId: 0,
    name: "",
    type: 2,
    supplierId: 0,
    pattern: "",
    imageId: null,
    orderOrigin: orderOrigin,
    isSpecialtyFabricHandling: false,
    isDropRepeat: false,
    values: {},
    supplierName: "",
    isActive: undefined, 
    draperyOrderSummaryComponent: defaultDraperyOrderSummaryComponent(item),
});

const defaultWeltCordingFabric = (
    item: string,
    orderOrigin: number
): DraperyOrderFabric => ({
    id: 0,
    draperyOrderId: 0,
    name: "",
    type: 3,
    supplierId: 0,
    pattern: "",
    imageId: null,
    orderOrigin: orderOrigin,
    isSpecialtyFabricHandling: false,
    cutWidthFabricStrips: 2,
    values: {},
    supplierName: "",
    draperyOrderSummaryComponent: defaultDraperyOrderSummaryComponent(item),
});

const defaultCordingFabric = (
    item: string,
    orderOrigin: number
): DraperyOrderFabric => ({
    id: 0,
    draperyOrderId: 0,
    name: "",
    type: 4,
    supplierId: 0,
    pattern: "",
    imageId: null,
    orderOrigin: orderOrigin,
    isSpecialtyFabricHandling: false,
    values: {},
    supplierName: "",
    draperyOrderSummaryComponent: defaultDraperyOrderSummaryComponent(item),
});

const defaultFlangeFabric = (
    item: string,
    orderOrigin: number
): DraperyOrderFabric => ({
    id: 0,
    draperyOrderId: 0,
    name: "",
    type: 5,
    imageId: null,
    orderOrigin: orderOrigin,
    supplierId: 0,
    pattern: "",
    isSpecialtyFabricHandling: false,
    values: {},
    supplierName: "",
    draperyOrderSummaryComponent: defaultDraperyOrderSummaryComponent(item),
});

const defaultPreMadeFlangeFabric = (
    item: string,
    orderOrigin: number
): DraperyOrderFabric => ({
    id: 0,
    draperyOrderId: 0,
    name: "",
    type: 6,
    imageId: null,
    orderOrigin: orderOrigin,
    supplierId: 0,
    pattern: "",
    isSpecialtyFabricHandling: false,
    values: {},
    supplierName: "",
    draperyOrderSummaryComponent: defaultDraperyOrderSummaryComponent(item),
});

const defaultTrimFabric = (
    item: string,
    orderOrigin: number
): DraperyOrderFabric => ({
    id: 0,
    draperyOrderId: 0,
    name: "",
    type: 7,
    imageId: null,
    orderOrigin: orderOrigin,
    supplierId: 0,
    pattern: "",
    isSpecialtyFabricHandling: false,
    isDropRepeat: false,
    applicationMethod: 0,
    values: {},
    supplierName: "",
    isActive: undefined, 
    draperyOrderSummaryComponent: defaultDraperyOrderSummaryComponent(item),
});

const defaultPreMadeTrimFabric = (
    item: string,
    orderOrigin: number
): DraperyOrderFabric => ({
    id: 0,
    draperyOrderId: 0,
    name: "",
    type: 8,
    imageId: null,
    orderOrigin: orderOrigin,
    supplierId: 0,
    pattern: "",
    isSpecialtyFabricHandling: false,
    applicationMethod: 0,
    values: {},
    supplierName: "",
    draperyOrderSummaryComponent: defaultDraperyOrderSummaryComponent(item),
});

const defaultOtherEmbellishmentFabric = (
    item: string,
    orderOrigin: number
): DraperyOrderFabric => ({
    id: 0,
    draperyOrderId: 0,
    name: "",
    type: 9,
    supplierId: 0,
    pattern: "",
    imageId: null,
    orderOrigin: orderOrigin,
    isSpecialtyFabricHandling: false,
    isDropRepeat: false,
    yardsNeeded: undefined,
    values: {},
    supplierName: "",
    isActive: undefined, 
    draperyOrderSummaryComponent: defaultDraperyOrderSummaryComponent(item),
});

export const defaultLiningsFabric = (
    itemName: string,
    orderOrigin?: number
): DraperyOrderFabric => ({
    id: 0,
    draperyOrderId: 0,
    name: itemName,
    type: 20,
    supplierId: 0,
    pattern: "",
    imageId: null,
    orderOrigin: orderOrigin ? orderOrigin : -1,
    isSpecialtyFabricHandling: false,
    values: {},
    supplierName: "",
    draperyOrderSummaryComponent: defaultDraperyOrderSummaryComponent(itemName),
});

// duplicate
export const duplicateFabric = (fabric: DraperyOrderFabric) => {
    return {
        ...fabric,
        id: 0,
        draperyOrderId: 0,
        draperyOrderSummaryComponent: defaultDraperyOrderSummaryComponent(
            fabric.draperyOrderSummaryComponent.item
        ),
    };
};

interface FabricsTabProps {
    accountId: number;
    bandings: DraperyOrderDesignEmbellishment[];
    cordings: DraperyOrderDesignEmbellishment[];
    flanges: DraperyOrderDesignEmbellishment[];
    trims: DraperyOrderDesignEmbellishment[];
    draperyOrder: DraperyModule.DraperyOrder & { clientName: string };
    draperyOrderDesign: DraperyModule.DraperyOrderDesign;
    othersEmbellishments: DraperyOrderDesignEmbellishment[];
    mainFabric: DraperyOrderFabric;
    embellishmentsList: EmbellishmentCheckInfo[];
    liningsList: DraperyModule.DraperyOrderDesignLining[];
    disabled: boolean;
    disabledLeftover: boolean;
    highlightedSection: string | null | undefined;
    fabricsPatternItems: any;
    liningsPatternItems: any;
    workroomSetting: any;

    onSupplierSearch: SearchSelectProps["onSearch"];
    onAddSupplierByName: SearchSelectProps["onAddByName"];
    openSupplierModalClick(pathId: string, pathName: string): void;
    openFabricsPatternModalClick(pathId: string, pathName: string): void;
    openEditFabricsPatternModalClick(pathName: string): void;
    openImageModal(name: string, option: "add" | "delete"): void;

    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    changeTrimValue(name: string, value: string): void;
    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    handleSelectListChange(value: string | number, name?: string): void;
    handleSearchSelectChange(
        id: number,
        name: string,
        pathId: string,
        pathName: string,
        itemId?: number
    ): void;
    handleSearchSelectClear(
        pathId: string,
        pathName: string,
        key?: number
    ): void;
    onFabricsPatternSearch: SearchSelectProps["onSearch"];
    onFabricsLiningPatternSearch: SearchSelectProps["onSearch"];
    orderOriginStateEmb?: number;
    updateStateByName?(
        name: string,
        value: string | number | boolean | number[] | undefined | object,
        isSaving?: boolean
    ): void;

    clearHighlightSection?(name: any): void;
    workOrderEditMode: boolean;
    isCopy: boolean;
    orderType: string | undefined;
    romanShadeProps?: any;
}

interface State {
    isAddSupplierModalOpen: boolean;
    isDisabled: boolean;
    updatedOrderFabric: {};
    orderOriginState: number;
    orderOriginStateEmb: number;
    orderOriginStateLining: number;
}

class FabricsTab extends PureComponent<FabricsTabProps, State> {
    public constructor(props: FabricsTabProps) {
        super(props);
        this.state = {
            isAddSupplierModalOpen: false,
            isDisabled: false,
            updatedOrderFabric: {},
            orderOriginState: -1,
            orderOriginStateEmb: -1,
            orderOriginStateLining: -1,
        };
    }
    componentDidUpdate() {
        this.getDefaultValue(this.props.orderType ? this.props.orderType : '');
    }
    public render() {
        const {
            mainFabric,
            bandings,
            cordings,
            flanges,
            trims,
            othersEmbellishments,
            embellishmentsList,
            liningsList,
            draperyOrder,
            draperyOrderDesign,
            disabledLeftover,
            disabled,
            highlightedSection,
            fabricsPatternItems,
            liningsPatternItems,
            workroomSetting,
            updateStateByName,
            workOrderEditMode,
            orderType,
            romanShadeProps
        } = this.props;

        return (
            <>
                <FabricsMainComponent
                    mainFabric={mainFabric}
                    disabled={workOrderEditMode ? false : disabled}
                    highlightedSection={highlightedSection}
                    onSupplierSearch={this.props.onSupplierSearch}
                    openSupplierModalClick={this.props.openSupplierModalClick}
                    openFabricsPatternModalClick={
                        this.props.openFabricsPatternModalClick
                    }
                    openEditFabricsPatternModalClick={
                        this.props.openEditFabricsPatternModalClick
                    }
                    draperyOrder={draperyOrder}
                    draperyOrderDesign={draperyOrderDesign}
                    disabledLeftover={disabledLeftover}
                    openImageModal={this.props.openImageModal}
                    onAddSupplierByName={this.props.onAddSupplierByName}
                    handleInputChangeForNumber={
                        this.props.handleInputChangeForNumber
                    }
                    handleInputChangeForString={
                        this.props.handleInputChangeForString
                    }
                    handleInputChangeForBoolean={
                        this.props.handleInputChangeForBoolean
                    }
                    handleSearchSelectChange={
                        this.props.handleSearchSelectChange
                    }
                    handleSearchSelectClear={this.props.handleSearchSelectClear}
                    onFabricsPatternSearch={this.props.onFabricsPatternSearch}
                    fabricsPatternItems={fabricsPatternItems}
                    workroomSetting={workroomSetting}
                    orderOriginState={this.state.orderOriginState}
                    updateStateByName={updateStateByName}
                    clearHighlightSection={this.props.clearHighlightSection}
                    workOrderEditMode={workOrderEditMode}
                    isCopy={this.props.isCopy}
                    orderType={orderType}
                />
                {embellishmentsList[0].checked &&
                    bandings.length !== 0 &&
                    bandings.map((el, idx) => (
                        <>
                            <FabricsComponent
                                key={idx}
                                name={`BANDING FABRIC ${
                                    bandings.length > 1 ? idx + 1 : ""
                                }`}
                                type={2}
                                targetArray="bandings"
                                targetArrayIndex={idx}
                                draperyOrderDesignEmbellishment={el}
                                defaultDraperyOrderFabric={defaultBandingFabric(
                                    `BANDING FABRIC ${
                                        bandings.length > 1 ? idx + 1 : ""
                                    }`,
                                    this.state.orderOriginStateEmb
                                )}
                                showRepeatValues
                                showFabricWidth
                                disabled={workOrderEditMode ? false : disabled}
                                highlightedSection={highlightedSection}
                                onSearch={this.props.onSupplierSearch}
                                openSupplierModalClick={
                                    this.props.openSupplierModalClick
                                }
                                openFabricsPatternModalClick={
                                    this.props.openFabricsPatternModalClick
                                }
                                openImageModal={this.props.openImageModal}
                                onAddSupplierByName={
                                    this.props.onAddSupplierByName
                                }
                                handleInputChangeForNumber={
                                    this.props.handleInputChangeForNumber
                                }
                                handleInputChangeForString={
                                    this.props.handleInputChangeForString
                                }
                                handleInputChangeForBoolean={
                                    this.props.handleInputChangeForBoolean
                                }
                                handleSelectListChange={
                                    this.props.handleSelectListChange
                                }
                                handleSearchSelectChange={
                                    this.props.handleSearchSelectChange
                                }
                                handleSearchSelectClear={
                                    this.props.handleSearchSelectClear
                                }
                                onFabricsPatternSearch={
                                    this.props.onFabricsPatternSearch
                                }
                                fabricsPatternItems={fabricsPatternItems}
                                workroomSetting={workroomSetting}
                                orderOriginStateEmb={
                                    this.state.orderOriginStateEmb
                                }
                                openEditFabricsPatternModalClick={
                                    this.props.openEditFabricsPatternModalClick
                                }
                                workOrderEditMode={workOrderEditMode}
                                draperyOrder={draperyOrder}
                                isCopy={this.props.isCopy}
                            />
                        </>
                    ))}
                {embellishmentsList[1].checked &&
                    cordings.length !== 0 &&
                    cordings.map((el, idx) => {
                        if (el.subType === 1) {
                            return (
                                <FabricsComponent
                                    key={idx}
                                    targetArray="cordings"
                                    targetArrayIndex={idx}
                                    name={`WELT CORDING FABRIC ${
                                        cordings.length > 1 ? idx + 1 : ""
                                    }`}
                                    defaultDraperyOrderFabric={defaultWeltCordingFabric(
                                        `WELT CORDING FABRIC ${
                                            cordings.length > 1 ? idx + 1 : ""
                                        }`,
                                        this.state.orderOriginStateEmb
                                    )}
                                    type={3}
                                    draperyOrderDesignEmbellishment={el}
                                    showFabricWidth
                                    showCutWidthFabricStrips
                                    disabled={
                                        workOrderEditMode ? false : disabled
                                    }
                                    highlightedSection={highlightedSection}
                                    onSearch={this.props.onSupplierSearch}
                                    openSupplierModalClick={
                                        this.props.openSupplierModalClick
                                    }
                                    openFabricsPatternModalClick={
                                        this.props.openFabricsPatternModalClick
                                    }
                                    openImageModal={this.props.openImageModal}
                                    onAddSupplierByName={
                                        this.props.onAddSupplierByName
                                    }
                                    handleInputChangeForNumber={
                                        this.props.handleInputChangeForNumber
                                    }
                                    handleInputChangeForString={
                                        this.props.handleInputChangeForString
                                    }
                                    handleInputChangeForBoolean={
                                        this.props.handleInputChangeForBoolean
                                    }
                                    handleSelectListChange={
                                        this.props.handleSelectListChange
                                    }
                                    handleSearchSelectChange={
                                        this.props.handleSearchSelectChange
                                    }
                                    handleSearchSelectClear={
                                        this.props.handleSearchSelectClear
                                    }
                                    onFabricsPatternSearch={
                                        this.props.onFabricsPatternSearch
                                    }
                                    fabricsPatternItems={fabricsPatternItems}
                                    workroomSetting={workroomSetting}
                                    orderOriginStateEmb={
                                        this.state.orderOriginStateEmb
                                    }
                                    openEditFabricsPatternModalClick={
                                        this.props
                                            .openEditFabricsPatternModalClick
                                    }
                                    workOrderEditMode={workOrderEditMode}
                                    draperyOrder={draperyOrder}
                                    isCopy={this.props.isCopy}
                                />
                            );
                        } else if (el.subType === 0) {
                            return (
                                <FabricsComponent
                                    key={idx}
                                    targetArray="cordings"
                                    targetArrayIndex={idx}
                                    name={`CORDING (PRE-MADE) ${
                                        cordings.length > 1 ? idx + 1 : ""
                                    }`}
                                    defaultDraperyOrderFabric={defaultCordingFabric(
                                        `CORDING (PRE-MADE) ${
                                            cordings.length > 1 ? idx + 1 : ""
                                        }`,
                                        this.state.orderOriginStateEmb
                                    )}
                                    highlightedSection={highlightedSection}
                                    type={4}
                                    draperyOrderDesignEmbellishment={el}
                                    disabled={
                                        workOrderEditMode ? false : disabled
                                    }
                                    onSearch={this.props.onSupplierSearch}
                                    openSupplierModalClick={
                                        this.props.openSupplierModalClick
                                    }
                                    openFabricsPatternModalClick={
                                        this.props.openFabricsPatternModalClick
                                    }
                                    openImageModal={this.props.openImageModal}
                                    onAddSupplierByName={
                                        this.props.onAddSupplierByName
                                    }
                                    handleInputChangeForNumber={
                                        this.props.handleInputChangeForNumber
                                    }
                                    handleInputChangeForString={
                                        this.props.handleInputChangeForString
                                    }
                                    handleInputChangeForBoolean={
                                        this.props.handleInputChangeForBoolean
                                    }
                                    handleSelectListChange={
                                        this.props.handleSelectListChange
                                    }
                                    handleSearchSelectChange={
                                        this.props.handleSearchSelectChange
                                    }
                                    handleSearchSelectClear={
                                        this.props.handleSearchSelectClear
                                    }
                                    onFabricsPatternSearch={
                                        this.props.onFabricsPatternSearch
                                    }
                                    fabricsPatternItems={fabricsPatternItems}
                                    workroomSetting={workroomSetting}
                                    orderOriginStateEmb={
                                        this.state.orderOriginStateEmb
                                    }
                                    openEditFabricsPatternModalClick={
                                        this.props
                                            .openEditFabricsPatternModalClick
                                    }
                                    workOrderEditMode={workOrderEditMode}
                                    draperyOrder={draperyOrder}
                                    isCopy={this.props.isCopy}
                                />
                            );
                        }
                    })}
                {embellishmentsList[2].checked &&
                    flanges.length !== 0 &&
                    flanges.map((el, idx) => {
                        if (el.subType === 1) {
                            return (
                                <FabricsComponent
                                    key={idx}
                                    targetArray="flanges"
                                    targetArrayIndex={idx}
                                    name={`FLANGE FABRIC ${
                                        flanges.length > 1 ? idx + 1 : ""
                                    }`}
                                    defaultDraperyOrderFabric={defaultFlangeFabric(
                                        `FLANGE FABRIC ${
                                            flanges.length > 1 ? idx + 1 : ""
                                        }`,
                                        this.state.orderOriginStateEmb
                                    )}
                                    type={5}
                                    draperyOrderDesignEmbellishment={el}
                                    showFabricWidth
                                    disabled={
                                        workOrderEditMode ? false : disabled
                                    }
                                    highlightedSection={highlightedSection}
                                    onSearch={this.props.onSupplierSearch}
                                    openSupplierModalClick={
                                        this.props.openSupplierModalClick
                                    }
                                    openImageModal={this.props.openImageModal}
                                    onAddSupplierByName={
                                        this.props.onAddSupplierByName
                                    }
                                    openFabricsPatternModalClick={
                                        this.props.openFabricsPatternModalClick
                                    }
                                    handleInputChangeForNumber={
                                        this.props.handleInputChangeForNumber
                                    }
                                    handleInputChangeForString={
                                        this.props.handleInputChangeForString
                                    }
                                    handleInputChangeForBoolean={
                                        this.props.handleInputChangeForBoolean
                                    }
                                    handleSelectListChange={
                                        this.props.handleSelectListChange
                                    }
                                    handleSearchSelectChange={
                                        this.props.handleSearchSelectChange
                                    }
                                    handleSearchSelectClear={
                                        this.props.handleSearchSelectClear
                                    }
                                    onFabricsPatternSearch={
                                        this.props.onFabricsPatternSearch
                                    }
                                    fabricsPatternItems={fabricsPatternItems}
                                    workroomSetting={workroomSetting}
                                    orderOriginStateEmb={
                                        this.state.orderOriginStateEmb
                                    }
                                    openEditFabricsPatternModalClick={
                                        this.props
                                            .openEditFabricsPatternModalClick
                                    }
                                    workOrderEditMode={workOrderEditMode}
                                    draperyOrder={draperyOrder}
                                    isCopy={this.props.isCopy}
                                />
                            );
                        } else if (el.subType === 0) {
                            return (
                                <FabricsComponent
                                    key={idx}
                                    targetArray="flanges"
                                    targetArrayIndex={idx}
                                    name={`FLANGE (PRE-MADE) ${
                                        flanges.length > 1 ? idx + 1 : ""
                                    }`}
                                    defaultDraperyOrderFabric={defaultPreMadeFlangeFabric(
                                        `FLANGE (PRE-MADE) ${
                                            flanges.length > 1 ? idx + 1 : ""
                                        }`,
                                        this.state.orderOriginStateEmb
                                    )}
                                    type={6}
                                    draperyOrderDesignEmbellishment={el}
                                    disabled={
                                        workOrderEditMode ? false : disabled
                                    }
                                    highlightedSection={highlightedSection}
                                    onSearch={this.props.onSupplierSearch}
                                    openSupplierModalClick={
                                        this.props.openSupplierModalClick
                                    }
                                    openFabricsPatternModalClick={
                                        this.props.openFabricsPatternModalClick
                                    }
                                    openImageModal={this.props.openImageModal}
                                    onAddSupplierByName={
                                        this.props.onAddSupplierByName
                                    }
                                    handleInputChangeForNumber={
                                        this.props.handleInputChangeForNumber
                                    }
                                    handleInputChangeForString={
                                        this.props.handleInputChangeForString
                                    }
                                    handleInputChangeForBoolean={
                                        this.props.handleInputChangeForBoolean
                                    }
                                    handleSelectListChange={
                                        this.props.handleSelectListChange
                                    }
                                    handleSearchSelectChange={
                                        this.props.handleSearchSelectChange
                                    }
                                    handleSearchSelectClear={
                                        this.props.handleSearchSelectClear
                                    }
                                    onFabricsPatternSearch={
                                        this.props.onFabricsPatternSearch
                                    }
                                    fabricsPatternItems={fabricsPatternItems}
                                    workroomSetting={workroomSetting}
                                    orderOriginStateEmb={
                                        this.state.orderOriginStateEmb
                                    }
                                    openEditFabricsPatternModalClick={
                                        this.props
                                            .openEditFabricsPatternModalClick
                                    }
                                    workOrderEditMode={workOrderEditMode}
                                    draperyOrder={draperyOrder}
                                    isCopy={this.props.isCopy}
                                />
                            );
                        }
                    })}
                {embellishmentsList[3].checked &&
                    trims.length !== 0 &&
                    trims.map((el, idx) => {
                        if (el.subType === 0) {
                            return (
                                <FabricsComponent
                                    key={idx}
                                    targetArray="trims"
                                    targetArrayIndex={idx}
                                    name={`TRIM (PRE-MADE) ${
                                        trims.length > 1 ? idx + 1 : ""
                                    }`}
                                    defaultDraperyOrderFabric={defaultPreMadeTrimFabric(
                                        `TRIM (PRE-MADE) ${
                                            trims.length > 1 ? idx + 1 : ""
                                        }`,
                                        this.state.orderOriginStateEmb
                                    )}
                                    type={8}
                                    draperyOrderDesignEmbellishment={el}
                                    showApplicationMethod
                                    showTrimWidth
                                    disabled={
                                        workOrderEditMode ? false : disabled
                                    }
                                    highlightedSection={highlightedSection}
                                    onSearch={this.props.onSupplierSearch}
                                    openSupplierModalClick={
                                        this.props.openSupplierModalClick
                                    }
                                    openImageModal={this.props.openImageModal}
                                    onAddSupplierByName={
                                        this.props.onAddSupplierByName
                                    }
                                    openFabricsPatternModalClick={
                                        this.props.openFabricsPatternModalClick
                                    }
                                    handleInputChangeForNumber={
                                        this.props.handleInputChangeForNumber
                                    }
                                    handleInputChangeForString={
                                        this.props.handleInputChangeForString
                                    }
                                    handleInputChangeForBoolean={
                                        this.props.handleInputChangeForBoolean
                                    }
                                    handleSelectListChange={
                                        this.props.handleSelectListChange
                                    }
                                    handleSearchSelectChange={
                                        this.props.handleSearchSelectChange
                                    }
                                    handleSearchSelectClear={
                                        this.props.handleSearchSelectClear
                                    }
                                    onFabricsPatternSearch={
                                        this.props.onFabricsPatternSearch
                                    }
                                    fabricsPatternItems={fabricsPatternItems}
                                    workroomSetting={workroomSetting}
                                    orderOriginStateEmb={
                                        this.state.orderOriginStateEmb
                                    }
                                    changeTrimValue={this.props.changeTrimValue}
                                    openEditFabricsPatternModalClick={
                                        this.props
                                            .openEditFabricsPatternModalClick
                                    }
                                    workOrderEditMode={workOrderEditMode}
                                    draperyOrder={draperyOrder}
                                    isCopy={this.props.isCopy}
                                    orderType={orderType}
                                />
                            );
                        } else if (el.subType === 1) {
                            return (
                                <FabricsComponent
                                    key={idx}
                                    targetArray="trims"
                                    targetArrayIndex={idx}
                                    name={`TRIM FABRIC ${
                                        trims.length > 1 ? idx + 1 : ""
                                    }`}
                                    defaultDraperyOrderFabric={defaultTrimFabric(
                                        `TRIM FABRIC ${
                                            trims.length > 1 ? idx + 1 : ""
                                        }`,
                                        this.state.orderOriginStateEmb
                                    )}
                                    type={7}
                                    draperyOrderDesignEmbellishment={el}
                                    showRepeatValues
                                    showApplicationMethod
                                    showFabricWidth
                                    disabled={
                                        workOrderEditMode ? false : disabled
                                    }
                                    highlightedSection={highlightedSection}
                                    onSearch={this.props.onSupplierSearch}
                                    openSupplierModalClick={
                                        this.props.openSupplierModalClick
                                    }
                                    openImageModal={this.props.openImageModal}
                                    onAddSupplierByName={
                                        this.props.onAddSupplierByName
                                    }
                                    openFabricsPatternModalClick={
                                        this.props.openFabricsPatternModalClick
                                    }
                                    handleInputChangeForNumber={
                                        this.props.handleInputChangeForNumber
                                    }
                                    handleInputChangeForString={
                                        this.props.handleInputChangeForString
                                    }
                                    handleInputChangeForBoolean={
                                        this.props.handleInputChangeForBoolean
                                    }
                                    handleSelectListChange={
                                        this.props.handleSelectListChange
                                    }
                                    handleSearchSelectChange={
                                        this.props.handleSearchSelectChange
                                    }
                                    handleSearchSelectClear={
                                        this.props.handleSearchSelectClear
                                    }
                                    onFabricsPatternSearch={
                                        this.props.onFabricsPatternSearch
                                    }
                                    fabricsPatternItems={fabricsPatternItems}
                                    workroomSetting={workroomSetting}
                                    orderOriginStateEmb={
                                        this.state.orderOriginStateEmb
                                    }
                                    openEditFabricsPatternModalClick={
                                        this.props
                                            .openEditFabricsPatternModalClick
                                    }
                                    workOrderEditMode={workOrderEditMode}
                                    draperyOrder={draperyOrder}
                                    isCopy={this.props.isCopy}
                                    orderType={this.props.orderType}
                                />
                            );
                        }
                    })}
                {embellishmentsList[4].checked &&
                    othersEmbellishments.length !== 0 &&
                    othersEmbellishments.map((el, idx) => (
                        <FabricsComponent
                            key={idx}
                            targetArray="othersEmbellishments"
                            targetArrayIndex={idx}
                            name={`${el.name} FABRIC`}
                            defaultDraperyOrderFabric={defaultOtherEmbellishmentFabric(
                                `${el.name} FABRIC`,
                                this.state.orderOriginStateEmb
                            )}
                            type={9}
                            draperyOrderDesignEmbellishment={el}
                            showFabricWidth
                            showRepeatValues
                            showYardsNeeded={this.props.orderType === 'drapery-order' ? true : false}
                            disabled={workOrderEditMode ? false : disabled}
                            highlightedSection={highlightedSection}
                            onSearch={this.props.onSupplierSearch}
                            openSupplierModalClick={
                                this.props.openSupplierModalClick
                            }
                            openImageModal={this.props.openImageModal}
                            onAddSupplierByName={this.props.onAddSupplierByName}
                            handleInputChangeForNumber={
                                this.props.handleInputChangeForNumber
                            }
                            openFabricsPatternModalClick={
                                this.props.openFabricsPatternModalClick
                            }
                            handleInputChangeForString={
                                this.props.handleInputChangeForString
                            }
                            handleInputChangeForBoolean={
                                this.props.handleInputChangeForBoolean
                            }
                            handleSelectListChange={
                                this.props.handleSelectListChange
                            }
                            handleSearchSelectChange={
                                this.props.handleSearchSelectChange
                            }
                            handleSearchSelectClear={
                                this.props.handleSearchSelectClear
                            }
                            onFabricsPatternSearch={
                                this.props.onFabricsPatternSearch
                            }
                            fabricsPatternItems={fabricsPatternItems}
                            workroomSetting={workroomSetting}
                            orderOriginStateEmb={this.state.orderOriginStateEmb}
                            openEditFabricsPatternModalClick={
                                this.props.openEditFabricsPatternModalClick
                            }
                            workOrderEditMode={workOrderEditMode}
                            draperyOrder={draperyOrder}
                            isCopy={this.props.isCopy}
                            orderType={this.props.orderType}
                        />
                    ))}
                  {/* // HERE => QUIPA-1578 */}
                    {/* if back valance is selected and no lining is selected */}
                    {romanShadeProps && 
                romanShadeProps.valanceAndReturns && 
                romanShadeProps.valanceAndReturns.backValance &&
                romanShadeProps.valanceAndReturns.backValance > 0 &&
                liningsList.every((lin) => !lin.active) ? (
                    <FabricsLiningsSection
                        liningsList={liningsList}
                        disabled={workOrderEditMode ? false : disabled}
                        onSupplierSearch={this.props.onSupplierSearch}
                        openSupplierModalClick={this.props.openSupplierModalClick}
                        openImageModal={this.props.openImageModal}
                        openFabricsPatternModalClick={
                            this.props.openFabricsPatternModalClick
                        }
                        onFabricsLiningPatternSearch={
                            this.props.onFabricsLiningPatternSearch
                        }
                        handleInputChangeForNumber={
                            this.props.handleInputChangeForNumber
                        }
                        handleInputChangeForString={
                            this.props.handleInputChangeForString
                        }
                        handleInputChangeForBoolean={
                            this.props.handleInputChangeForBoolean
                        }
                        handleSelectListChange={this.props.handleSelectListChange}
                        handleSearchSelectChange={
                            this.props.handleSearchSelectChange
                        }
                        handleSearchSelectClear={this.props.handleSearchSelectClear}
                        highlightedSection={highlightedSection}
                        liningsPatternItems={liningsPatternItems}
                        workroomSetting={workroomSetting}
                        orderOriginState={this.state.orderOriginStateLining}
                        openEditFabricsPatternModalClick={this.props.openEditFabricsPatternModalClick}
                        workOrderEditMode={workOrderEditMode}
                        draperyOrder={draperyOrder}
                        isCopy={this.props.isCopy}
                        forBackValance
                        orderType={orderType}
                        romanShadeProps={this.props.romanShadeProps}
                    />
                ) : (
                    // else return default lining section
                    <FabricsLiningsSection
                        liningsList={liningsList}
                        disabled={workOrderEditMode ? false : disabled}
                        onSupplierSearch={this.props.onSupplierSearch}
                        openSupplierModalClick={this.props.openSupplierModalClick}
                        openImageModal={this.props.openImageModal}
                        openFabricsPatternModalClick={
                            this.props.openFabricsPatternModalClick
                        }
                        onFabricsLiningPatternSearch={
                            this.props.onFabricsLiningPatternSearch
                        }
                        handleInputChangeForNumber={
                            this.props.handleInputChangeForNumber
                        }
                        handleInputChangeForString={
                            this.props.handleInputChangeForString
                        }
                        handleInputChangeForBoolean={
                            this.props.handleInputChangeForBoolean
                        }
                        handleSelectListChange={this.props.handleSelectListChange}
                        handleSearchSelectChange={
                            this.props.handleSearchSelectChange
                        }
                        handleSearchSelectClear={this.props.handleSearchSelectClear}
                        highlightedSection={highlightedSection}
                        liningsPatternItems={liningsPatternItems}
                        workroomSetting={workroomSetting}
                        orderOriginState={this.state.orderOriginStateLining}
                        openEditFabricsPatternModalClick={this.props.openEditFabricsPatternModalClick}
                        workOrderEditMode={workOrderEditMode}
                        draperyOrder={draperyOrder}
                        isCopy={this.props.isCopy}
                        orderType={orderType}
                        romanShadeProps={this.props.romanShadeProps}
                    />
                )}
            </>
        );
    }
    getDefaultValue(orderType: string): void {
        const value =
            this.props.workroomSetting === null ||
            (this.props.workroomSetting &&
                this.props.workroomSetting.fabricPurchaser === "No Default") ||
            (this.props.workroomSetting &&
                !this.props.workroomSetting.fabricPurchaser)
                ? -1
                : this.props.mainFabric.orderOrigin === -1
                ? this.props.workroomSetting &&
                  this.props.workroomSetting.fabricPurchaser &&
                  this.props.workroomSetting.fabricPurchaser === "Workroom"
                    ? 1
                    : 2
                : this.props.mainFabric.orderOrigin;
        const valueEmb =
            this.props.workroomSetting === null ||
            (this.props.workroomSetting &&
                this.props.workroomSetting.embellishmentsPurchaser ===
                    "No Default") ||
            (this.props.workroomSetting &&
                !this.props.workroomSetting.embellishmentsPurchaser)
                ? -1
                : this.props.workroomSetting &&
                  this.props.workroomSetting.embellishmentsPurchaser &&
                  this.props.workroomSetting.embellishmentsPurchaser ===
                      "Workroom"
                ? 1
                : 2;

        const valueLining =
            this.props.workroomSetting === null ||
            (this.props.workroomSetting &&
                this.props.workroomSetting.liningsPurchaser === "No Default") ||
            (this.props.workroomSetting &&
                !this.props.workroomSetting.liningsPurchaser)
                ? -1
                : this.props.workroomSetting &&
                  this.props.workroomSetting.liningsPurchaser &&
                  this.props.workroomSetting.liningsPurchaser === "Workroom"
                ? 1
                : 2;
        this.setState({
            orderOriginState: orderType === 'roman-shade-order' ? -1 :  value,
            orderOriginStateEmb: orderType === 'roman-shade-order' ? -1 : valueEmb,
            orderOriginStateLining: orderType === 'roman-shade-order' ? -1 : valueLining,
        });
    }
}

export default FabricsTab;
